// import { Button } from 'bootstrap';
import React, { useState ,useEffect} from "react";
import PropTypes from "prop-types";
import { Badge, Button, Col, Row } from "react-bootstrap";
import "./League.scss";
import headerSection from "../../assets/images/headerSection.jpg";
// import TextFieldComponent from '../../Components/TextFieldComponent/TextFieldComponent';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import Overview from "./Overview";
import TeamDetails from "./TeamDetails";
import { useDispatch, useSelector } from "react-redux";
import { useParams,Link } from "react-router-dom";
import constants from "../../utils/constants";
import { useOnMount } from "../../utils/helpers";
import { getLeagueStandingsAction } from "../../Store/actions/leagueAction";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import SearchBar from "material-ui-search-bar";
import basketballgif from '../../assets/images/basketballLoader.gif'




const columns = [
  //remove minWidth:250 from all obj
  { id: 'pool_name', label: 'Pool Name', align: 'center' 
  // align: 'left' minWidth: 250
}, 
  { id: 'team_name', label: 'Team Name', align: 'center'},
  { id: 'team_town', label: 'Organization', align: 'center'},
  {
    id: 'match_schedules',
    label: 'GP',  
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
    //  minWidth: 250
  },
  {
    id: 'record',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Record',
    align: 'center', 
    // minWidth: 250,
    format: (value)=>value,
    
  },
  {
    id: 'points_for',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'PF',
    align: 'center',
    format: "123",
    
  },
  {
    id: 'points_against',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'PA',
    align: 'center',
    format: "123",
    
  },
  {
    id: 'per',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Win%',
    align: 'center', 
    format: "123",
    
  },
  
  // {
  //   id: 'density',
  //   label: 'Density',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: 'black',
    color:'white',
    // color:
    //   theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function createData(pool_name, team_name, GP, record, PF,PA,win) {
  return { pool_name, team_name, GP, record, PF,PA,win};
}

const rows = [createData("abc", "blues", "1", "W(1)-L(0)","12","9", "100%")];


// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

  

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

function LeagueViewStanding(props) {
  // const [value, setValue] = React.useState(0);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const [teamId, setTeamId] = useState([]);
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const { standing } = useSelector((state) => state.league);
  const {isLoading} = useSelector((state)=>state.loading)

  const { id } = useParams();
  useOnMount(() => {
    dispatch(getLeagueStandingsAction({"leagueId":id}));
  });
  useEffect(() => {
    setTeamId(standing);
    setRows(standing);

  }, [standing]);

  // console.log("stttttaaaa",standing);

  // let item=standing.map(v=>({
  //    ...v,
  //    pool_name:v.pool.name,
  //    leagueId:v.pool.leagueId
  // }))
  // console.log("stitem",item);
  // let arr=[]
  // arr.push(item)
  

  // const res=item.map(nested =>({ pool:nested.pool, teamList:
  //   nested.teamList.map(menu =>({...menu,pool_name:nested.pool_name,leagueId:nested.leagueId})) }));
  // console.log("rrrrrrrr",res);

  // let final=res.map(item=> item.teamList
  // )
  // console.log("ffff",final);
//   let item2=item.map(v=>({
//     ...v,
//     pool_name:v.pool_name
//  }))
// let arr=[]
// for (const obj of res) {
//   for (const team of obj.teamList) {
//     console.log("ttt",team);
//     // arr.push(team)
//     arr.push({
//       pool_name: team.pool_name,
//       leagueId:team.leagueId,
//       team_name: team.team_name,
//       match_schedules: team.wins + team.lost,
//       record: "(W) " + team.wins + " - " + " (L) " + team.lost,
//       points_for: team.points_for,
//       points_against: team.points_against,
//       // per: item.wins,
//       per:
//         team.wins + team.lost != 0
//           ? ((team.wins / (team.wins + team.lost)) * 100).toFixed(1)
//           : (0).toFixed(1),
//     })
//   }
// }

// console.log("aaaa",arr);

//  console.log("itttt222",final);
  // const handleTeam = (id) => {
  //   console.log("team_id",id);
  //   setTeamId(id)
  // }

  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    const filteredRows = teamId.filter((row) => {
      const {pool_name,team_name}=row
      // return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      return pool_name.toLowerCase().includes(searchedVal.toLowerCase()) || 
      team_name.toLowerCase().includes(searchedVal.toLowerCase()) 
     
    });
    setRows(filteredRows);
    // setUpdatedList(filteredRows);
  };
  // console.log("rrrr",rows);

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
    setRows(teamId)
  };

  return (
    <>
        <div className='league-head'>League Standings</div>

      {/* <div className="header-league-section">
        <img className="img-fulid" src={headerSection} alt="header-poster" />
      </div> */}
      {/* <div className="px-3 mb-5"> */}
        {/* <section className="title">
          <Row className="align-items-center justify-content-between">
            <Col md={12}>
              <div className="header-contain text-center">
                <div className="header pl-0">Basketball League Stats</div>
                <div className="sub-header">Basketball League Stats</div>
              </div>
            </Col>
          </Row>
        </section>

        <AppBar
          position="static"
          color="default"
          className="mt-4 league-status"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Overview" {...a11yProps(0)} />
            {teamList.map((item, index) => (
              <Tab label={item.name} {...a11yProps(index + 1)} onClick={() => handleTeam(item.id)} />
            ))}
          </Tabs>
        </AppBar>

        <TabPanel value={0} index={0} className="overview p-0">
          {value === 0 ? <Overview /> : <TeamDetails teamId= {teamId && teamId}/>}
        </TabPanel> */}
        
        
        {/* <TabPanel value={value} index={1} className="overview p-0">
          <TeamDetails />
        </TabPanel> */}
      {/* </div> */}

      <div style={{backgroundColor: '#1F1F21'}}>
    <div className='container' style={{paddingBottom: 50, paddingTop: 50}}>
    <div style={{display:"flex",justifyContent:"flex-end"}}  className='searchbar'>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          // style={{width:"30%",border:"none",height:"40px",marginBottom:"14px",color:"black"}}
          className='search'
        />
        </div>
    <Paper className='league_table_body' sx={{ width: '100%', overflow: 'hidden'}}>
      <TableContainer >
   
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows */}
            {!isLoading?
            rows?.length ? rows
            // {teamId?.length ? teamId
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row,i) => {
                // console.log("rrr",row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      // console.log("vvv",value);
                      // {console.log("rrrr",row)}
                      return (
                        <>
                        {column.id !== "dropdown" ? column.id!=="team_name" ?
                        <TableCell key={column.id} align={column.align}>
                          {/* {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value} */}
                            {value}
                        </TableCell> : <TableCell key={column.id} align={column.align}>
        <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_STATUS}/${row.leagueId}?team=${row.team_name}`} style={{color: "orange"}}>
                           {value} </Link>
                        </TableCell>
                        : 
                          <TableCell className="tableData">
                             <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      {/* <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="menubar"
      >
        <MenuItem onClick={handleClose} disableRipple>
          {/* <EditIcon /> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${row.id}`} style={{color: "#000", textDecoration: "none"}}> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/2`} style={{color: "white", textDecoration: "none"}}>
          View Schedules
          </Link>
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          {/* <FileCopyIcon /> */}
          {/* View Stats */}
        {/* </MenuItem> */} 
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          {/* <ArchiveIcon /> */}
          {/* View Standings */}
        {/* </MenuItem>  */}
        {/* <MenuItem onClick={handleClose} disableRipple> */}
          {/* <MoreHorizIcon /> */}
          View Teams & Players
        {/* </MenuItem> */}
      {/* </StyledMenu>  */}
    </div>
                            </TableCell>
                        }
                     
                         </>
                      );
                    })}
                  </TableRow>
                );
              }): <div className="no-data-found"> NO STANDINGS AVAILABLE </div>
              :
              <div
              // className="text-center"
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection:"row",

              }}
            >
              <div style={{position:"absolute",left:"45%"}}>
                <img
                  src={basketballgif}
                  alt="gif"
                  style={{ width: "150px", height: "130px" }}
                />
                <h2 style={{color:"white",textAlign:"center"}}>Loading...</h2>
              </div>
            </div>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        // count={schedule.length}
        // count={teamId.length}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
    </div>
    </>
  );
}

export default LeagueViewStanding;
