/* eslint-disable dot-notation */
import { useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import jwt_decode from "jwt-decode";
import { select, put } from "redux-saga/effects";
import { setError } from "../Store/actions/error";
import { setLoading } from "../Store/actions/loading";
import { getState } from "../Store/configureStore";
import moment from 'moment';
const dateFns = new DateFnsUtils();

/**
 * Hook to run a handler once on mount and never again.
 * @param {() => void} handler Function to run on mount.
 * See https://reactjs.org/docs/hooks-reference.html#useeffect
 * See https://github.com/facebook/create-react-app/issues/6880
 * This function is mainly to provide a better signal to the developer than
 * knowing how `useEffect` works when you pass an empty array. It also helps to
 * get around warnings raised by `react-hooks/exhaustive-deps` and we use it
 * instead of `// eslint-disable-next-line react-hooks/exhaustive-deps`.
 * We only run the handler once, so we don't care if dependencies change!
 */
export const useOnMount = (handler) => useEffect(handler, []);

// Saga helpers

export function* getHeaders() {
  return yield select((state) => {
    const headers = {};

    if (state.auth.authToken) {
      headers["Authorization"] = `Bearer ${state.auth.authToken}`;
    }
    return headers;
  });
}

export function getStoreAuthToken() {
  return getState().auth.authToken;
}

export function* handleException(e, errorType) {
  if (!e) return;

  const errorMessage = e?.response?.data?.message;
  yield put(setLoading(false));
  yield put(setError({ type: errorType, message: errorMessage }));
}

// Formatting helpers

export const convertBytesToKilobytes = (bytes) => {
  const kb = Math.floor(bytes / 1000);
  return `${kb} KB`;
};

export const formatDate = (date, format) => {
  // const newDate = new Date(date.toUTCString());
  // console.log('newDate',newDate, dateFns.format(newDate, format));
  try {
    return moment(date).format(format);
    // dateFns.format(newDate, format);
  } catch (e) {
    return date;
  }
};

export const formatVideoDuration = (duration) => {
  let formattedDuration = "";

  const durationSplit = duration.split(":");

  if (parseInt(durationSplit[0], 10)) {
    formattedDuration += `${parseInt(durationSplit[0], 10)} hr `;
  }

  if (parseInt(durationSplit[1], 10)) {
    formattedDuration += `${parseInt(durationSplit[1], 10)}:${
      durationSplit[2]
    } min`;
  } else if (parseInt(durationSplit[2], 10)) {
    formattedDuration += `${parseInt(durationSplit[2], 10)} sec`;
  }

  return formattedDuration;
};

export const getValueFromToken = (token, value) => {
  try {
    if (!token) return null;
    const userDetail = jwt_decode(token);
    return userDetail[value];
  } catch {
    return null;
  }
};
const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getSorting = (order, orderBy) =>
  order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

export const handleDownloadClick = (csv, filename = "analytics-data") => {
  const downloadFile = csv.join("\n");
  const csvFile = new Blob([downloadFile], { type: "text/csv" });
  const downloadLink = document.createElement("a");
  downloadLink.download = `${filename}.csv`;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const handleTwoFileDownload = (file1, file2) => {
  let file1Length = file1.length;
  const file2Length = file2.length;
  const csv = [];
  if (file1Length <= file2Length) file1Length = file2Length;
  for (let i = 0; i < file1Length; i++) {
    if (file1[i]) {
      csv[i] = `${file1[i]},`;
    } else {
      csv[i] = ",,,,";
    }
    if (file2[i]) {
      csv[i] = `${csv[i]}${file2[i]}`;
    }
  }
  return csv;
};

export const exportTableToCSV = (tableId, tableHeader = "Analytics Data") => {
  if (tableId) {
    const csv = [];
    const rows = document.querySelectorAll(`#${tableId} tr`);
    rows.forEach((tr) => {
      const row = [];
      const cols = tr.querySelectorAll(`#${tableId} td, th`);
      cols.forEach((td) => {
        row.push(td.innerText);
      });
      cols.forEach(() => {
        row.push("");
      });
      csv.push(row.join(","));
    });
    return ["", "", `${tableHeader},,,`, "", ...csv];
  }
};
