import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import headerSection from "../../assets/images/headerSection.jpg";
import "../../assets/css/style.scss";
import "./Carousel.scss";

function CarouselComponent(props) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0,
    },
  };
  return (
    <div>
      <Carousel
        className="header-section"
        partialVisbile
        itemClass="image-item px-0"
        responsive={responsive}
      >
        <div>
          <img src={headerSection} className="img-fluid" />
        </div>
        <div>
          <img src={headerSection} className="img-fluid" />
        </div>
        <div>
          <img src={headerSection} className="img-fluid" />
        </div>
      </Carousel>
    </div>
  );
}

export default CarouselComponent;
