import { Button } from "@material-ui/core";
import React from "react";
import "./ButtonComponent.scss";


const ButtonComponent = ({ children, ...rest }) => (
  <Button {...rest} className="custom-btn" >{children}</Button>
);

export default ButtonComponent;
