// Authentication
export const USER_SIGNIN_INIT = "USER_SIGNIN_INIT";
export const USER_SIGNOUT_INIT = "USER_SIGNOUT_INIT";
export const SET_USER = "SET_USER";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const CLEAR_ACCESS_TOKEN = "CLEAR_ACCESS_TOKEN";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const RESET_NEW_PASSWORD_REQUEST = "RESET_NEW_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const CLEAR_REDUX_STORE = "CLEAR_REDUX_STORE";

// Set loading spinner
export const SET_LOADING = "SET_LOADING";

// For modals
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

// For snackbar alert
export const SET_ALERT = "SET_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";

// Errors
export const SET_ERROR = "SET_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

// Home
export const GET_FEATURES = "GET_FEATURES";
export const SET_FEATURE = 'SET_FEATURE';
export const GET_NEWS_ARTICLES = 'GET_NEWS_ARTICLES';
export const SET_NEWS_ARTICLES = 'SET_NEWS_ARTICLES';
export const SEND_CONTACT_DETAILS = 'SEND_CONTACT_DETAILS';
export const SEND_CONTACT = 'SEND_CONTACT';
export const SET_BLOG_DETAILS = 'SET_BLOG_DETAILS';
export const SEND_EMAIL_SUBSCRIPTION = 'SEND_EMAIL_SUBSCRIPTION';

// FAQ
export const SET_FAQ = 'SET_FAQ';
export const GET_FAQ = 'GET_FAQ';
export const SEND_QUERY = 'SEND_QUERY';
export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY';
export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY';
export const GET_TERM_AND_CONDITION = 'GET_TERM_AND_CONDITION';
export const SET_TERM_AND_CONDITION = 'SET_TERM_AND_CONDITION';
export const GET_ABOUT_US = 'GET_ABOUT_US';
export const SET_ABOUT_US = 'SET_ABOUT_US';

// Player
export const GET_PLAYER_LIST = 'GET_PLAYER_LIST';
export const SET_PLAYER_LIST = 'SET_PLAYER_LIST';
export const GET_PLAYER_DETAILS = 'GET_PLAYER_DETAILS';
export const SET_PLAYER_DETAILS = 'SET_PLAYER_DETAILS';

// League
export const GET_LEAGUE_LIST = 'GET_LEAGUE_LIST';
export const SET_LEAGUE_LIST = 'SET_LEAGUE_LIST';
export const GET_SCHEDULE_DETAILS = 'GET_SCHEDULE_DETAILS';
export const SET_SCHEDULE_DETAILS = 'SET_SCHEDULE_DETAILS';
export const GET_LEAGUE_STATUS = 'GET_LEAGUE_STATUS';
export const SET_LEAGUE_TEAM_LIST = 'SET_LEAGUE_TEAM_LIST';
export const SET_LEAGUE_TEAM_PLAYER = 'SET_LEAGUE_TEAM_PLAYER';
export const SET_LEAGUE_TEAM_SCORE = 'SET_LEAGUE_TEAM_SCORE';

export const GET_LEAGUES_TEAM_LIST = 'GET_LEAGUES_TEAM_LIST';
export const SET_LEAGUES_TEAM_LIST = 'SET_LEAGUES_TEAM_LIST';


export const GET_HIGHLIGHT_LIST = 'GET_HIGHLIGHT_LIST';
export const SET_HIGHLIGHT_LIST = 'SET_HIGHLIGHT_LIST';
export const SET_VIDEO_PLAYER_DATA = 'SET_VIDEO_PLAYER_DATA';
export const CLOSE_VIDEO_PLAYER = 'CLOSE_VIDEO_PLAYER';

//League Standing

export const GET_LEAGUE_STANDINGS = 'GET_LEAGUE_STANDINGS';
export const SET_LEAGUE_STANDINGS = 'SET_LEAGUE_STANDINGS';


//Team List Player Stats

export const GET_TEAMPLAYER_STATISTICS = 'GET_TEAMPLAYER_STATISTICS';
export const SET_TEAMPLAYER_STATISTICS = 'SET_TEAMPLAYER_STATISTICS';


//League Stats

export const GET_LEAGUE_STATISTICS = 'GET_LEAGUE_STATISTICS';
export const SET_LEAGUE_STATISTICS = 'SET_LEAGUE_STATISTICS';

//Section One

export const GET_SECTION_ONE = 'GET_SECTION_ONE';
export const SET_SECTION_ONE = 'SET_SECTION_ONE';

//Section Two

export const GET_SECTION_TWO = 'GET_SECTION_TWO';
export const SET_SECTION_TWO = 'SET_SECTION_TWO';

export const GET_SECTION_TWO_ID = 'GET_SECTION_TWO_ID';
export const SET_SECTION_TWO_ID = 'SET_SECTION_TWO_ID';

//Section Faq

export const GET_SECTION_FAQ = 'GET_SECTION_FAQ';
export const SET_SECTION_FAQ = 'SET_SECTION_FAQ';


//Schedule Stats

export const GET_SCHEDULE_STATISTICS = 'GET_SCHEDULE_STATISTICS';
export const SET_SCHEDULE_STATISTICS = 'SET_SCHEDULE_STATISTICS';

//Story
export const GET_STORY_ARTICLES = 'GET_STORY_ARTICLES';
export const SET_STORY_ARTICLES = 'SET_STORY_ARTICLES';
export const SET_STORY_ARTICLES_DETAILS = 'SET_STORY_ARTICLES_DETAILS';


//Coach
export const GET_COACH_LIST = 'GET_COACH_LIST';
export const SET_COACH_LIST = 'SET_COACH_LIST';

//Player details & stats

export const GET_PLAYER_DETAIL = 'GET_PLAYER_DETAIL';
export const SET_PLAYER_DETAIL = 'SET_PLAYER_DETAIL';

export const GET_PLAYER_STATISTICS = 'GET_PLAYER_STATISTICS';
export const SET_PLAYER_STATISTICS = 'SET_PLAYER_STATISTICS';

export const GET_PRICING_ARTICLES = 'GET_PRICING_ARTICLES';
export const SET_PRICING_ARTICLES = 'SET_PRICING_ARTICLES';




