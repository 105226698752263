import { produce } from "immer";
import { SET_ABOUT_US, SET_BLOG_DETAILS, SET_COACH_LIST, SET_FAQ, SET_FEATURE, SET_NEWS_ARTICLES, SET_PLAYER_DETAILS, SET_PLAYER_LIST, SET_PRIVACY_POLICY, SET_SECTION_FAQ, SET_SECTION_ONE, SET_SECTION_TWO, SET_STORY_ARTICLES, SET_STORY_ARTICLES_DETAILS, SET_TERM_AND_CONDITION,SET_SECTION_TWO_ID, SET_PRICING_ARTICLES } from "../actions/actionTypes";

const initialState = {
  featureList: [],
  newsArticles: [],
  faq: [],
  privacy: {},
  terms: {},
  about: {},
  playerList: {
    results: []
  },
  playerDetails: {
    player: {}
  },
  storyListArticle: [],
  blogDetails: {},
  storyArticle: {},
  coachLists: [],
  sectionOne:[],
  sectionTwo:[],
  sectionTwoId:[],
  sectionFaq:[],
  pricingListArticle: [],
};

const homeReducer = (draft, action) => {
  switch (action.type) {
    case SET_FEATURE: {
      draft.featureList = action.payload;
      break;
    }
    case SET_NEWS_ARTICLES: {
      draft.newsArticles = action.payload;
      break;
    }
    case SET_FAQ: {
      draft.faq = action.payload;
      break;
    }
    case SET_PRIVACY_POLICY: {
      draft.privacy = action.payload;
      break;
    }
    case SET_PLAYER_LIST: {
      draft.playerList = action.payload;
      break;
    }
    case SET_STORY_ARTICLES: {
      draft.storyListArticle = action.payload;
      break;
    }
    case SET_TERM_AND_CONDITION: {
      draft.terms = action.payload;
      break;
    }
    case SET_ABOUT_US: {
      draft.about = action.payload;
      break;
    }
    case SET_PLAYER_DETAILS: {
      draft.playerDetails = action.payload;
      break;
    }
    case SET_BLOG_DETAILS: {
      draft.blogDetails = action.payload;
      break;
    }
    case SET_COACH_LIST: {
      draft.coachLists = action.payload;
      break;
    }
    case SET_STORY_ARTICLES_DETAILS: {
      draft.storyArticle = action.payload;
      break;
    }
    case SET_SECTION_ONE: {
      draft.sectionOne = action.payload;
      break;
    }
    case SET_SECTION_TWO: {
      draft.sectionTwo = action.payload;
      break;
    }
    case SET_SECTION_TWO_ID: {
      draft.sectionTwoId = action.payload;
      break;
    }
    case SET_SECTION_FAQ: {
      draft.sectionFaq = action.payload;
      break;
    }
    case SET_PRICING_ARTICLES: {
      draft.pricingListArticle = action.payload;
      break;
    }
    default:
      break;
  }
};

export default produce(homeReducer, initialState);
