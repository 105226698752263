import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// import "./Home.scss";
import { useOnMount } from '../../utils/helpers';
import { getSectionTwoIdAction, getStoryArticles } from '../../Store/actions/HomeAction';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../utils/constants';
import { useParams } from 'react-router-dom';
import './WebDetails.scss'
import introvid from '../../assets/video/hlsdemo.mp4'
import thumbnail from '../../assets/images/thumbnail.png'
// import { getSectionTwoByIdApi } from '../../api';




function WebDetails(props) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { storyListArticle,sectionTwo ,sectionTwoId} = useSelector((state) => state.home);
  // console.log("sss",sectionTwoId);

  // let sectionTwoId = {};
  // if (sectionTwoId.length > 0) {
  //   // console.log("data",sectionTwoId);
  // sectionTwoId = sectionTwo.find((item) => item.id === parseInt(id));

  // }
  // console.log("iiii",id);

  useOnMount(() => {
    window.scrollTo(0, 0);
    // dispatch(getStoryArticles());
    dispatch(getSectionTwoIdAction(id));
  });
  return (
    <div className=''>
      {/* <div className="header-league-section "> */}
      <div className=" " style={{display:"flex",alignItems:"center","justifyContent":"center",backgroundColor:"#1f1f21"}}> 
     {/* { console.log("sttt",sectionTwoId)} */}
     {sectionTwoId?.media && sectionTwoId?.media?.length &&  (sectionTwoId.media.split('.').pop()=="jpg" || sectionTwoId.media.split('.').pop()=="jpeg" || sectionTwoId.media.split('.').pop()=="png" 
                   || sectionTwoId.media.split('.').pop()=="svg" || sectionTwoId.media.split('.').pop()=="bmp"
                   || sectionTwoId.media.split('.').pop()=="webp" || sectionTwoId.media.split('.').pop()=="tiff" || sectionTwoId.media.split('.').pop()=="gif") ?
                     <img src={constants.IMG_URL + sectionTwoId.media} className="vidimg" width="450" height="300"   />:
                           
           <video src={constants.IMG_URL + sectionTwoId.media} className="vidimg" width="300" height="200" controls="controls" poster={thumbnail} /> }
        {/* <img
          // className="img-fulid"
          src={constants.IMG_URL + sectionTwoId?.image}
          alt="header-poster"
          width={"260px"}
          height={"260px"}
        /> */}
          {/* <video src={introvid} className="viddet" width="300" height="200" controls="controls" poster={thumbnail} /> */}

      </div>
      <section className="section-3 ">
        {/* <div className="header-contain">
          <div className="header">Blog Details</div>
          <div className="sub-header">Blog Details</div>
        </div> */}
        {/* <div>
            <h2 className='title-head'>Game Details</h2>
          </div> */}
        <Row className="feature-row text-left " xs={12} md={12} lg={12}>
          <Col md={12}>
            <div className="mb-3 ">
              <h2>{sectionTwoId && sectionTwoId?.title}</h2>
            </div>
            <div className="">
              {/* <p style={{width:"12px",height:"12px"}}> */}
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sectionTwoId?.description,
                  }}
                />
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default WebDetails;
