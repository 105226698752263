import React from "react";
import { Col, Row } from "react-bootstrap";
import TextFieldComponent from "../../../Components/TextFieldComponent/TextFieldComponent";
import headerSection from "../../../assets/images/headerSection.jpg";
import { Button } from "@material-ui/core";
import ArticleCard from "../../../Components/ArticleCard/ArticleCard";
import "./NewArticles.scss";
// import { useDispatch, useSelector } from "react-redux";
import { useOnMount } from "../../../utils/helpers";
import { getFeature, getNewsArticles, setBlogsDetails } from "../../../Store/actions/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../../utils/constants";
import { Link } from "react-router-dom";
// import CarouselComponent from "../../Components/Carousel/Carousel";

const NewArticles = () => {
  const dispatch = useDispatch();
  const {newsArticles } = useSelector((state) => state.home);
  
  useOnMount(() => {
    dispatch(getFeature());
    dispatch(getNewsArticles());
  });
  return (
    <div className="new-article">
      <img className="header-section" src={headerSection} alt="header-poster" />
      <Row className="align-items-center justify-content-between mx-0">
        <Col md={12}>
          <div className="header-contain border-bottom text-center">
            <div className="header">Blog</div>
            <div className="sub-header">Blog</div>
          </div>
        </Col>
      </Row>
      <div className="article-row row mx-0">
        {newsArticles.map((article) => (
          <div className="col-md-3 mb-4">
            <img
              className="img"
              src={constants.IMG_URL + article.image}
              alt={article.title}
            />
            <div className="display-contain">
              <div className="header">{article.title}</div>
              {/* <div className="date">{article.date || '-'}</div> */}
              <div className="contain my-3"><div dangerouslySetInnerHTML={{ __html: article.description }} /></div>
              <Button variant="dark" size="sm" onClick={() => dispatch(setBlogsDetails(article))} className="bg-dark">
                <Link to="/blog-details" className="text-white" style={{ textDecoration: "none" }} >
                  Read More
                </Link>
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewArticles;
