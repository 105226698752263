import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import "./Home.scss";
import { useOnMount } from '../../utils/helpers';
import { getNewsArticles } from '../../Store/actions/HomeAction';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../utils/constants';
import { useParams } from 'react-router-dom';

function BlogDetails(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { newsArticles } = useSelector((state) => state.home);

  let blogDetail = {};
  if (newsArticles.length > 0) {
    window.scrollTo(0, 0);
    blogDetail = newsArticles.find((item) => item.id === parseInt(id));
  }

  useOnMount(() => {
    dispatch(getNewsArticles());
  });
  return (
    <div>
      <div className="header-league-section">
        <img
          className="img-fulid"
          src={constants.IMG_URL + blogDetail?.image}
          alt="header-poster"
        />
      </div>
      <section className="section-3">
        <div className="header-contain">
          <div className="header">Blog Details</div>
          <div className="sub-header">Blog Details</div>
        </div>
        <Row className="feature-row text-left" xs={12} md={12} lg={12}>
          <Col md={12}>
            <div className="mb-3">
              <h2>{blogDetail?.title}</h2>
            </div>
            <div className="">
              <p>
                <div
                  dangerouslySetInnerHTML={{ __html: blogDetail?.description }}
                />
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default BlogDetails;
