import {  GET_LEAGUE_LIST, SET_LEAGUE_LIST, GET_SCHEDULE_DETAILS, SET_SCHEDULE_DETAILS, GET_LEAGUE_STATUS, SET_LEAGUE_TEAM_LIST,SET_LEAGUES_TEAM_LIST, SET_LEAGUE_TEAM_PLAYER, SET_LEAGUE_TEAM_SCORE, GET_HIGHLIGHT_LIST, SET_HIGHLIGHT_LIST, SET_VIDEO_PLAYER_DATA, CLOSE_VIDEO_PLAYER,GET_LEAGUE_STANDINGS,SET_LEAGUE_STANDINGS,GET_LEAGUES_TEAM_LIST, GET_LEAGUE_STATISTICS, SET_LEAGUE_STATISTICS,GET_SCHEDULE_STATISTICS,SET_SCHEDULE_STATISTICS,GET_PLAYER_STATISTICS,GET_PLAYER_DETAIL,SET_PLAYER_DETAIL,SET_PLAYER_STATISTICS} from './actionTypes';

export const getLeagueListAction = () => ({ type: GET_LEAGUE_LIST });
export const setLeagueListAction = (payload) => ({ type: SET_LEAGUE_LIST, payload });

export const getLeagueScheduleDetailAction = (payload) => ({ type: GET_SCHEDULE_DETAILS, payload });
export const setLeagueScheduleDetailAction = (payload) => ({ type: SET_SCHEDULE_DETAILS, payload });

export const getLeagueViewStatusAction = (payload) => ({ type: GET_LEAGUE_STATUS, payload });
export const setTeamList = (payload) => ({ type: SET_LEAGUE_TEAM_LIST, payload });
export const setTeamPlayer = (payload) => ({ type: SET_LEAGUE_TEAM_PLAYER, payload });
export const setTeamScore = (payload) => ({ type: SET_LEAGUE_TEAM_SCORE, payload });

export const getLeagueTeamList = (payload) => ({ type: GET_LEAGUES_TEAM_LIST, payload });
export const setLeagueTeamList = (payload) => ({ type: SET_LEAGUES_TEAM_LIST, payload });


export const getHighLightsAction = () => ({ type: GET_HIGHLIGHT_LIST })
export const SetHighLightsAction = (payload) => ({ type: SET_HIGHLIGHT_LIST, payload })

export const setVideoPlayerAction = (payload) => ({ type: SET_VIDEO_PLAYER_DATA, payload })
export const closeVideoPlayerAction = () => ({ type: CLOSE_VIDEO_PLAYER })

export const getLeagueStandingsAction = (payload) => ({ type: GET_LEAGUE_STANDINGS, payload });
export const setLeagueStandingsAction = (payload) => ({ type: SET_LEAGUE_STANDINGS, payload });

export const getLeagueStatsAction = (payload) => ({ type: GET_LEAGUE_STATISTICS, payload });
export const setLeagueStatsAction = (payload) => ({ type: SET_LEAGUE_STATISTICS, payload });


export const getScheduleStatsAction = (payload) => ({ type: GET_SCHEDULE_STATISTICS, payload });
export const setScheduleStatsAction = (payload) => ({ type: SET_SCHEDULE_STATISTICS, payload });

export const getPlayerStatsAction = (payload) => ({ type: GET_PLAYER_STATISTICS, payload });
export const setPlayerStatsAction = (payload) => ({ type: SET_PLAYER_STATISTICS, payload });

export const getPlayerDetailAction = (payload) => ({ type: GET_PLAYER_DETAIL, payload });
export const setPlayerDetailAction = (payload) => ({ type: SET_PLAYER_DETAIL, payload });

