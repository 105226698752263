import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import headerSection from "../../assets/images/headerSection.jpg";
import { getTermAndCondition } from "../../Store/actions/HomeAction";
import { useOnMount } from "../../utils/helpers";

function TermsofUse(props) {
  const dispatch = useDispatch();
  const { terms } = useSelector((state) => state.home.terms);
  useOnMount(() => {
    dispatch(getTermAndCondition());
  });
  return (
    <>
      <div className="header-league-section">
        <img className="img-fulid" src={headerSection} alt="header-poster" />
      </div>

      <Row className="justify-content-center mx-0 mb-5">
        <Col md={12}>
          <div className="header-contain text-left">
            <div className="header pl-0 text-center">Terms of Use</div>
            <div className="sub-header text-center">Terms of Use</div>
          </div>
        </Col>
        <Col md={12} className="text-justify mt-5">
        <div dangerouslySetInnerHTML={{__html: terms}} />
        </Col>
      </Row>
    </>
  );
}

export default TermsofUse;
