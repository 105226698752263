import cookies from 'js-cookie';
import { produce } from 'immer';
import { SET_ACCESS_TOKEN, CLEAR_ACCESS_TOKEN, SET_USER_DETAILS } from '../actions/actionTypes';

const initialState = {
  authToken: cookies.get('accessToken') || null,
  userDetails: cookies.getJSON('userDetails') || null,
};

const authReducer = (draft, action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN: {
      draft.authToken = action.payload;
      break;
    }
    case CLEAR_ACCESS_TOKEN: {
      draft.authToken = null;
      draft.userDetails = null;
      break;
    }
    case SET_USER_DETAILS: {
      draft.userDetails = action.payload;
      break;
    }
    default:
      break;
  }
};

export default produce(authReducer, initialState);
