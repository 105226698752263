import { Collapse, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  container: {
    display: "flex",
  },
  header: {
    color: "white",
    width: "100%",
    borderBottom: "2px solid #656060",
    cursor: "pointer",
  },
  open: {
    color: "#fba302",
    padding: "10px 20px",
    position: "relative",
    "&:after": {
      content: '"-"',
      position: "absolute",
      right: 30,
      top: -10,
      fontSize: 45,
      zIndex: 1,
      height: "100%",
    },
  },
  close: {
    padding: "10px 20px",
    position: "relative",
    "&:after": {
      content: '"+"',
      position: "absolute",
      right: 30,
      top: 10,
      fontSize: 20,
      zIndex: 1,
      height: "100%",
    },
  },
  paper: {
    // margin: theme.spacing(1),
    fontSize: 12,
    background: "#343a40",
    padding: "15px 25px",
  },
}));
const CustomeCollapse = ({ isOpen, title, desc }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  useEffect(() => {
    setChecked(isOpen);
  }, []);
  return (
    <div className={classes.header}>
      <div
        className={`${checked ? classes.open : classes.close}`}
        onClick={() => setChecked(!checked)}
      >
        {title}
      </div>
      <Collapse in={checked}>
        <div className={classes.paper}>
          <div dangerouslySetInnerHTML={{ __html: desc }} ></div> 
        </div>
      </Collapse>
    </div>
  );
};

export default CustomeCollapse;
