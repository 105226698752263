import { Card, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import headerSection from "../../assets/images/headerSection.jpg";
import playButton from "../../assets/images/playButton.png";
import TextFieldComponent from "../../Components/TextFieldComponent/TextFieldComponent";
import {
  getHighLightsAction,
  setVideoPlayerAction,
} from "../../Store/actions/leagueAction";
import constants from "../../utils/constants";
import { useOnMount } from "../../utils/helpers";
import VideoPlayers from "../HighLight/VideoPlayers";
import "./Watch.scss";

function Watch(props) {
  const dispatch = useDispatch();
  const highLights = useSelector((state) => state.highlight.list);
  const isOpen = useSelector((state) => state.highlight.isOpen);
  const [updatedList, setUpdatedList] = useState([]);
  const handlePlayVideo = (item) => {
    dispatch(setVideoPlayerAction(item));
  };
  useOnMount(() => {
    dispatch(getHighLightsAction());
  });
  useEffect(() => {
    setUpdatedList(highLights);
  }, [highLights]);
  const handleSearchInput = (event) => {
    let tempUpdatedList = highLights.length ? highLights : [];
    tempUpdatedList = tempUpdatedList.filter((item) => {
      const {
        title, home_team, visitor_team
      } = item;
      const searchKeyWord = event.target.value.toLowerCase().trim();
      return title.toLowerCase().search(searchKeyWord) !== -1
        || home_team.toLowerCase().search(searchKeyWord) !== -1
        || visitor_team.toLowerCase().search(searchKeyWord) !== -1;
    });
    setUpdatedList(tempUpdatedList);
  }
  return (
    <>
      <div className="header-league-section">
        <img className="img-fulid" src={headerSection} alt="header-poster" />
      </div>
      <section className="">
        <Row className="align-items-center justify-content-center mx-0">
          <Col md={12}>
            <div className="header-contain text-center">
              <div className="header pl-0">HighLight</div>
              <div className="sub-header">HighLight</div>
            </div>
          </Col>
          <Col md={12} className="d-flex justify-content-end my-4">
            <Col md={4} className="">
              <TextFieldComponent
                className="custom-input"
                name="deviceName"
                id="deviceName"
                type="text"
                placeholder="Search here..."
                onChange={handleSearchInput}
                // value={initialValues.deviceName}
                // onBlur={handleFocus}
                // onChange={handleChangeValue}
                // error
                // helperText={isError.deviceName}
              />
            </Col>
          </Col>
        </Row>
      </section>

      <section className="mb-5">
        <Row className="mx-0">
          {updatedList.length ? updatedList.map((item) => (
            <Col md={4} xs={12} className="mb-4">
              <Card className="custom-card video-card">
                <img
                  // src={headerSection}
                  src={constants.IMG_URL + item.image}
                  className="img-fluid"
                  style={{ height: "300px", objectFit: "cover", width: "100%" }}
                />
                <div
                  className="video-play-button"
                  onClick={() => handlePlayVideo(item)}
                >
                  <img src={playButton} alt="play" />
                </div>
                <div className="custom-card-body">
                  <h5>
                    {item.home_team} vs {item.visitor_team}
                  </h5>
                  <span>{item.title}</span>
                </div>
              </Card>
            </Col>
          )) : (
            <div className="no-data-found"> Data not Found </div>
          )}
        </Row>
      </section>
      <Dialog open={isOpen} disableBackdropClick maxWidth="md" scroll="paper">
        <VideoPlayers />
      </Dialog>
    </>
  );
}

export default Watch;
