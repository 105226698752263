import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// import "./Home.scss";
import { useOnMount } from '../../utils/helpers';
import { getStoryArticles } from '../../Store/actions/HomeAction';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../utils/constants';
import { useParams } from 'react-router-dom';
import './Story.scss'


function StoryDetails(props) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { storyListArticle } = useSelector((state) => state.home);

  let storyArticle = {};
  if (storyListArticle.length > 0) {
    storyArticle = storyListArticle.find((item) => item.id === parseInt(id));
  }

  useOnMount(() => {
    window.scrollTo(0, 0);
    dispatch(getStoryArticles());
  });
  return (
    <div className=''>
      {/* <div className="header-league-section "> */}
      <div className=" " style={{display:"flex",alignItems:"center","justifyContent":"center",backgroundColor:"#1f1f21"}}> 
        <img
          // className="img-fulid"
          src={constants.IMG_URL + storyArticle?.image}
          alt="header-poster"
          width={"260px"}
          height={"260px"}
        />
      </div>
      <section className="section-3 ">
        {/* <div className="header-contain">
          <div className="header">Blog Details</div>
          <div className="sub-header">Blog Details</div>
        </div> */}
        <div>
            <h2 className='title-head'>Blog Details</h2>
          </div>
        <Row className="feature-row text-left " xs={12} md={12} lg={12}>
          <Col md={12}>
            <div className="mb-3 ">
              <h2>{storyArticle?.title}</h2>
            </div>
            <div className="">
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: storyArticle?.description,
                  }}
                />
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default StoryDetails;
