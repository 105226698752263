import React from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { Router } from 'react-router-dom';
import history from './router/history';
import { getStore } from './Store/configureStore';
import { Provider } from 'react-redux';

const AppReduxRouter = () => {
  const myStore = getStore();
  return (
      <Provider store={myStore}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
  );
};

ReactDOM.render(
  <AppReduxRouter />,
  document.getElementById('root')
);