import React, { useEffect, useState } from 'react';
import { Card, CardImg, Col, Row } from 'react-bootstrap';
import TextFieldComponent from '../../Components/TextFieldComponent/TextFieldComponent';
import headerSection from '../../assets/images/headerSection.jpg';
import playerOne from '../../assets/images/player1.png';
import playerTwo from '../../assets/images/player2.png';
import playerThree from '../../assets/images/player3.png';
import './Player.scss';
import { Link } from 'react-router-dom';
import constants from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useOnMount } from '../../utils/helpers';
import { getPlayerListAction } from '../../Store/actions/HomeAction';

function PlayersList(props) {
  const dispatch = useDispatch();
  const { results, count } = useSelector((state) => state.home.playerList);
  const [updatedList, setUpdatedList] = useState([]);

  useOnMount(() => {
    dispatch(getPlayerListAction());
  });
  useEffect(() => {
    setUpdatedList(results);
  }, [results]);
  const handleSearchInput = (event) => {
    let tempUpdatedList = results.length ? results : [];
    tempUpdatedList = tempUpdatedList.filter((item) => {
      const { first_name, last_name } = item;
      const searchKeyWord = event.target.value.toLowerCase().trim();
      return (
        `${first_name} ${last_name}`.toLowerCase().search(searchKeyWord) !== -1
      );
    });
    setUpdatedList(tempUpdatedList);
  };
  return (
    <>
      <div className="">
        <div className="header-league-section">
          <img className="img-fulid" src={headerSection} alt="header-poster" />
        </div>
        <section className="px-3">
          <Row className="align-items-center justify-content-between">
            <Col md={12}>
              <div className="header-contain text-center">
                <div className="header pl-0">Players</div>
                <div className="sub-header">Players</div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col md={4}>
              <TextFieldComponent
                className=""
                name="deviceName"
                id="deviceName"
                type="text"
                placeholder="Search Here"
                onChange={handleSearchInput}
                // value={initialValues.deviceName}
                // onBlur={handleFocus}
                // onChange={handleChangeValue}
                // error
                // helperText={isError.deviceName}
              />
            </Col>
          </Row>
        </section>
        <section className="px-3 mt-5 player-list">
          <Row>
            {updatedList.length ? (
              updatedList.map((item) => (
                <Col md={3}>
                  <Link
                    to={`${constants.ROUTE.PLAYER.PLAYER_DETAILS}/${item.id}`}
                  >
                    <Card>
                      <Card.Img src={constants.IMG_URL + item.avtar} />
                      <Card.Body>
                        <Row className="justify-content-between">
                          <Col>Player Name</Col>
                          <Col>
                            HEIGHT: {item.height ? item.height : '-'}{' '}
                            <span>cm</span>
                          </Col>
                        </Row>
                        <Row className="justify-content-between mt-3">
                          <Col>{`${item.first_name} ${item.last_name}`}</Col>
                          <Col>
                            WEIGHT: {item.weight ? item.weight : '-'}
                            <span>lbs</span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))
            ) : (
              <div className="no-data-found"> Data not Found </div>
            )}
          </Row>
        </section>
      </div>
    </>
  );
}

export default PlayersList;
