import { Snackbar } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import "./assets/css/style.scss";
import Layout from "./Components/Layout/Layout";
import { closeAlert } from "./Store/actions/alert";
import { headerRoute } from "./utils/routes";
import 'video-react/dist/video-react.css';
import {NotificationContainer} from 'react-notifications';
// import { header } from './utils/routes';

function App() {
  const dispatch = useDispatch();
  const alert = useSelector((stateAlert) => stateAlert.alert);
  const handleAlertClose = () => {
    dispatch(closeAlert());
  };
  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            {headerRoute.map((item, index) => {
              return (
                <Route
                  path={item.path}
                  exact
                  component={item.component}
                  key={index}
                />
              );
            })}
            <Redirect to="/" />
          </Switch>
          <Snackbar
            open={!!alert.message}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleAlertClose} severity={alert.type}>
              {alert.message}
            </Alert>
          </Snackbar>
        </Layout>
      </Router>

      <NotificationContainer/>     </div>
  );
}

export default App;
