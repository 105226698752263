import { produce } from "immer";
import {   SET_LEAGUE_LIST, SET_LEAGUE_TEAM_LIST, SET_LEAGUE_TEAM_PLAYER, SET_LEAGUE_TEAM_SCORE, SET_SCHEDULE_DETAILS,SET_LEAGUE_STANDINGS,SET_LEAGUES_TEAM_LIST, SET_LEAGUE_STATISTICS,SET_SCHEDULE_STATISTICS,SET_PLAYER_DETAIL,SET_PLAYER_STATISTICS} from "../actions/actionTypes";

const initialState = {
  list: [],
  schedule: [],
  teamList: [],
  teamPlayer: [],
  teamScore: [],
  standing:[],
  leagueTeamList:[],
  stats:[],
  scheduleStats:[],
  playerDetail:[],
  playerStats:[]
};

const leagueReducer = (draft, action) => {
  switch (action.type) {
    case SET_LEAGUE_LIST: {
      draft.list = action.payload;
      break;
    }
    case SET_SCHEDULE_DETAILS: {
      draft.schedule = action.payload;
      break;
    }
    case SET_LEAGUE_TEAM_LIST: {
      draft.teamList = action.payload;
      break;
    }
    case SET_LEAGUE_TEAM_PLAYER: {
      draft.teamPlayer = action.payload;
      break;
    }
    case SET_LEAGUE_TEAM_SCORE: {
      draft.teamScore = action.payload;
      break;
    }
    case SET_LEAGUE_STANDINGS: {
      draft.standing = action.payload;
      break;
    }
    case SET_LEAGUES_TEAM_LIST: {
      draft.leagueTeamList = action.payload;
      break;
    }
    case SET_LEAGUE_STATISTICS: {
      draft.stats = action.payload;
      break;
    }
    case SET_SCHEDULE_STATISTICS: {
      draft.scheduleStats = action.payload;
      break;
    }
    case SET_PLAYER_STATISTICS: {
      draft.playerStats = action.payload;
      break;
    }
    case SET_PLAYER_DETAIL: {
      draft.playerDetail = action.payload;
      break;
    }
    
    default:
      break;
  }
};

export default produce(leagueReducer, initialState);
