import { Button, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import TextInput from "../TextInput/TextInput";
import { sendContactDetails,sendContact } from "../../Store/actions/HomeAction";
import { setAlert } from "../../Store/actions/alert";
import "./ContactUs.scss";
import Stack from '@mui/material/Stack';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CustomeNotification from "../CustomeNotification/CustomeNotification";
import 'react-notifications/lib/notifications.css';

const ContactUs = () => {
  const dispatch = useDispatch();
  const businessType = useSelector((state) => state);
  const [isError, setIsError] = useState({});
  const [isFocus, setIsFocus] = useState({});
  const [open, setOpen] = React.useState(false);
  // const [contact, setContact] = useState({
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   phone: "",
  //   country: "",
  //   state: "",
  //   message: "",
  // });

  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    comment: ""
  });
  // const [isError, setIsError] = useState({
  //   "first_name": "",
  //   "last_name": "",
  //   email: "",
  //   phone: "",
  //   country: "",
  //   state: "",
  //   message: "",
  // });

  const validateEmail = (email) => {
    const emailRegex =
      /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (email && emailRegex.test(email.trim())) {
      return true;
    }
    return false;
  };

  const validatePhoneNumber = (number) => {
    // const patt = /^[0-9]*$/;

    // return patt.test(number);
    return /^\d{10}$/.test(number);
  };

  const handleChangeInput = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
    setIsError({
      ...isError,
      [e.target.name]: "",
    });
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    setOpen(true)
    const validation = validateform(contact);
    if (Object.keys(validation).length > 0) {
      // console.log("eee");
    //   <>
    //   <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
    //   <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    //     This is a success message!
    //   </Alert>
    // </Snackbar>
    // <Alert severity="success">This is a success message!</Alert> </>
      CustomeNotification("error","error" ,"Please Fill All Data", 2000);
      setIsError(validation);
      return;
    }
     else {
      // console.log("hii");
      // dispatch(sendContactDetails(contact));
      dispatch(sendContact(contact));
      
      setContact({
        name: "",
        email: "",
        phone: "",
        comment: ""
      })
    }
  };
  // console.log("cnt",contact);
  const isValidation = (formData) => {
    let isFindError = true;
    Object.keys(formData).forEach((key, index) => {
      if (!formData[key]) {
        setIsError({
          ...isError,
          [key]: "field is required",
        });
        isFindError = false;
      } else {
        setIsError({
          ...isError,
          [key]: "",
        });
      }
    });
    return isFindError;
  };

  const validateform = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    }
    if (values.name.trim() == "") {
      errors.name = "Name is required";
    }
    // if (!values.last_name) {
    //   errors.last_name = "Last name is required";
    // }
    // if (values.last_name.trim() == "") {
    //   errors.last_name = "Last name is required";
    // }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(values.email)) {
      errors.email = "Please enter a valid email";
    }
    if (values.email.trim() == "") {
      errors.email = "Email is required";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required";
    } else if (!validatePhoneNumber(values.phone)) {
      errors.phone = "Please enter a valid phone number";
    }
    // if (!values.country) {
    //   errors.country = "Country is required";
    // }
    // if (values.country.trim() == "") {
    //   errors.country = "Country is required";
    // }
    // if (!values.state) {
    //   errors.state = "State is required";
    // }
    // if (values.state.trim() == "") {
    //   errors.state = "State is required";
    // }
    if (!values.comment) {
      errors.comment = "Message is required";
    }
    if (values.comment.trim() == "") {
      errors.comment = "Message is required";
    }
    return errors;
  };

  const handleFocus = (e) => {
    const validation = validateform(contact);
    setIsError(validation);
    setIsFocus({ ...isFocus, [e.target.name]: true });
  };
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    // <section className="section-3">
    //   <div className="header-contain">
    //     <div className="header">Contact Us</div>
    //     <div className="sub-header">Contact Us</div>
    //     <div className="sub-title mt-3">
    //       Reach out to us to host your Leagues
    //     </div>
    //   </div>
    //   <Grid className="contact-us" container spacing={3}>
    //     <Grid item xs={4}>
    //       <TextField
    //         id="first_name"
    //         name="first_name"
    //         fullWidth
    //         onChange={handleChangeInput}
    //         label="First Name"
    //         onBlur={handleFocus}
    //         error={isError.first_name && isFocus.first_name ? true : false}
    //         helperText={isError.first_name}
    //       />
    //     </Grid>
    //     <Grid item xs={4}>
    //       <TextField
    //         id="last_name"
    //         name="last_name"
    //         fullWidth
    //         onChange={handleChangeInput}
    //         label="Last Name"
    //         onBlur={handleFocus}
    //         error={isError.last_name && isFocus.last_name ? true : false}
    //         helperText={isError.last_name}
    //       />
    //     </Grid>
    //     <Grid item xs={4}>
    //       <TextField
    //         id="email"
    //         name="email"
    //         type="email"
    //         fullWidth
    //         label="Email address"
    //         onChange={handleChangeInput}
    //         onBlur={handleFocus}
    //         error={isError.email && isFocus.email ? true : false}
    //         helperText={isError.email}
    //       />
    //     </Grid>
    //     <Grid item xs={4}>
    //       <TextField
    //         id="phone"
    //         name="phone"
    //         // type="number"
    //         fullWidth
    //         label="Phone number"
    //         onChange={handleChangeInput}
    //         onBlur={handleFocus}
    //         error={isError.phone && isFocus.phone ? true : false}
    //         helperText={isError.phone}
    //       />
    //     </Grid>
    //     <Grid item xs={4}>
    //       <TextField
    //         id="country"
    //         name="country"
    //         fullWidth
    //         label="Country"
    //         onChange={handleChangeInput}
    //         onBlur={handleFocus}
    //         error={isError.country && isFocus.country ? true : false}
    //         helperText={isError.country}
    //       />
    //     </Grid>
    //     <Grid item xs={4}>
    //       <TextField
    //         id="state"
    //         name="state"
    //         fullWidth
    //         label="State"
    //         onChange={handleChangeInput}
    //         onBlur={handleFocus}
    //         error={isError.state && isFocus.state ? true : false}
    //         helperText={isError.state}
    //       />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <TextField
    //         id="message"
    //         name="message"
    //         fullWidth
    //         label="Message"
    //         onChange={handleChangeInput}
    //         onBlur={handleFocus}
    //         error={isError.message && isFocus.message ? true : false}
    //         helperText={isError.message}
    //       />
    //     </Grid>
    //     <Grid className="text-center" item xs={12}>
    //       <ButtonComponent
    //         variant="contained"
    //         color="primary"
    //         // color="black"
    //         type="submit"
    //         className="text-drak"
    //         onClick={onSubmitForm}
    //       >
    //         Send Message
    //       </ButtonComponent>
    //     </Grid>
    //   </Grid>
    // </section>
    <div className="contact-form">
      <div className="inner-contact container">
        <div className="d-flex justify-content-center">
          <div className="col-12 col-md-9">
            <h2 className="contact-head">Contact</h2>
            <form>
              <div class="d-block d-md-flex">
                <div class="col-12 col-md-6">
                  <input
                     id="name"
                     name="name"
                     value={contact.name}
                    type="text"
                    className="form-control"
                    placeholder="Name *"
                    onBlur={handleFocus}
            error={isError.name && isFocus.name ? true : false}

                    onChange={(e) => handleChangeInput(e)}
                  />
                </div>
                <div class="col-12 col-md-6">
                  <input
                    id="email"
                    name="email"
                    value={contact.email}

                    type="email"
                    className="form-control"
                    placeholder="Email *"
            error={isError.email && isFocus.email ? true : false}

                    // onChange={handleChangeInput}
                    onBlur={handleFocus}

                    onChange={(e) => handleChangeInput(e)}
                  />
                </div>
              </div>
              <div class="d-block d-md-flex">
                <div class="col-12 col-md-12">
                  <input
                  id="phone"
                  value={contact.phone}

                  name="phone"
                    type="number"
                    className="form-control"
                    placeholder="Phone number *"
                    onBlur={handleFocus}
            error={isError.phone && isFocus.phone ? true : false}


                    // onChange={handleChangeInput}
                    onChange={(e) => handleChangeInput(e)}
                  />
                </div>
              </div>
              <div className="d-block d-md-flex">
                <div className="col-12">  
                  <textarea
                    className="form-control"
                    value={contact.comment}

                    // id="exampleFormControlTextarea1"
                    id="comment"
                     name="comment"
                    rows="3"
                    placeholder="Comment *"
                    onBlur={handleFocus}
            error={isError.comment && isFocus.comment ? true : false}


                    // onChange={handleChangeInput}
                    onChange={(e) => handleChangeInput(e)}
                  ></textarea>
                </div>
              </div>
              <button className="btn-send" type="submit" onClick={onSubmitForm}>Send</button>
              {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          This is a success message!
        </Alert>
      </Snackbar>
      <Alert severity="success">This is a success message!</Alert> */}
            </form>
          </div>
        </div>
      </div>
     {/* {console.log("cnt2",contact)} */}
    </div>
    
  );
};

export default ContactUs;
