import axios from 'axios';
import { setError } from '../Store/actions/error';
import { sendSignOutRequest } from '../Store/actions/auth';
import { setLoading } from '../Store/actions/loading';
// import { getStoreAuthToken } from '../utils/helpers';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  responseType: 'json',
  headers: {
    'Hoop-live-start': 'WEB',
    // 'Authorization': `Bearer ${getStoreAuthToken()}`
  },
});

export const userSigninRequest = (formData) =>
  apiClient.post('/admin/login', formData);
export const resetPasswordApi = (data) =>
  apiClient.post('/admin/change-password', data);
export const forgotPasswordApi = (data) =>
  apiClient.post('/admin/forgot-password', data);
export const getUserDetail = (id, authToken) =>
  apiClient.get('/admin/user', {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

// League
export const getLeagueList = () => apiClient.get('/player/leaguelist/1');
export const createLeague = (data) => apiClient.post('/league/create', data);
export const updateLeague = (data) => apiClient.post('/league/update', data);
export const deleteLeague = (id) => apiClient.delete(`/league/remove/${id}`);

// Team
export const getTeamList = (id) => apiClient.get(`/team/team-listing/${id}`); // team list
export const getTeamPlayer = (id) => apiClient.get(`team/roster-score/${id}`); // team Player
export const getTeamDetails = (id) => apiClient.get(`/team/team/${id}`);
export const createTeam = (data) => apiClient.post('/team/create-team', data);
export const updateTeam = (id, data) =>
  apiClient.post(`/team/update-team/${id}`, data);
export const deleteTeam = (id) => apiClient.delete(`/team/delete-team/${id}`);
export const getTeamScore = (id) => apiClient.get(`/team/team-score/${id}`); // overview
export const getTeamStats = (id) => apiClient.get(`/team/stats/1/${id}`);

export const getLeagueTeamList = (id) => apiClient.get(`/team/team-list/${id}`); // team list


// Players
export const getAllPlayerList = () =>
  apiClient.get('/player/all-player-list?limit=2000&offset=1');
export const getAllCoachList = () => apiClient.get('/player/coatch-list');
export const getPlayerTeamList = () => apiClient.get('/player/teamlist/1');
export const getTeamRoster = () => apiClient.get('/player/team-roster/2');
export const getCoachRoster = () => apiClient.get('/player/team-roster/2');
export const getCoachDetail = (id) => apiClient.get(`/player/coatch/3`);
export const getPlayerDetail = (id) => apiClient.get(`/player/player/${id}`);
export const deletePlayer = (id) => apiClient.get(`/player/delete/${id}`);
export const createPlayer = (data) =>
  apiClient.post('/player/create-player/3', data);
export const createCoach = (data) =>
  apiClient.post('/player/create-coatch/3', data);
export const updatePlayer = (data) =>
  apiClient.post('/player/update-player/1', data);
export const updateCoach = (data) =>
  apiClient.post('/player/update-coatch/1', data);
export const getPlayerStatics = (id) =>
  apiClient.get(`/player/player-statics/${id}`);

//Player Stats and player details

export const getPlayerDetails = (id) => apiClient.get(`/player/statistics/player-details/${id}`); // player details 
export const getPlayerStats = (id) => apiClient.get(`/player/statistics/player-stats/${id}`); // player stats


// Highlights
export const getHighlightListApi = () =>
  apiClient.get('/highlight/hightlight-list');

// Schedule
export const getScheduleAllData = () =>
  apiClient.get('/schedule/get-all-data/3');
export const getAllScheduleList = (id) =>
  apiClient.get(`/schedule/all-schedule-list-league/${id}`); // schedule Detail
export const getScheduleList = () =>
  apiClient.get('/schedule/schedule-list/28');
export const getScheduleDetail = () => apiClient.get('/schedule/schedule/1');
export const createSchedule = (data) =>
  apiClient.post('/schedule/create-schedule', data);
export const updateSchedule = (data) =>
  apiClient.post('/schedule/update-schedule/1', data);
export const deleteSchedule = () => apiClient.get('/schedule/schedule/1');
export const signInSchedule = (data) =>
  apiClient.post('/schedule/create-schedule', data);

export const getSchedulePlayerList = () =>
  apiClient.get('/schedule/get-all-player/47');
export const updatePresentPlayer = (data) =>
  apiClient.put('/schedule/update-present-player/3', data);
export const getPresentPlayerDetail = () =>
  apiClient.get('/schedule/get-present-player/3');
export const updateTopFivePlayer = () =>
  apiClient.put('/schedule/update-topfive-player/3');

//Schedule Stats
export const getScheduleStats = (match_id) => apiClient.get('/schedule/get-all-data-main/'+match_id); 


//League Standing
export const getLeagueStandings = (data) =>
  apiClient.post('/team/standings', data);

//League Statistics
export const getLeagueStatistics = (id) => apiClient.get(`/scoresheet/statistics/${id}`); // league stats list

//section one
export const getSectionOneApi = () =>
  apiClient.get('/intro/section-one-list');

//section two
export const getSectionTwoApi = () =>
  apiClient.get('/intro/section-two-list');

//section two by id
export const getSectionTwoByIdApi = (id) =>
  apiClient.get(`/intro/section-two-list/${id}`);  

//section faq
export const getSectionFaqApi = () =>
  apiClient.get('/intro/section-faq-list');  



// Score
export const getAllScoreSheet = () =>
  apiClient.get('/scoresheet/list?limit=100&offset=1'); // League List
export const getTeamPoint = () => apiClient.get('/score/team-point');
export const addTeamPoint = () => apiClient.post('/score/add-point');
export const addFreeThrow = () => apiClient.post('/score/add-free-throw');
export const addFouls = () => apiClient.post('/score/add-fouls');
export const getScoreSheet = () =>
  apiClient.get('/scoresheet/statistics/1?limit=2&offset=0');

// Rules - admin panel
export const addRules = () => apiClient.post('/rule/add-rules');
export const getRules = () => apiClient.get('/rule/rules/141');

// Feature
export const updateFeature = () => apiClient.post('/features/update-feature/1');
export const createFeature = () => apiClient.post('/features/create-feature');
export const getFeatureDetails = () => apiClient.get('/features/1');
export const getFeatureList = () => apiClient.get('/features/feature-list');
export const deleteFeature = () => apiClient.get('/features/delete-feature/1');

// Blog
export const getBlogList = () => apiClient.get('/blog/blog-list'); // news articles
export const updateBlog = () => apiClient.post('/blog/update-blog/1');
export const createBlog = () => apiClient.post('/blog/create-blog');
export const getBlogDetails = () => apiClient.get('/blog/1');
export const deleteBlog = () => apiClient.get('/blog/delete-blog/1');

// Story
export const getStoryList = () => apiClient.get('/story/story-list');
export const updateStory = () => apiClient.post('/story/update-story/1');
export const createStory = () => apiClient.post('/story/create-story');
export const getStoryDetail = () => apiClient.get('/story/1');
export const deleteStory = () => apiClient.get('/story/delete-story/1');

// Contact Us
export const changeContactDetails = () =>
  apiClient.post('/contact-us/change-details');
export const sendContactUs = (data) =>
  apiClient.post('/contact-us/contact-us', data);
export const sendContact = (data) =>
  apiClient.post('/contact-us/website-contact-us', data);

//Email subscribe us
export const sendEmailSubscription = (data) =>
  apiClient.post('/contact-us/email-subscription', data);

export const getPricingList = () => apiClient.get('/pricing/pricing-list');


// Terms and condition
export const getAboutUsApi = () => apiClient.get('/contact-us/about-us');
export const getPrivacyPolicy = () => apiClient.get('/contact-us/privacy');
export const getTermsAndCondition = () => apiClient.get('/contact-us/terms');
export const getSocialMedia = () => apiClient.get('/contact-us/get-social');
export const addSocialMedia = () => apiClient.post('/contact-us/add-social');
export const getFaqListApi = () => apiClient.get('faq/faq-list');
export const createFAQApi = (data) => apiClient.post('faq/create-faq', data);


// team list
export const getTeamListWithScore = (league_id) => apiClient.get('/team/team-score/'+league_id);
export const getbreakdown = (team_id) => apiClient.get('/team/breakdown/'+team_id); 
export const getStatics = (team_id) => apiClient.get('/scoresheet/team-statistics/'+team_id);
export const callStateDataQuater = (team_id, quater) => apiClient.get(`/Team/stats/${team_id}/${quater}`);

// Add Images or File
export const uploadFile = () => apiClient.post('/common/upload');

export const downloadVideoRequest = (url, options) =>
  axios.get(url, {
    responseType: 'blob',
    ...options,
  });

export const setUpInterceptor = (store, history) => {
  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      let e = error;

      if (
        error.response.status === 500 &&
        error.response.data.message === 'Expired or invalid JWT token'
      ) {
        store.dispatch(sendSignOutRequest());
        store.dispatch(setLoading(false));
        store.dispatch(
          setError({
            type: 'auth',
            message: 'Your session has expired. Please sign in.',
          })
        );
        e = null;
      }

      return Promise.reject(e);
    }
  );
};
