import React from 'react';
import { Col, Row } from 'react-bootstrap';
import headerSection from '../../assets/images/headerSection.jpg';
import StoryCard from '../../Components/StoryCard/StoryCard';
// import { getNewsArticles } from "../../Store/actions/HomeAction";
import { useOnMount } from '../../utils/helpers';
import { getStoryArticles } from '../../Store/actions/HomeAction';
import { useDispatch, useSelector } from 'react-redux';
import constants from '../../utils/constants';
import './Story.scss'
// import headerSection from "../../assets/images/headerSection.jpg";

const Story = () => {
  const dispatch = useDispatch();
  const { storyListArticle } = useSelector((state) => state.home);
  useOnMount(() => {
    // dispatch(getFeature());
    dispatch(getStoryArticles());
  });
  return (
    <div className="new-article">
      {/* <img className="header-section" src={headerSection} alt="header-poster" /> */}
      <Row className="align-items-center justify-content-between mx-0">
        <Col md={12}>
          {/* <div className="header-contain border-bottom text-center">
            <div className="header">News</div>
            <div className="sub-header">News</div>
          </div> */}
          <div>
            <h2 className='title-head'>News</h2>
          </div>
        </Col>
      </Row>
      <Row className="mx-0">
        {storyListArticle.map((story) => (
          // <Col md={4} className="py-3">
          <Col md={4} className="py-4 d-flex">
            <StoryCard
              id={story.id}
              url={constants.IMG_URL + story.image}
              title={story.title}
              date={story.updatedAt}
              description={story.description}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Story;
