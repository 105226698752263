import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/helpers';
import './StoryCard.scss';
const StoryCard = ({ url, title, date, description, id }) => {
  const diff_hours = (storyDate) => {
    const dt1 = new Date();
    const dt2 = new Date(storyDate);
    let diff = (dt1.getTime() - dt2.getTime()) / 1000;
    diff /= 60 * 60;
    if (diff < 48) {
      return `${Math.abs(Math.round(diff))} Hours ago`;
    } else {
      return `${formatDate(dt2, 'DD/MM/yyyy')} ago`;
    }
  };
  return (
    <div className="story-card">
      <img className="article-img" src={url} alt={title} />
      <div className="display-contain">
        <div className="d-flex justify-content-between">
          <div className="header">{title}</div>
          <div className="date">{diff_hours(date)}</div>
        </div>
        <div className="contain">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <Button
          variant="dark"
          size="sm"
          // onClick={() => dispatch(setBlogsDetails(article))}
          className="bg-dark"
          style={{    position: 'absolute', bottom: '35px'}}
        >
          <Link
            to={`/story-details/${id}`}
            className="text-white"
            style={{ textDecoration: 'none' }}
          >
            Read More
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default StoryCard;
