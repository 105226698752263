import { eachYearOfInterval } from 'date-fns';
import { all, takeEvery } from 'redux-saga/effects';
// import {
//   fetchFliksVideosSaga,
// } from './fliks';
import {
  GET_ABOUT_US,
  GET_COACH_LIST,
  GET_FAQ,
  GET_FEATURES,
  GET_HIGHLIGHT_LIST,
  GET_LEAGUE_LIST,
  GET_LEAGUE_STATUS,
  GET_NEWS_ARTICLES,
  GET_PLAYER_DETAILS,
  GET_PLAYER_LIST,
  GET_PRIVACY_POLICY,
  GET_SCHEDULE_DETAILS,
  GET_STORY_ARTICLES,
  GET_TERM_AND_CONDITION,
  SEND_CONTACT_DETAILS,
  SEND_QUERY,
  USER_SIGNIN_INIT,
  GET_LEAGUE_STANDINGS,
  GET_LEAGUES_TEAM_LIST,
  GET_LEAGUE_STATISTICS,
  GET_TEAMPLAYER_STATISTICS,
  GET_SCHEDULE_STATISTICS,
  GET_PLAYER_STATISTICS,
  GET_PLAYER_DETAIL,
  SEND_CONTACT,
  SEND_EMAIL_SUBSCRIPTION,
  GET_SECTION_ONE,
  GET_SECTION_TWO,
  GET_SECTION_FAQ,
  GET_SECTION_TWO_ID,
  GET_PRICING_ARTICLES
} from '../actions/actionTypes';
import { fetchAboutUsSaga, fetchCoachListSaga, fetchFAQListSaga, fetchFeatureListSaga, fetchNewsArticlesSaga, fetchPlayerListSaga, fetchPrivacyPolicySaga, fetchStoryArticlesSaga, fetchTermAndConditionSaga, sendContactSaga, sendQuerySaga,sendWebsiteContactSaga,sendEmailSubscriptionSaga, fetchSectionOneSaga, fetchSectionTwoSaga, fetchSectionFaqSaga, fetchSectionTwoIdSaga, fetchPricingArticlesSaga } from './homeSaga';
import { fetchHighLightListSaga, fetchLeagueListSaga, fetchLeagueScheduleDetailSaga, fetchLeagueViewStatusSaga,fetchLeagueStandingsSaga,fetchLeagueTeamsSaga,fetchLeagueStatsSaga ,fetchScheduleStatsSaga, fetchPlayerStatsSaga,fetchPlayerDetailSaga} from './leagueSaga';
import { fetchTeamPlayerStatsSaga} from './teamSaga';

export default function* rootSaga() {
  yield all([watchAuth(), watchHome(), watchLeague(),watchTeam()]);
}

function* watchAuth() {
  // yield takeEvery(USER_SIGNIN_INIT, signinSaga);
}

function* watchHome() {
  yield takeEvery(GET_FEATURES, fetchFeatureListSaga);
  yield takeEvery(GET_NEWS_ARTICLES, fetchNewsArticlesSaga);
  yield takeEvery(SEND_CONTACT_DETAILS, sendContactSaga);
  yield takeEvery(SEND_CONTACT, sendWebsiteContactSaga);
  yield takeEvery(GET_FAQ, fetchFAQListSaga);
  yield takeEvery(SEND_QUERY, sendQuerySaga);
  yield takeEvery(GET_PRIVACY_POLICY, fetchPrivacyPolicySaga);
  yield takeEvery(GET_TERM_AND_CONDITION, fetchTermAndConditionSaga)
  yield takeEvery(GET_PLAYER_LIST, fetchPlayerListSaga);
  // yield takeEvery(GET_PLAYER_DETAILS, fetchPlayerDetailsSaga)
  yield takeEvery(GET_STORY_ARTICLES, fetchStoryArticlesSaga)
  yield takeEvery(GET_ABOUT_US, fetchAboutUsSaga)
  yield takeEvery(GET_COACH_LIST, fetchCoachListSaga)
  yield takeEvery(SEND_EMAIL_SUBSCRIPTION, sendEmailSubscriptionSaga)
  yield takeEvery(GET_SECTION_ONE, fetchSectionOneSaga)
  yield takeEvery(GET_SECTION_TWO, fetchSectionTwoSaga)
  yield takeEvery(GET_SECTION_TWO_ID, fetchSectionTwoIdSaga)
  yield takeEvery(GET_SECTION_FAQ, fetchSectionFaqSaga)
  yield takeEvery(GET_PRICING_ARTICLES, fetchPricingArticlesSaga)

}

function* watchLeague() {
  yield takeEvery(GET_LEAGUE_LIST, fetchLeagueListSaga);
  yield takeEvery(GET_SCHEDULE_DETAILS, fetchLeagueScheduleDetailSaga)
  yield takeEvery(GET_LEAGUE_STATUS, fetchLeagueViewStatusSaga)
  yield takeEvery(GET_HIGHLIGHT_LIST, fetchHighLightListSaga)
  yield takeEvery(GET_LEAGUE_STANDINGS, fetchLeagueStandingsSaga)
  yield takeEvery(GET_LEAGUES_TEAM_LIST, fetchLeagueTeamsSaga)
  yield takeEvery(GET_LEAGUE_STATISTICS, fetchLeagueStatsSaga)
  yield takeEvery(GET_SCHEDULE_STATISTICS, fetchScheduleStatsSaga)
  yield takeEvery(GET_PLAYER_STATISTICS, fetchPlayerStatsSaga)
  yield takeEvery(GET_PLAYER_DETAIL, fetchPlayerDetailSaga)
}

function* watchTeam(){
  yield takeEvery(GET_TEAMPLAYER_STATISTICS,fetchTeamPlayerStatsSaga)
}