/* eslint-disable no-nested-ternary */
import { put, select } from "redux-saga/effects";
import { createFAQApi, getAboutUsApi, getAllCoachList, getAllPlayerList, getBlogList, getFaqListApi, getFeatureList, getPlayerDetail, getPlayerStatics, getPrivacyPolicy, getStoryList, getTermsAndCondition, sendContactUs,sendContact,sendEmailSubscription, getSectionOneApi, getSectionTwoApi, getSectionFaqApi, getSectionTwoByIdApi, getPricingList} from "../../api";
import { setLoading } from "../actions/loading";
import { closeAlert, setAlert } from "../actions/alert";
import { setError, clearError } from "../actions/error";

import { closeModal } from "../actions/modal";
import { handleException } from "../../utils/helpers";
import { getFAQAction, setAboutUsAction, setCoachListAction, setFAQ, setFeature, setNewsArticles, setPlayerDetailsAction, setPlayerListAction, setPricingArticles, setPrivacyPolicy, setSectionFaqAction, setSectionOneAction, setSectionTwoAction, setSectionTwoIdAction, setStoryArticles, setTermAndCondition } from "../actions/HomeAction";
import CustomeNotification from "../../Components/CustomeNotification/CustomeNotification";

export function* fetchFeatureListSaga() {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getFeatureList();
    const featureList = response?.data?.data;
    yield put(setFeature(featureList));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "FeatureList");
  }
}

export function* fetchNewsArticlesSaga() {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getBlogList();
    const articlesList = response?.data?.data;
    yield put(setNewsArticles(articlesList));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "articlesList");
  }
}

export function* fetchStoryArticlesSaga() {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getStoryList();
    const storyListArticle = response?.data?.data;
    yield put(setStoryArticles(storyListArticle));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "storyListArticle");
  }
}

export function* sendContactSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield sendContactUs(action.payload);
    const articlesList = response?.data?.data;
    yield put(setAlert({ type: 'success', message: 'Send Success full' }));
    yield put(closeAlert());
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "SendContact");
  }
}

export function* sendWebsiteContactSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield sendContact(action.payload);
    const contactlist = response?.data

    // console.log("contactlist",response);
   yield put(CustomeNotification("success","success" ,response.data.msg, 2000))
    // yield put(setAlert({ type: 'success', message: 'Send Success full' }));
    // yield put(closeAlert());
    yield put(setLoading(false));
  } catch (e) {
    // console.log("eee",e.response.data)
    yield put(CustomeNotification("error","error" ,e.response.data.msg, 2000))
    yield handleException(e, "SendContact");
  }
}

export function* sendEmailSubscriptionSaga(action) {
  // console.log("action",action.payload);
  // yield put(setLoading(true));
  // yield put(clearError());

  try {
    const response = yield sendEmailSubscription(action.payload);
    const emaillist = response?.data
    // console.log("contactlist",emaillist);
    if(response.data.status==200){
   yield put(CustomeNotification("success","success" ,response.data.msg, 2000))
    } else if(response.data.status==500){
      yield put(CustomeNotification("error","error" ,response.data.msg, 2000))
       }
   
    // yield put(setAlert({ type: 'success', message: 'Send Success full' }));
    // yield put(closeAlert());
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "SendEmail");
  }
}

export function* fetchFAQListSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getFaqListApi(action.payload);
    const faqList = response?.data?.data;
    yield put(setFAQ(faqList));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "SendContact");
  }
}
export function* sendQuerySaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    yield createFAQApi(action.payload);
    yield put(setAlert({ type: 'success', message: 'Send Query Success full' }));
    yield put(getFAQAction())
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "SendContact");
  }
}
export function* fetchPrivacyPolicySaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getPrivacyPolicy(action.payload);
    const policy = response?.data?.data;
    yield put(setPrivacyPolicy(policy));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "getPrivacyPolicy");
  }
}
export function* fetchTermAndConditionSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getTermsAndCondition(action.payload);
    const terms = response?.data?.data;
    yield put(setTermAndCondition(terms));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "getTermAndCondition");
  }
}
export function* fetchAboutUsSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getAboutUsApi(action.payload);
    const about = response?.data?.data;
    yield put(setAboutUsAction(about));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "getAboutUs");
  }
}

export function* fetchPlayerListSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getAllPlayerList(action.payload);
    const playerList = response?.data?.data;
    yield put(setPlayerListAction(playerList));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "playerList");
  }
}

export function* fetchCoachListSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getAllCoachList(action.payload);
    const coachLists = response?.data?.data;
    // console.log("Coach List API", coachLists);
    yield put(setCoachListAction(coachLists));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "coachLists");
  }
}

export function* fetchSectionOneSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getSectionOneApi(action.payload);
    const data = response?.data?.data;
    yield put(setSectionOneAction(data));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "getsectionone");
  }
}

export function* fetchSectionTwoSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getSectionTwoApi(action.payload);
    const data = response?.data?.data;
    yield put(setSectionTwoAction(data));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "getsectiontwo");
  }
}

export function* fetchSectionTwoIdSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getSectionTwoByIdApi(action.payload);
    const data = response?.data?.data;
    yield put(setSectionTwoIdAction(data));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "getsectiontwoid");
  }
}

export function* fetchSectionFaqSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getSectionFaqApi(action.payload);
    const data = response?.data?.data;
    yield put(setSectionFaqAction(data));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "getsectionfaq");
  }
}


export function* fetchPricingArticlesSaga() {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getPricingList();
    const priceListArticle = response?.data?.data;
    yield put(setPricingArticles(priceListArticle));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "priceListArticle");
  }
}

// export function* fetchPlayerDetailsSaga(action) {
//   yield put(setLoading(true));
//   yield put(clearError());

//   try {
//     const response = yield getPlayerStatics(action.payload);
//     const playerDetails = response?.data?.data;
//     yield put(setPlayerDetailsAction(playerDetails));
//     yield put(setLoading(false));
//   } catch (e) {
//     yield handleException(e, "playerDetail");
//   }
// }
