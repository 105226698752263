import React from 'react';
import './Player.scss';
import coach from '../../../src/assets/images/coach.png';
import basketplayer from '../../../src/assets/images/basketballplayer.png';
import { Col, Row } from 'react-bootstrap';
import headerSection from '../../assets/images/headerSection.jpg';
import { Link, useHistory } from 'react-router-dom';

function Profile(props) {
  const history = useHistory();
  return (
    <>
      <div className="header-league-section">
        <img className="img-fulid" src={headerSection} alt="header-poster" />
      </div>
      <section className="px-3">
        <Row className="align-items-center justify-content-between">
          <Col md={12}>
            <div className="header-contain text-center">
              <div className="header pl-0">Profile</div>
              <div className="sub-header">Profile</div>
            </div>
          </Col>
        </Row>
        <Row className="main-profile justify-content-center">
          <Col md={6}>
            <Row className="">
              <Col md={6}>
                <div className="card-profile">
                  <Link className="" to="/profile/coaches">
                    <img src={coach} className="img-fluid" />
                    <p>Coach</p>
                  </Link>
                </div>
              </Col>
              <Col md={6}>
                <div className="card-profile">
                  <Link
                    className=""
                    to="/profile/players-list"
                    className="card-profile"
                  >
                    <img src={basketplayer} className="img-fluid" />
                    <p>Player</p>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Profile;
