import React, { useState } from 'react';
import CardListTable from '../../Components/CardListTable/CardListTable';
import TextFieldComponent from '../../Components/TextFieldComponent/TextFieldComponent';
import TeamDetailsData from '../../Components/DummyTable/DummyTable';
import { orderBy } from 'lodash';
import { AppBar, Box, makeStyles, Tab, Typography } from '@material-ui/core';
import { Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import { useOnMount } from '../../utils/helpers';
import { getLeagueViewStatusAction } from '../../Store/actions/leagueAction';
import Quater from './Quater';

const LeagueOverview = [
    {
        name: "Avtar",
        selector: "img",
        // sortable: true,
        grow: "0.3",
        cell: (row) => {
            return (
                <>
                    <img
                        className="w-50"
                        src={
                            "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
                        }
                        alt="img"
                    />
                </>
            );
        },
    },
    {
        name: "Name",
        selector: "name",
        // sortable: true,
        grow: "4",
    },
    {
        name: "PPG",
        selector: "ppg",
        //   sortable: true,
        grow: "0.5",
    },
    {
        name: "PTS",
        selector: "pts",
        //   sortable: true,
        grow: "0.5",
    },
    {
        name: "DIVISION",
        selector: "division",
        //   sortable: true,
        grow: "0.5",
    },
    {
        name: "POOL",
        selector: "pool",
        //   sortable: true,
        grow: "0.5",
    },
    {
        name: "MP",
        selector: "mp",
        //   sortable: true,
        grow: "0.5",
    },
    {
        name: "AST",
        selector: "ast",
        //   sortable: true,
        grow: "0.05",
    },
    {
        name: "BLK",
        selector: "blk",
        //   sortable: true,
        grow: "0.05",
    },
    {
        name: "FGM",
        selector: "fgm",
        //   sortable: true,
        grow: "0.05",
    },
    {
        name: "FGA",
        selector: "fga",
        //   sortable: true,
        grow: "0.05",
    }
];

function createData(time, home, visitor, status, score) {
    return { time, home, visitor, status, score };
}

const rows = [createData("2:12 pm", "USA", "CANADA", 24, "0 - 0")];

function TeamDetails(props) {
    const [loading, setLoading] = useState(false);
    const [items, setData] = useState();
    const [value, setValue] = React.useState(0);
    const handleSort = (column, sortDirection) => {
        // simulate server sort
        setLoading(true);

        // instead of setTimeout this is where you would handle your API call.
        setTimeout(() => {
            setData(orderBy(items, column.selector, sortDirection));
            setLoading(false);
        }, 100);
    }
    const dispatch = useDispatch();
    const { teamList } = useSelector((state) => state.league);
    const { id } = useParams();
    useOnMount(() => {
        dispatch(getLeagueViewStatusAction(id));
    });
    console.log("props.teamId",props);
    return (
        <>
            <div className="overview p-0">
                <div className="overview-table">
                    <div className="d-flex justify-content-end px-3 py-3">
                        <TextFieldComponent
                            className="w-25"
                            name="deviceName"
                            id="deviceName"
                            type="text"
                            placeholder="Search Here"
                        />
                    </div>
                </div>

            </div>
            <Quater teamId={props?.teamId}/>
        </>
    );
}

export default TeamDetails;