import { produce } from "immer";
import {   SET_TEAMPLAYER_STATISTICS } from "../actions/actionTypes";

const initialState = {
  stats:[]
};

const teamReducer = (draft, action) => {
  switch (action.type) {
    case SET_TEAMPLAYER_STATISTICS: {
      draft.stats = action.payload;
      break;
    }
    
    default:
      break;
  }
};

export default produce(teamReducer, initialState);
