/* eslint-disable no-nested-ternary */
import { put, select } from "redux-saga/effects";
import { getStatics} from "../../api";
import { setLoading } from "../actions/loading";
import { clearError } from "../actions/error";

import { handleException } from "../../utils/helpers";
import { setTeamPlayerStatsAction} from "../actions/TeamAction";
import avtarimg from '../../assets/images/avatar.png'



//league teams list

export function* fetchTeamPlayerStatsSaga(action) {
  yield put(setLoading(true));
  yield put(clearError());

  try {
    const response = yield getStatics(action.payload);
    // console.log("teammmmstt",response);
    const TeamPlayerStatsDetails = response?.data;
    // console.log("qqqq",TeamPlayerStatsDetails);
    let playerlist=TeamPlayerStatsDetails.playerList
    let arr=[]
    // const scoredata=(stats)=>{
      // let finalst=[]
      // finalst.push(stats)
      // console.log("hjhjh",finalst);
      // console.log("sttttaaattss",stats);
      // let arr2=finalst[0].playerList
    playerlist.forEach((element,i) => {
      // console.log("EEE",element);
      arr.push({
        // id: element.id,
        ranking:i+1,
        logo:
          element.avtar!=null && element.avtar!="" ?
          <img
            src={`${process.env.REACT_APP_API_ENDPOINT}${element.avtar}`}
            height={75}
            width={75}
          
          />
          :
          <img
          src={`${avtarimg}`}
          height={75}
          width={75}
        
        />
        ,
        player_id:element.id,
        first_name: element.first_name,
        last_name: element.last_name,
        name: element.team.name,
        town:element.team.town,
        level: element.team.level,
        G: element.score.G,
        PPG:  isNaN(Number(element.score.PPG))
        ? element.score.PPG == "NA"
          ? element.score.PPG
          : 0
        : Number(element.score.PPG),
        // RPG: isNaN(Number(element.score.RPG)) ? 0 : element.score.RPG,
        RPG: isNaN(Number(element.score.RPG))
        ? element.score.RPG == "NA"
          ? element.score.RPG
          : 0
        : Number(element.score.RPG),
        OFF:  isNaN(Number(element.score.OFF))
        ? element.score.OFF == "NA"
          ? element.score.OFF
          : 0
        : Number(element.score.OFF),
        DFE:  isNaN(Number(element.score.DFE))
        ? element.score.DFE == "NA"
          ? element.score.DFE
          : 0
        : Number(element.score.DFE),
        APG:  isNaN(Number(element.score.APG))
        ? element.score.APG == "NA"
          ? element.score.APG
          : 0
        : Number(element.score.APG),
        FT: isNaN(Number(element.score.FT))
        ? element.score.FT == "NA"
          ? element.score.FT
          : 0
        : Number(element.score.FT),
        FG:  isNaN(Number(element.score.FG))
        ? element.score.FG == "NA"
          ? element.score.FG
          : 0
        : Number(element.score.FG),
        // P_3: isNaN(Number(element.score["3P"])) ? 0 : element.score["3P"],
        P_3: isNaN(Number(element.score["3P"]))
        ? element.score["3P"] == "NA"
          ? element.score["3P"]
          : 0
        : Number(element.score["3P"]),
        P_2: isNaN(Number(element.score["2P"]))
        ? element.score["2P"] == "NA"
          ? element.score["2P"]
          : 0
        : Number(element.score["2P"]),
      });
    });
    // console.log("aaaa",arr);
    yield put(setTeamPlayerStatsAction(arr));
    // yield put(setTeamPlayerStatsAction(arr));
    yield put(setLoading(false));
  } catch (e) {
    yield handleException(e, "TeamPlayerStatsDetails");
  }
}

