import React from "react";
import { Row, Button, Col } from "react-bootstrap";
// import "./Home.scss";
import { useOnMount } from "../../utils/helpers";
import { getFeature, getNewsArticles } from "../../Store/actions/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../utils/constants";
import headerSection from "../../assets/images/headerSection.jpg";

function Features(props) {
    const dispatch = useDispatch();
  const { featureList, newsArticles } = useSelector((state) => state.home);
  useOnMount(() => {
    dispatch(getFeature());
    dispatch(getNewsArticles());
  });
    return (
        <div>
        <div className="header-league-section">
        <img className="img-fulid" src={headerSection} alt="header-poster" />
      </div>
            <section className="section-3">
                <div className="header-contain">
                    <div className="header">Check Out our features</div>
                    <div className="sub-header">Check Out our features</div>
                </div>
                <Row className="feature-row" xs={5} md={5} lg={5}>
                    {featureList.map((feature) => (
                        <Col className="py-2">
                            <div className="img-background">
                                <img
                                    src={constants.IMG_URL + feature.image}
                                    alt={feature.title}
                                />
                            </div>
                            <div className="header">{feature.title}</div>
                            <div className="contain">{feature.description}</div>
                        </Col>
                    ))}
                </Row>
            </section>
        </div>
    );
}

export default Features;