import React from 'react';
import headerSection from "../../assets/images/headerSection.jpg";
import ContactUs from '../../Components/ContactUs/ContactUs';
import './ContactUs.scss';

function ContactUsPage(props) {
    return (
        <>
            {/* <div className="header-league-section">
                <img className="img-fulid" src={headerSection} alt="header-poster" />
            </div> */}
            <ContactUs/>
        </>
    );
}

export default ContactUsPage;