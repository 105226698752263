// import React from "react";
// import { Badge, Col, Row } from "react-bootstrap";
// import "./League.scss";
// import headerSection from "../../assets/images/headerSection.jpg";
// import {
//   Table,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
// } from "@material-ui/core";
// import { useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { useOnMount } from "../../utils/helpers";
// import { getLeagueScheduleDetailAction } from "../../Store/actions/leagueAction";



import React,{useState,useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import constants from "../../utils/constants";
import { Link } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { useOnMount } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getLeagueScheduleDetailAction } from "../../Store/actions/leagueAction";
import { useParams } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import basketballgif from '../../assets/images/basketballLoader.gif'

import "./League.scss";




const columns = [
  { id: 'id', label: 'Match Id', align: 'left',
  //  minWidth: 250
  }, 
  { id: 'match_date', label: 'Match Date', align: 'left', 
    // minWidth: 250
  }, 
  { id: 'match_start_time', label: 'Match Time', align: 'left', 
  // minWidth: 250
}, 
  { id: 'home_team_name', label: 'Home Team', align: 'center', 
  // minWidth: 250
},
  { id: 'home_team_town', label: 'Home Team Organization', align: 'center', 
  // minWidth: 250
},
  {
    id: 'visitor_team_name',
    label: 'Visitor Team',  
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
    //  minWidth: 250
  },
  {
    id: 'visitor_team_town',
    label: 'Visitor Team Organization',  
    align: 'center',
    //  minWidth: 250
  },
  {
    id: 'status',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Status',
    align: 'center', 
    // minWidth: 250,
    format: (value)=>value,
    
  },
  {
    id: 'location',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Location',
    align: 'center',
    //  minWidth: 250,
    // format: (value)=>value,
    
  },
  {
    id: 'level',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Level',
    align: 'center', 
    // minWidth: 250,
    // format: (value)=>value,
    
  },
  {
    id: 'court',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Court',
    align: 'center',
    //  minWidth: 250,
    // format: (value)=>value,
    
  },
  {
    id: 'score',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Scores',
    align: 'center', 
    // minWidth: 250,
    format: "123",
    
  },
  {
    id: 'dropdown',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Tools',
    align: 'center', 
    // minWidth: 250
    // format: "123",
    
  },
  // {
  //   id: 'density',
  //   label: 'Density',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: 'black',
    color:'white',
    // color:
    //   theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function createData(time, home, visitor, status, score) {
  return { time, home, visitor, status, score };
}

const rows = [createData("2:12 pm", "USA", "CANADA", 24, "0 - 0")];

function LeagueViewSchedule(props) {
  const dispatch = useDispatch();
  const [updatedList, setUpdatedList] = useState([]);
  const schedule = useSelector((state) => state.league.schedule);
  const {isLoading} = useSelector((state)=>state.loading)

  const { id } = useParams();
  const [rows, setRows] = useState([]);

  // console.log("idd",id);
  useOnMount(() => {
    dispatch(getLeagueScheduleDetailAction(id));
  });
  useEffect(() => {
    setUpdatedList(schedule);
    setRows(schedule);

  }, [schedule]);


  // let scheduleList=schedule.map((item)=>{
  //   // console.log("iii000000",item);
  //   if(item.is_match_started==null){
  //     return {...item, status: 'Did not start'};
  //   }else if(item.is_match_completed==0){
  //     return {...item, status: 'In Progress'};
  //   }else{
  //     return {...item, status: 'Completed'};

  //   }
  //   return item;
  // })

  // let scorechng=scheduleList.map(v => ({...v, score: `(H) ${ v.home_team_score} - (V) ${ v.visitor_team_score}`}))

  // console.log("ssss",scorechng);

  // console.log("vieee",schedule);

  // const handleSearchInput = (event) => {
  //   let tempUpdatedList = schedule.length ? schedule : [];
  //   tempUpdatedList = tempUpdatedList.filter((item) => {
  //     console.log("item111",item);
  //     const {
  //       id
  //     } = item;
  //     const searchKeyWord = event.target.value.toLowerCase().trim();
  //     console.log("seee",searchKeyWord);
  //     return id.toLowerCase().search(searchKeyWord) !== -1 ;
  //   });
  //   setUpdatedList(tempUpdatedList);
  // }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    const filteredRows = updatedList.filter((row) => {
      const {id,home_team_name,visitor_team_name,match_start_time,status,score,location,level,court}=row
      // return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      return id.toLocaleString().toLowerCase().includes(searchedVal.toLowerCase()) || home_team_name.toLowerCase().includes(searchedVal.toLowerCase()) || visitor_team_name.toLowerCase().includes(searchedVal.toLowerCase())
      || match_start_time.includes(searchedVal) || status.toLowerCase().includes(searchedVal.toLowerCase()) 
     || location.toLowerCase().includes(searchedVal.toLowerCase()) || level.toLocaleString().toLowerCase().includes(searchedVal.toLowerCase()) || court.toLowerCase().includes(searchedVal.toLowerCase())
    });
    setRows(filteredRows);
    // setUpdatedList(filteredRows);
  };
  // console.log("rrrr",rows);

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
    setRows(updatedList)
  };

  
  return (
    <>
      {/* <div className="header-league-section">
        <img className="img-fulid" src={headerSection} alt="header-poster" />
      </div> */}
      {/* <div className="px-3">
        <section className="title">
          <Row className="align-items-center justify-content-between">
            <Col md={12}>
              <div className="header-contain text-center">
                <div className="header pl-0">Basketball League Schedule</div>
                <div className="sub-header">Basketball League Schedule</div>
              </div>
            </Col>
          </Row>
        </section>

        <section className="list-league">
          <div className="list-league-title">
            <p>Upcomming Game Schedule</p>
          </div>
          {schedule?.length ? schedule.map((item) => (
            <TableContainer className="list-league-container">
              <div className="list-league-container-title pl-3">
                <b>{item.match_date}</b>
              </div>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{width:"100px"}}>Time</TableCell>
                    <TableCell align="center" style={{width:"400px"}}>Home</TableCell>
                    <TableCell align="center" style={{width:"400px"}}>Visitor</TableCell>
                    <TableCell align="center" style={{width:"400px"}}>Status</TableCell>
                    <TableCell align="center">Scores</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell scope="row">{item.match_start_time}</TableCell>
                      <TableCell align="center">
                        <span style={{ color: "#f39e1d" }}>{item.home_team_name} </span>
                      </TableCell>
                      <TableCell align="center" style={{ color: "#f39e1d" }}>
                        {item.visitor_team_name} 
                      </TableCell> 
                      <TableCell align="center">
                        <Badge variant="success">{item?.is_match_started === null ? "Did not start" : item.is_match_completed === 0 ? "In Progress" : "Complete"}</Badge>
                      </TableCell>
                      <TableCell align="center">{item.home_team_score} - {item.visitor_team_score}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )): <div className="no-data-found"> No SCHEDULE Available </div>}
        </section>
      </div> */}
        <div className='league-head'>Basketball League Schedule</div>
    <div style={{backgroundColor: '#1F1F21'}}>
    <div className='container' style={{paddingBottom: 50, paddingTop: 50}}>
    <div style={{display:"flex",justifyContent:"flex-end"}}  className='searchbar'>
        <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          // style={{width:"30%",border:"none",height:"40px",marginBottom:"14px",color:"black"}}
          className='search'
        />
        </div>
    <Paper className='league_table_body' sx={{ width: '100%', overflow: 'hidden'}}>
      <TableContainer >
   
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows */}
            {!isLoading ?
            rows?.length ? rows
            // {updatedList?.length ? updatedList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                // console.log("rrr",row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      // console.log("vvv",value);
                      return (
                        <>
                        {column.id !== "dropdown" ? column.id!=="home_team_name" ? column.id!=="visitor_team_name" ?
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>: <TableCell key={column.id} align={column.align}>
                        {/* <Link to={`${constants.ROUTE.TEAM.PLAYERSTATS}/${row.visitor_team}`} style={{color: "#e5b26a"}}>{value}</Link> */}
                        <Link to={`${constants.ROUTE.TEAM.PLAYERSTATS}/${row.visitor_team}`} style={{color: "orange"}}>{value}</Link>
                        </TableCell>:<TableCell key={column.id} align={column.align}>
                        <Link to={`${constants.ROUTE.TEAM.PLAYERSTATS}/${row.home_team}`} style={{color: "orange"}}>{value}</Link>
                        </TableCell>
                      :
                          <TableCell className="tableData">
       
                             <div>
        <Link to={`${constants.ROUTE.TEAM.HOMESTATS}/${row.id}`} style={{color: "white", textDecoration: "none"}}>

      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        View Team Stats
      </Button>
      </Link>
      {/* <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="menubar"
      >
        <MenuItem onClick={handleClose} disableRipple>
          {/* <EditIcon /> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${row.id}`} style={{color: "#000", textDecoration: "none"}}> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/2`} style={{color: "white", textDecoration: "none"}}>
          View Schedules
          </Link> */}
        {/* </MenuItem> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          {/* <FileCopyIcon /> */}
          {/* View Stats */}
        {/* </MenuItem> */} 
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          {/* <ArchiveIcon /> */}
          {/* View Standings */}
        {/* </MenuItem> */} 
        {/* <MenuItem onClick={handleClose} disableRipple> */}
          {/* <MoreHorizIcon /> */}
          {/* View Teams & Players */}
        {/* </MenuItem> */}
      {/* </StyledMenu> */}
    </div>
                            </TableCell>
                        }
                     
                         </>
                      );
                    })}
                  </TableRow>
                );
              }): <div className="no-data-found"> NO SCHEDULE AVAILABLE </div>
              :
              <div
              // className="text-center"
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection:"row",

              }}
            >
              <div style={{position:"absolute",left:"45%"}}>
                <img
                  src={basketballgif}
                  alt="gif"
                  style={{ width: "150px", height: "130px" }}
                />
                <h2 style={{color:"white",textAlign:"center"}}>Loading...</h2>
              </div>
            </div>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        // count={schedule.length}
        // count={updatedList.length}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
    </div>
    </>
  );
}

export default LeagueViewSchedule;
