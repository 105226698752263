import React from "react";
import ContactUs from "../../Components/ContactUs/ContactUs";
import headerSection from "../../assets/images/headerSection.jpg";
import Laptop from "../../assets/images/laptop.png";
import Mobile from "../../assets/images/mobile.png";

import { Col, Row } from "react-bootstrap";
import "./HowWork.scss";

const HowWorks = () => {
  return (
    <div>
      <img className="header-section" src={headerSection} alt="header-poster" />
      <section className="section-3">
        <div className="header-contain text-left">
          <div className="header">How It Work?</div>
          <div className="sub-header">How It Work?</div>
        </div>
        <Row className="pt-5">
          <Col md={4} className="text-center">
            <div className="number-text">1</div>
            <div className="white-border" />
            <p className="description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen.
            </p>
          </Col>
          <Col md={4} className="text-center">
            <div className="number-text">2</div>
            <div className="white-border" />
            <p className="description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen.
            </p>
          </Col>
          <Col md={4} className="text-center">
            <div className="number-text">3</div>
            <div className="white-border" />
            <p className="description">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen.
            </p>
          </Col>
        </Row>
      </section>
      <section className="section-3">
        <div className="header-contain text-left">
          <div className="header">How It Work?</div>
          <div className="sub-header">How It Work?</div>
        </div>
        <Row className="pt-5">
          <Col md={6} className="text-center">
            <img className="laptop-img" src={Laptop} alt="desktop" />
          </Col>
          <Col md={6} className="text-left d-flex align-items-center">
            <div>
              <div className="using-web-title">Using The Website</div>
              <p className="description">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="py-3">
          <Col md={6} className="text-left d-flex align-items-center">
            <div>
              <div className="using-web-title">Using The App</div>
              <p className="description">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </Col>
          <Col md={6} className="text-center">
            <img className="mobile-img" src={Mobile} alt="desktop" />
          </Col>
        </Row>
      </section>
      <ContactUs />
    </div>
  );
};

export default HowWorks;
