import React, { useEffect, useState } from "react";
import CardListTable from "../../Components/CardListTable/CardListTable";
import TextFieldComponent from "../../Components/TextFieldComponent/TextFieldComponent";
import LeagueStatus from "../../Components/DummyTable/DummyTable";
import { orderBy } from "lodash";
import { useSelector } from "react-redux";
import {getTeamListWithScore} from "../../api/index"
import { useParams } from "react-router";


function Overview(props) {
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const [items, setData] = useState();
  const [teamList, setTeamList] = useState([]);

  const { teamPlayer } = useSelector((state) => state.league);

  const handleSort = (column, sortDirection) => {
    // simulate server sort
    setLoading(true);

    // instead of setTimeout this is where you would handle your API call.
    setTimeout(() => {
      setData(orderBy(items, column.selector, sortDirection));
      setLoading(false);
    }, 100);
  };
  const LeagueOverview = [
    {
      name: "Team",
      selector: "team",
      // sortable: true,
      grow: "0.5",
      cell: (row) => {
        return (
          <>
            <h5>{row?.NAME}</h5>
          </>
        );
      },
    },
    {
      name: "W",
      selector: "WIN",
      //   sortable: true,
      grow: "0.5",
    },
    {
      name: "L",
      selector: "LOSE",
      //   sortable: true,
      grow: "0.5",
    },
  ];
  useEffect(()=> {
    getTeamListWithScore(id)
    .then((res) => {
      setTeamList(res.data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }, [])

  return (
    <>
      <div className="overview p-0">
        <div className="overview-title">
          <p>league leaders</p>
        </div>
        <div className="overview-table">
          <div className="d-flex justify-content-end px-3">
            <TextFieldComponent
              className="w-25"
              name="deviceName"
              id="deviceName"
              type="text"
              placeholder="Search Here"
              // value={initialValues.deviceName}
              // onBlur={handleFocus}
              // onChange={handleChangeValue}
              // error
              // helperText={isError.deviceName}
            />
          </div>
          <div className="mt-3 px-0">
            <CardListTable
              columns={LeagueOverview}
              //   data={LeagueStatus.LeagueStatus}
              data={teamList && teamList}
              onSort={handleSort}
              hover={false}

              // TheadComponent={(_) => null}
              // pending={loading}
              // pagination={false}
              // custompagination
              // paginationServer={false}
              // noDataString={"No data found"}
              // totalListCount={count}
              // paginationTotalRows={count}
              // paginationPerPage={limit}
              // onPageChangedCalled={handlePageChange}
              // inputClassName="mt-3"
              // sortFunction={customSort}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Overview;
