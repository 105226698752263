// import { Button } from 'bootstrap';
import React, { useState ,useEffect} from "react";
import PropTypes from "prop-types";
import { Badge, Button, Col, Row } from "react-bootstrap";
import "./League.scss";
import headerSection from "../../assets/images/headerSection.jpg";
// import TextFieldComponent from '../../Components/TextFieldComponent/TextFieldComponent';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import Overview from "./Overview";
import TeamDetails from "./TeamDetails";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useOnMount } from "../../utils/helpers";
// import { getLeagueTeamList} from "../../Store/actions/leagueAction";
import { getPlayerDetailAction} from "../../Store/actions/leagueAction";
import { getPlayerStatsAction} from "../../Store/actions/leagueAction";
import constants from "../../utils/constants";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import DummyImage from '../../assets/images/dummy-image.jpg'
import { Link } from "react-router-dom";
import avtarimg from '../../assets/images/avatar.png'
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import basketballgif from '../../assets/images/basketballLoader.gif'
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';



// function createData(time, home, visitor, status, score) {
//   return { time, home, visitor, status, score };
// }

// const rows = [createData("2:12 pm", "USA", "CANADA", 24, "0 - 0")];

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

const columns = [
  { id: 'match_id', label: 'Match Id', align: 'left'}, 
  { id: 'team_name', label: 'Team Name', align: 'center', 
  // minWidth: 250
},
  { id: 'town', label: 'Organization', align: 'center', 
  // minWidth: 250
},
  { id: 'level', label: 'Level', align: 'center', 
  // minWidth: 250
},
  {
    id: 'G',
    label: 'GP',  
    align: 'center',
    // format: (value) => value.toLocaleString('en-US'),
    //  minWidth: 250
  },
  {
    id: 'PPG',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'PPG',
    align: 'center',
    //  minWidth: 250,
    // format: (value)=>value,
    
  },
  {
    id: 'RPG',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'RPG',
    align: 'center', 
    // minWidth: 250,
    format: "123",
    
  },
  {
    id: 'OFF',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'OFF',
    align: 'center', 
    // minWidth: 250
    // format: "123",
    
  },
  {
    id: 'DFE',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'DEF',
    align: 'center', 
    // minWidth: 250
    // format: "123",
    
  },
  {
    id: 'APG',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'APG',
    align: 'center', 
    // minWidth: 250
    // format: "123",
    
  },
  {
    id: 'FT',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'FT%',
    align: 'center',
    //  minWidth: 250
    // format: "123",
    
  },
  {
    id: 'FG',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'FG%',
    align: 'center', 
    // minWidth: 250
    // format: "123",
    
  },
  {
    // id: '3P',
    id: 'P_3',
    // label: 'Size\u00a0(km\u00b2)',
    label: '3P%',
    align: 'center', 
    // minWidth: 250
    // format: "123",
    
  },
  {
    id: 'P_2',
    // label: 'Size\u00a0(km\u00b2)',
    label: '2P%',
    align: 'center', 
    // minWidth: 250
    // format: "123",
    
  },
  // {
  //   id: 'PA',
  //   // label: 'Size\u00a0(km\u00b2)',
  //   label: 'PA',
  //   align: 'center', minWidth: 250,
  //   format: "123",
    
  // },
  // {
  //   id: 'win',
  //   // label: 'Size\u00a0(km\u00b2)',
  //   label: 'Win',
  //   align: 'center', minWidth: 250,
  //   format: "123",
    
  // },
  
  // {
  //   id: 'density',
  //   label: 'Density',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];

function createData(id, name, town, gender, level) {
  return {id, name, town, gender, level };
}

const rows = [createData("2:12 pm", "USA", "CANADA", 24, "0 - 0")];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: 'black',
    color:'white',
    // color:
    //   theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

// function createData(pool_name, team_name, GP, record, PF,PA,win) {
//   return { pool_name, team_name, GP, record, PF,PA,win};
// }

// const rows = [createData("abc", "blues", "1", "W(1)-L(0)","12","9", "100%")];

function StatsPlayerDetails(props) {
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const [teamlist, setTeamList] = useState([]);
  const [teamlists, setTeamLists] = useState([]);
  const dispatch = useDispatch();
  const { playerDetail } = useSelector((state) => state.league);
  const { playerStats } = useSelector((state) => state.league);
  const { isLoading } = useSelector((state) => state.loading);
  const { id } = useParams();
  useOnMount(() => {
    dispatch(getPlayerDetailAction(id));
    dispatch(getPlayerStatsAction(id));
    // dispatch(getPlayerStatsAction(11060));
  });

  useEffect(() => {
    setTeamList(playerDetail);
    setTeamLists(playerStats);
  }, [playerDetail,playerStats]);
  // console.log("pldd",playerStats);
  // console.log("ptt",teamlists);

//   let finaldata=teamlists.map(item=>({
//     ...item.score,
//     match_id:item.match_id,
//     teamname:item.team.team_detail.name,
//     level:item.team.team_detail.level
//  }))
//  console.log("fdfd",finaldata);
//  setTeamLists(finaldata)

//  console.log("trtr",teamlists);

  // console.log("teammmmqqq",leagueTeamList);
  // const handleTeam = (id) => {
  //   console.log("team_id",id);
  //   setTeamId(id)
  // }

  // let finaltem=leagueTeamList.map((item)=>{
   
  //   return item.team_detail
  // })
  
//   let finaltem = leagueTeamList.map((item) => 
//   Object.assign({}, item.team_detail, {teamid:item.id})
//   )
  // console.log("fffff",newData);

//   const newUsers = finaltem.map((user) => ({
//     ...user,
//   //   logo:   <img
//   //   src={`${process.env.REACT_APP_API_ENDPOINT}${user.logo}`}
//   //   height={75}
//   //   width={75}
//   // />

//   logo:!user.logo ? (
//     <img
//       src={DummyImage}
//       height={75}
//       width={75}
//       // onClick={() => handleImage(DummyImage)}
//     />
//   ) : (
//     <img
//       src={`${process.env.REACT_APP_API_ENDPOINT}${user.logo}`}
//       height={75}
//       width={75}
    
//     />
//   )
//   }));

  // console.log("aaaffff",finaltem);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  

//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


  return (
    <>
        <div className='league-head'>Player Details & Stats</div>

        <Card sx={{ minWidth: 275 }} style={{backgroundColor:"#1f1f21",borderRadius:0,display:"flex",alignItems:"center",justifyContent:"center"}}>
      <CardContent>
        <div className="float-container" style={{width:"100%",display:"contents"}}>
          {/* <div  className="float-child" style={{width:"50%",float:"left",padding:"10px"}}> */}
          <div  className="float-child" style={{float:"left",padding:"10px"}}>
        <Typography>
        {/* <img
            src={`${process.env.REACT_APP_API_ENDPOINT}${teamlist.avtar}`}
            height={100}
            width={100}
          
          /> */}
             { teamlist.avtar!=null && teamlist.avtar!="" ?
          <img
            src={`${process.env.REACT_APP_API_ENDPOINT}${teamlist.avtar}`}
            height={100}
            width={100}
          
          />
          :
          <img
          src={`${avtarimg}`}
          height={100}
          width={100}
        
        />}
        </Typography>
        </div>
        {/* <div className="float-child" style={{width:"50%",float:"left",color:"white"}} > */}
        <div className="float-child" style={{float:"left",color:"white",padding:"10px"}} >
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <div><span style={{color:"orange"}}>Name:</span> <span> {teamlist.first_name}{" "}{teamlist.last_name}</span></div>
          <div><span style={{color:"orange"}}>Jersey Id: </span><span>{teamlist.jersey_id}</span></div>
          <div><span style={{color:"orange"}}>Player Id:</span><span> {teamlist.id}</span></div>
          <div><span style={{color:"orange"}}>Email:</span><span> {teamlist.email}</span></div>
        </Typography>
        </div>
        </div>
        {/* <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>


    <div style={{backgroundColor: '#1F1F21'}}>
    <div className='container' style={{paddingBottom: 10, paddingTop: 30}}>
    <Paper className='league_table_body' sx={{ width: '100%', overflow: 'hidden'}}>
      <TableContainer >
   
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {/* {console.log("legust",stats)} */}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
             {/* {[stats][0].playerList?.length ? [stats][0].playerList */}
            {/* {!isLoading ? */}
            {/* // teamlist?.length?teamlist */}
           { !isLoading?
           teamlists?.length ? teamlists
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row,i) => {
                // console.log("rrr",row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      // console.log("vvv",value);
                      return (
                        <>
                        {column.id !== "dropdown" ? column.id!=="first_name" && column.id!=="last_name" ?
                        <TableCell key={column.id} align={column.align}>
                          {/* {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}  */}
                            {value}
                        </TableCell> : <TableCell key={column.id} align={column.align}>
                  <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_STATS_PLAYER_DETAILS}/${row.player_id}`} style={{color: "orange"}}>
                 {value}
                 </Link> 
                        </TableCell>
                        : 
                          <TableCell className="tableData">
                             <div>
      <Button
        id="demo-customized-button"
        // aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        // aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        // onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      {/* <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="menubar"
      >
        <MenuItem onClick={handleClose} disableRipple>
          {/* <EditIcon /> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${row.id}`} style={{color: "#000", textDecoration: "none"}}> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/2`} style={{color: "white", textDecoration: "none"}}>
          View Schedules
          </Link>
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          {/* <FileCopyIcon /> */}
          {/* View Stats */}
        {/* </MenuItem> */} 
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          {/* <ArchiveIcon /> */}
          {/* View Standings */}
        {/* </MenuItem>  */}
        {/* <MenuItem onClick={handleClose} disableRipple> */}
          {/* <MoreHorizIcon /> */}
          {/* View Teams & Players */}
        {/* </MenuItem> */}
      {/* </StyledMenu>  */}
    </div>
                            </TableCell>
                        }
                     
                         </>
                      );
                    })}
                  </TableRow>
                );
              }): <div className="no-data-avl" style={{marginTop: "30px",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "1px",
                color: "white",
                fontSize: "14px",
                height:"40px",
                // fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                //   "Helvetica Neue, Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
                //   "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important",
                  textAlign: "center",
                fontWeight: "500",
                letterSpacing: "1px",
                lineHeight: "16px",
                textTransform: "uppercase",
                overflowWrap: "anywhere",
                width: "100%",
                position: "absolute",
                /* top: 50%; */
                left: "50%",
                transform: "translate(-50%, -50%)"}}> NO STATISTICS AVAILABLE </div>
              :
              <div
              // className="text-center"
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection:"row",

              }}
            >
              <div style={{position:"absolute",left:"45%"}}>
                <img
                  src={basketballgif}
                  alt="gif"
                  style={{ width: "150px", height: "130px" }}
                />
                <h2 style={{color:"white",textAlign:"center"}}>Loading...</h2>
              </div>
            </div>
            //   :
            //   // <h2>Loading....</h2>
            //   <div
            //   // className="text-center"
            //   style={{
            //     height: "500px",
            //     display: "flex",
            //     alignItems: "center",
            //     justifyContent: "center",
            //     flexDirection:"row",

            //   }}
            // >
            //   <div style={{position:"absolute",left:"45%"}}>
            //     <img
            //       src={basketballgif}
            //       alt="gif"
            //       style={{ width: "150px", height: "130px" }}
            //     />
            //     <h2 style={{color:"white",textAlign:"center"}}>Loading...</h2>
            //   </div>
            // </div>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        // count={schedule.length}
        count={teamlists.length}
        // count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
    </div>
    </>

    

)
}

export default StatsPlayerDetails;
