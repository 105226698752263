import React from "react";
import { Container, Form, Nav, Navbar, NavDropdown } from "react-bootstrap";
// import { getAccountData } from "../../utils/storage/index";

import { NavLink, useHistory, useLocation } from "react-router-dom";
import constants from "../../utils/constants";
import googleIcon from "../../assets/images/google.svg";
import facebookIcon from "../../assets/images/facebook.svg";
import twitterIcon from "../../assets/images/twitter.svg";
import linkedIcon from "../../assets/images/linkedin.svg";
import Logo from "../../assets/images/logo.png";
import { header } from "../../utils/routes";
import "./Header.scss";
const Header = () => {
  const history = useHistory();
  const location = useLocation();
  // console.log("paramString", location)
  const handleLogOut = () => {
    localStorage.clear();
    history.push(constants.ROUTE.LOGIN.LOGIN);
  };

  return (
    <>
      <div className="header-Fixed">
        <Navbar bg="dark" variant="dark" className="sub-header">
          <Form inline className="ml-auto">
            <div className="mail">Email</div>
            {/* <div className="mail-info">: info@hooplivestats.com</div> */}
            <div className="mail-info">: ratana.sak@hooplivestats.com</div>
            <div className="divider" />
            <img className="social-icon" src={googleIcon} alt="icon" />
            <img className="social-icon" src={facebookIcon} alt="icon" />
            <img className="social-icon" src={twitterIcon} alt="icon" />
            <img className="social-icon" src={linkedIcon} alt="icon" />
          </Form>
        </Navbar>
        {/* <Navbar collapseOnSelect expand="lg" className="header-option">
          <Navbar.Brand href="/">
            <img src={Logo} className="company-logo" alt="logo" />
          </Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {header.map((item, index) => {
                return item.header ? (
                  <NavLink to={item.path} className={item.cName} key={index}>
                    {item.title}
                  </NavLink>
                ) : null;
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar> */}
        <Navbar
          collapseOnSelect
          expand="lg"
          bg=""
          variant=""
          className="header-option justify-content-between"
        >
          <Navbar.Brand href="#home">
            <img src={Logo} className="company-logo" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* <Nav className="ml-auto"> */}
            <Nav >
              {header.map((item, index) => {
                // console.log("item.path", item.path,location.pathname );
                return item.header ? (
                  <NavLink to={item.path} className={ item.path === location.pathname ? "nav-active": ""} key={index}>
                    {item.title}
                  </NavLink>
                ) : null;
              })}
              <a href="/admin/login" target="_blank" className="">
                Login
              </a>
            </Nav>
          </Navbar.Collapse>
          {/* </Container> */}
        </Navbar>
      </div>
    </>
  );
};

export default Header;
