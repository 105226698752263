import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import headerSection from "../../assets/images/headerSection.jpg";
import Logo from "../../assets/images/logo.png";
import { getAboutUsAction } from "../../Store/actions/HomeAction";
import { useOnMount } from "../../utils/helpers";
import './aboutus.scss';

const AboutUs = () => {
  const dispatch = useDispatch();
  const { about_us, about_us_photo } = useSelector((state) => state.home.about);
  useOnMount(() => {
    dispatch(getAboutUsAction());
  });
  return (
    <>
      <section className="section-3">
        <Row>
          {/* <Col className="text-center" xs={12}>
            <img className="w-50 py-5" src={Logo} alt="eng" />
          </Col> */}
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
          <h2 className="title-head">About Us</h2>
          </div>
          {about_us_photo && 
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
          <img
            src={`${process.env.REACT_APP_API_ENDPOINT}${about_us_photo}`}
            height={200}
            width={200}
          
          />
          </div>
          }
          <Col className="text-center p-4" xs={12}>
          <div dangerouslySetInnerHTML={{__html: about_us}} />
            {/* {about_us} */}
          </Col>
        </Row>
      </section>
    </>
  );
};

export default AboutUs;