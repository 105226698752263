import { combineReducers } from 'redux';

import authReducer from './authReducer';
import errorReducer from './errorReducer';
import loadingReducer from './loadingReducer';
import modalReducer from './modalReducer';
import alertReducer from './alertReducer';
import homeReducer from './homeReducer';
import leagueReducer from './leagueReducer';
import highLightReducer from './highLightReducer';
import teamReducer from './teamReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    error: errorReducer,
    loading: loadingReducer,
    modal: modalReducer,
    alert: alertReducer,
    home: homeReducer,
    league: leagueReducer,
    highlight: highLightReducer,
    team:teamReducer
});

export default rootReducer;
