const API_URL = process.env.REACT_APP_API_URL;
export default {
  IMG_URL: process.env.REACT_APP_API_ENDPOINT,
  ROUTE: {
    HEADER: {
      HOME: '/',
      LEAGUE: '/league',
      NEW_ARTICLES: '/new-articles',
      ABOUT_US: '/about-us',
      HIGHLIGHT: '/highlights',
      HOW_WORK: '/how-it-work',
      FAQ: '/faq',
      STORY: '/story',
      PRICING:'/pricing',
      WATCH: '/highlights',
      PLAYER: '/players-list',
      CONTACT: '/contact',
      PRIVACY: '/privacy',
      TERMS: '/terms-of-use',
      PROFILE: '/profile',
      // LOGIN: '/admin/login',
    },
    LEAGUE: {
      LEAGUE_SCHEDULE: '/league-schedule',
      LEAGUE_SCHEDULE_ID: '/league-schedule/:id',
      LEAGUE_STATUS: '/league-status',
      LEAGUE_STATUS_ID: '/league-status/:id',
      LEAGUE_STANDING: '/league-standing',
      LEAGUE_STANDING_ID: '/league-standing/:id',
      LEAGUE_TEAM_PLAYER: '/league-team-player',
      LEAGUE_TEAM_PLAYER_ID: '/league-team-player/:id',
      LEAGUE_STATS_PLAYER_DETAILS: '/league-sats-player-details',
      LEAGUE_STATS_PLAYER_DETAILS_ID: '/league-sats-player-details/:id',
    },
    PLAYER: {
      PLAYER_LIST: '/profile/players-list',
      PLAYER_DETAILS: '/players-list/player-details',
      PLAYER_DETAILS_ID: '/players-list/player-details/:id',
      COACH_LIST: '/profile/coaches',
    },
    FEATURE: {
      FEATURE_LIST: '/feature-list',
    },
    BLOG: {
      BLOG_DETAILS: '/blog-details/:id',
    },
    STORY: {
      STORY_DETAILS: '/story-details/:id',
      WEB_DETAILS: '/web-details/:id',
    },
    STATISTICS: {
      LIST: "/scoresheet/statistics/",
      LIST_ID: "/scoresheet/statstics/:id",
      TEAM_ID: "/scoresheet/team-statistics/",
    },
    TEAM:{
      STANDINGS:"/team/standings/",
      PLAYERSTATS:"/team-list/view-stats",
      PLAYERSTATS_ID:"/team-list/view-stats/:id",
      HOMESTATS:"/schedule/home-stats",
      HOMESTATS_ID:"/schedule/home-stats/:id",
      VISITORSTATS:"/schedule/visitor-stats",
      VISITORSTATS_ID:"/schedule/visitor-stats/:id",

    }
  },
};
