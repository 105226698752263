import { produce } from "immer";
import { CLOSE_VIDEO_PLAYER, SET_HIGHLIGHT_LIST, SET_VIDEO_PLAYER_DATA } from "../actions/actionTypes";

const initialState = {
  list: [],
  videoDetails: {},
  isOpen: false
};

const highLightReducer = (draft, action) => {
  switch (action.type) {
    case SET_HIGHLIGHT_LIST: {
      draft.list = action.payload;
      break;
    }
    case SET_VIDEO_PLAYER_DATA: {
      draft.videoDetails = action.payload;
      draft.isOpen = true;
      break;
    }
    case CLOSE_VIDEO_PLAYER: {
      draft.videoDetails = {};
      draft.isOpen = false;
      break;
    }
    default:
      break;
  }
};

export default produce(highLightReducer, initialState);
