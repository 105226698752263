import { GET_FEATURES, SET_FEATURE, GET_NEWS_ARTICLES, SET_NEWS_ARTICLES, SEND_CONTACT_DETAILS, GET_FAQ, SET_FAQ,
    SEND_QUERY, 
    GET_PRIVACY_POLICY,
    SET_PRIVACY_POLICY,
    SEND_CONTACT,
    GET_PLAYER_LIST, SET_PLAYER_LIST, GET_STORY_ARTICLES, SET_STORY_ARTICLES, GET_TERM_AND_CONDITION, SET_TERM_AND_CONDITION, GET_ABOUT_US, SET_ABOUT_US, GET_PLAYER_DETAILS, SET_PLAYER_DETAILS, SET_BLOG_DETAILS, GET_COACH_LIST, SET_COACH_LIST, SET_STORY_ARTICLES_DETAILS, SEND_EMAIL_SUBSCRIPTION, GET_SECTION_ONE, SET_SECTION_ONE, GET_SECTION_TWO, SET_SECTION_TWO, GET_SECTION_FAQ, SET_SECTION_FAQ,GET_SECTION_TWO_ID,SET_SECTION_TWO_ID,GET_PRICING_ARTICLES,SET_PRICING_ARTICLES} from './actionTypes';

export const getFeature = () => ({ type: GET_FEATURES });
export const setFeature = (payload) => ({ type: SET_FEATURE, payload });
export const getNewsArticles = () => ({ type: GET_NEWS_ARTICLES });
export const setNewsArticles = (payload) => ({ type: SET_NEWS_ARTICLES, payload });
export const getStoryArticles = () => ({ type: GET_STORY_ARTICLES });
export const setStoryArticles = (payload) => ({ type: SET_STORY_ARTICLES, payload });
export const sendContactDetails = (payload) => ({ type: SEND_CONTACT_DETAILS, payload });
export const sendContact = (payload) => ({ type: SEND_CONTACT, payload });
export const getFAQAction = () => ({ type: GET_FAQ });
export const setFAQ = (payload) => ({ type: SET_FAQ, payload });
export const sendQueryAction = (payload) => ({ type: SEND_QUERY, payload });
export const getPrivacyPolicy = () => ({ type: GET_PRIVACY_POLICY });
export const setPrivacyPolicy = (payload) => ({ type: SET_PRIVACY_POLICY, payload });
export const getTermAndCondition = () => ({ type: GET_TERM_AND_CONDITION });
export const setTermAndCondition = (payload) => ({ type: SET_TERM_AND_CONDITION, payload });
export const getPlayerListAction = () => ({ type: GET_PLAYER_LIST })
export const setPlayerListAction = (payload) => ({ type: SET_PLAYER_LIST, payload })
export const getAboutUsAction = () =>  ({ type: GET_ABOUT_US });
export const setAboutUsAction = (payload) =>  ({ type: SET_ABOUT_US, payload });
// export const getPlayerDetailsAction = (payload) => ({ type: GET_PLAYER_DETAILS, payload })
// export const setPlayerDetailsAction = (payload) => ({ type: SET_PLAYER_DETAILS, payload })
export const setBlogsDetails = (payload) => ({ type: SET_BLOG_DETAILS, payload });
export const setStoryDetails = (payload) => ({ type: SET_STORY_ARTICLES_DETAILS})
export const getCoachListAction = () => ({type : GET_COACH_LIST});
export const setCoachListAction = (payload) => ({type : SET_COACH_LIST, payload});

export const getPricingArticles = () => ({ type: GET_PRICING_ARTICLES });
export const setPricingArticles = (payload) => ({ type: SET_PRICING_ARTICLES, payload });

export const sendEmailSubscription = (payload) => ({ type: SEND_EMAIL_SUBSCRIPTION, payload });

//get section 1,2,faq

export const getSectionOneAction = (payload) => ({ type: GET_SECTION_ONE, payload });
export const setSectionOneAction = (payload) => ({ type: SET_SECTION_ONE, payload });

export const getSectionTwoAction = (payload) => ({ type: GET_SECTION_TWO, payload });
export const setSectionTwoAction = (payload) => ({ type: SET_SECTION_TWO, payload });

export const getSectionTwoIdAction = (payload) => ({ type: GET_SECTION_TWO_ID, payload });
export const setSectionTwoIdAction = (payload) => ({ type: SET_SECTION_TWO_ID, payload });


export const getSectionFaqAction = (payload) => ({ type: GET_SECTION_FAQ, payload });
export const setSectionFaqAction = (payload) => ({ type: SET_SECTION_FAQ, payload });



