import React, { useState, useEffect } from "react";
import { Player as VideoPlayer, BigPlayButton } from "video-react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  DialogContent,
  Grid,
  CircularProgress,
  DialogActions,
  DialogTitle,
  IconButton,
  Dialog,
} from "@material-ui/core";
// import { Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import constants from "../../utils/constants";
import videoDemo from "../../assets/images/pexels-pavel-danilyuk-7812515.mp4";
import { closeVideoPlayerAction } from "../../Store/actions/leagueAction";
// import {
//   resetFlikVideoDetails, deleteVideoFlikAction, downloadFlikVideoAction, cloneFlikAction
// } from '../../store/actions/fliks';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: "600",
    letterSpacing: "0em",
  },
  videoSize: {
    height: "26.125rem !important",
    width: "46.438rem !important",
  },
  topBar: {
    textAlign: "right",
    padding: "0px 0px 10px",
  },
  videoGrid: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    height: "26.125rem !important",
    width: "46.438rem !important",
  },
  loading: {
    margin: 3,
    position: "absolute",
    left: "47%",
  },
  title: {
    fontSize: "1.375rem",
    lineHeight: "1.375rem",
    textAlign: "left",
  },
  download: {
    textAlign: "right",
  },
  flikDetail: {
    fontSize: "0.75rem",
    lineHeight: "1.25rem",
    textAlign: "left",
    color: "gray",
    paddingTop: "1rem",
  },
  cloneButton: {
    color: `red !important`,
  },
  deleteButton: {
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#FE8181",
    textTransform: "none",
    fontWeight: "600",
  },
  icon: {
    width: 14,
    height: 14,
    filter:
      "invert(33%) sepia(86%) saturate(6553%) hue-rotate(251deg) brightness(96%) contrast(107%);",
  },
  downloadButton: {
    borderRadius: 2,
    height: "2rem",
    color: "red",
    border: `1px solid red`,
    padding: "0px 20px",
  },
  closeButtonWrapper: {
    display: "inline-block",
  },
  closeButtonIcon: {
    position: "absolute",
    right: 2,
    top: 2,
  },
  closeButton: {
    textTransform: "none",
    borderRadius: 2,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    fontWeight: 600,
    marginRight: 20,
  },
  footer: {
    // justifyContent: "space-between",
    justifyContent: "flex-end",
    padding: "10px 15px",
  },
}));

const VideoPlayers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error);
  const { id, video, home_team, visitor_team, createdAt, title, date } =
    useSelector((state) => state.highlight.videoDetails);
  const [open, setOpen] = useState(true);
  const [playerObj, setPlayerObj] = useState();
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const { eventId } = useParams();
  const onPlayerReady = (player) => {
    if (player.readyState >= 3 && player.hasStarted) {
      setOpen(!open);
    }
  };
  useEffect(() => {
    if (playerObj) {
      playerObj.subscribeToStateChange(onPlayerReady);
    }
    console.log("Video", video);
  }, [playerObj]);
  const playerData = (
    <>
      <div className={classes.videoGrid}>
        {video && (
          <VideoPlayer
            ref={(player) => {
              setPlayerObj(player);
            }}
            className={classes.videoSize}
            fluid={false}
            autoPlay
          >
            <source src={constants.IMG_URL + video} />
            {/* <source src={videoDemo} /> */}

            <BigPlayButton disabled />
          </VideoPlayer>
        )}
      </div>
      <Grid container direction="row">
        <Grid xs={6} className={classes.title}>
          {title}
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.flikDetail}>
        <Grid xs={4}>
          <div>Teams</div>
          <div>
            {home_team} VS {visitor_team}
          </div>
        </Grid>
        <Grid xs={4}>
          <div>Create date</div>
          <div>{date}</div>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <DialogTitle>
        <span className={classes.title}>Video Detail</span>
        <div className={classes.closeButtonWrapper}>
          <IconButton
            aria-label="close"
            className={classes.closeButtonIcon}
            onClick={() => dispatch(closeVideoPlayerAction())}
          >
            x{/* <Close /> */}
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.root}>
        {playerData}
      </DialogContent>
      <DialogActions className={classes.footer}>
        {/* <div className={classes.topBar}>
          <Button
            className={classes.deleteButton}
            onClick={() => setshowDeleteModal(true)}
          >
            Delete
          </Button>
        </div> */}
        <Button
          className={classes.closeButton}
          onClick={() => dispatch(closeVideoPlayerAction())}
          color="primary"
          type="submit"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default VideoPlayers;
