import { Collapse, Paper, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import headerSection from "../../assets/images/headerSection.jpg";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import CustomeCollapse from "../../Components/CustomeCollapse/CustomeCollapse";
import TextFieldComponent from "../../Components/TextFieldComponent/TextFieldComponent";
import { setAlert } from "../../Store/actions/alert";
import { getFAQAction, sendQueryAction } from "../../Store/actions/HomeAction";
import { useOnMount } from "../../utils/helpers";
import "./FAQ.scss";
const FAQ = () => {
  const dispatch = useDispatch();
  const [isError, setIsError] = useState({});
  const [isFocus, setIsFocus] = useState({});
  const [queryState, setQueryState] = useState({
    name: "",
    email: "",
    quary: ""
  });
  // const [isError, setIsError] = useState({
  //   name: "",
  //   email: "",
  //   quary: ""
  // });

  const validateEmail = (email) => {
    const emailRegex = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (email && emailRegex.test(email.trim())) {
      return true;
    }
    return false;
  }

  const handleChangeInput = (e) => {
    setQueryState({
      ...queryState,
      [e.target.name]: e.target.value,
    });
    setIsError({
      ...isError,
      [e.target.name]: "",
    });
  };
  const onSubmitForm = () => {
    const validation = validateform(queryState);
    if (Object.keys(validation).length > 0) {
      // CustomeNotification("error", "Please Fill All Data", "Error", 2000);
      setIsError(validation);
      return;
  } else {
      dispatch(sendQueryAction(queryState))
    }
  };

  const validateform = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    }
    if (values.name.trim() == "") {
      errors.name = "Name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(values.email)) {
      errors.email = "Please enter a valid email";
  }
    if (values.email.trim() == "") {
      errors.email = "Email is required";
    }
    if (!values.quary) {
      errors.quary = "Quary is required";
    }
    if (values.quary.trim() == "") {
      errors.quary = "Quary is required";
    }
    return errors;
  };

  const handleFocus = (e) => {
    const validation = validateform(queryState);
    setIsError(validation);
    setIsFocus({ ...isFocus, [e.target.name]: true });
  }

  // const isValidation = (formData) => {
  //   let isFindError = true;
  //   Object.keys(formData).forEach((key, index) => {
  //     if (!formData[key]) {
  //       setIsError({
  //         ...isError,
  //         [key]: "field is required",
  //       });
  //       isFindError = false;
  //     } else {
  //       setIsError({
  //         ...isError,
  //         [key]: "",
  //       });
  //     }
  //   });
  //   return isFindError;
  // };
  const { faq } = useSelector((state) => state.home);
  useOnMount(() => {
    dispatch(getFAQAction());
  });
  return (
    <div>
      <img className="header-section" src={headerSection} alt="header-poster" />
      <section className="section-3">
        <div className="header-contain text-center">
          <div className="header">frequently asked questions</div>
          <div className="sub-header">frequently asked questions</div>
        </div>
        <Collapse in={false}>
          <Paper elevation={4}>
            <svg>
              <polygon points="0,100 50,00, 100,100" />
            </svg>
          </Paper>
        </Collapse>
        <Row className="py-5">
          <Col className="faq-container mr-2 ml-4 px-0" sm={7}>
            {faq.map((item, index) => (
              <CustomeCollapse
                isOpen={index === 0}
                title={item.quary}
                desc={item.answer}
              />
            ))}
          </Col>
          <Col className="faq-container" sm={4}>
            <Row>
              <Col sm={12}>
                <p className="looking-text">
                  Can't find what you are looking for?
                </p>
              </Col>
              <Col sm={12}>
                <h4 className="query-text"><b>Send A Query</b></h4>
              </Col>
              <Col sm={12}>
                <TextFieldComponent
                  inputClassName="query-input text-white"
                  name="name"
                  id="name"
                  onChange={handleChangeInput}
                  style={{color: '#fff',}}
                  type="text"
                  placeholder="Full Name"
                  onBlur={handleFocus}
            error={isError.name && isFocus.name ? true : false}
            helperText={isError.name}
                />
              </Col>
              <Col sm={12}>
                <TextFieldComponent
                  inputClassName="query-input text-white"
                  name="email"
                  style={{color: '#fff',}}
                  id="email"
                  onChange={handleChangeInput}
                  type="text"
                  placeholder="Email Address"
                  onBlur={handleFocus}
            error={isError.email && isFocus.email ? true : false}
            helperText={isError.email}
                />
              </Col>
              <Col sm={12}>
                <TextFieldComponent
                  inputClassName="query-input text-white"
                  name="quary"
                  id="quary"
                  style={{color: '#fff',}}
                  onChange={handleChangeInput}
                  type="text"
                  placeholder="Enter Query"
                  onBlur={handleFocus}
            error={isError.quary && isFocus.quary ? true : false}
            helperText={isError.quary}
                />
              </Col>
              <Col sm={12} className="text-center my-3">
                <ButtonComponent onClick={onSubmitForm}>
                  Send Message
                </ButtonComponent>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default FAQ;
