// import { Button } from 'bootstrap';
import React, { useState ,useEffect} from "react";
import PropTypes from "prop-types";
import { Badge, Button, Col, Row } from "react-bootstrap";
import "./hometeam.scss";
import headerSection from "../../assets/images/headerSection.jpg";
// import TextFieldComponent from '../../Components/TextFieldComponent/TextFieldComponent';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
// import Overview from "./Overview";
// import TeamDetails from "./TeamDetails";
import { useDispatch, useSelector } from "react-redux";
import { useParams,useHistory } from "react-router-dom";
import { useOnMount } from "../../utils/helpers";
// import { getLeagueStatsAction } from "../../Store/actions/leagueAction";
import { getTeamPlayerStatsAction } from "../../Store/actions/TeamAction";
import { getScheduleStatsAction } from "../../Store/actions/leagueAction";
import TablePagination from '@mui/material/TablePagination';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
// import { setLoading } from "../../Store/actions/loading";
import basketballgif from '../../assets/images/basketballLoader.gif'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }




const columns = [
  { id: 'name', label: 'Name', align: 'left'}, 
  { id: 'pos', label: 'Pos', align: 'center'},
  {
    id: 'min',
    label: 'Min',  
    align: 'center',
    // format: (value) => value.toLocaleString('en-US'),
     
  },
  {
    id: 'pts',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Pts',
    align: 'center', 
    // format: "123",
    
  },
  {
    id: 'reb',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Reb',
    align: 'center',
    // format: "123",
    
  },
  { id: 'ast', label: 'Ast', align: 'left',}, 
  // { id: 'G', label: 'GP', align: 'center',},
  {
    id: 'stl',
    label: 'Stl',  
    align: 'center',
    // format: (value) => value.toLocaleString('en-US'),
     
  },
  {
    id: 'blk',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Blk',
    align: 'center', 
    // format: "123",
    
  },
  {
    id: 'fgm',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'Fgm',
    align: 'center',
    // format: "123",
    
  },
  { id: 'fga', label: 'Fga', align: 'left',}, 
  // { id: 'home', label: 'Logo', align: 'center', minWidth: 250},
  {
    id: 'fg%',
    label: 'Fg%',  
    align: 'center',
    // format: (value) => value.toLocaleString('en-US'),
    
  },
  {
    id: '3pm',
    // label: 'Size\u00a0(km\u00b2)',
    label: '3pm',
    align: 'center',
    // format: "123",
    
  },
  {
    id: '3pa',
    // label: 'Size\u00a0(km\u00b2)',
    label: '3pa',
    align: 'center',
    // format: "123",
    
  },
  { id: '3p%', label: '3P%', align: 'left',}, 
  { id: 'ftm', label: 'Ftm', align: 'center', },
  { id: 'fta', label: 'Fta', align: 'center', },
  { id: 'ft%', label: 'Ft%', align: 'center', },
  { id: 'off', label: 'Off', align: 'center', },
  { id: 'def', label: 'Def', align: 'center', },
  { id: 'to', label: 'To', align: 'center', },
  { id: 'pf', label: 'Pf', align: 'center', },

  
  // {
  //   id: 'schedule',
  //   label: 'View Schedule',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: 'black',
    color:'white',
    // color:
    //   theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


function createData(name,pos,stl,blk,fgm) {
  return { name,pos,stl,blk,fgm};
}

const rows = [createData("2:12 pm", "USA", "CANADA", 24, "0 - 0")];



function HomeTeamStats(props) {
  // const [value, setValue] = React.useState(0);
  const history = useHistory();

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  // const [teamId, setTeamId] = useState();
  // const dispatch = useDispatch();
  // const { teamList } = useSelector((state) => state.league);
  // const { id } = useParams();
  // useOnMount(() => {
  //   dispatch(getLeagueViewStatusAction(id));
  // });

  // const handleTeam = (id) => {
  //   console.log("team_id",id);
  //   setTeamId(id)

  const [teamlist, setTeamList] = useState([]);
  const [teamlists, setTeamLists] = useState([]);
  const [breakdown, setBreakdown] = useState();
  const [homeTeamPlayer, sethomeTeamPlayer] = useState([]);
  const [homeTeamInfo, sethomeTeamInfo] = useState({});
  const [playerQueterData, setPlayerQueterData] = useState([]);
  const [value, setValue] = React.useState(0);

  // const [loading, setLoading] = useState(true);
  // console.log("load",loading);
  const dispatch = useDispatch();
  const {isLoading} = useSelector((state)=>state.loading)
  // const { stats } = useSelector((state) => state.team);
  const { scheduleStats } = useSelector((state) => state.league);
  const { id } = useParams();
  useOnMount(() => {
    // dispatch(getTeamPlayerStatsAction(id));
    dispatch(getScheduleStatsAction(id));
    // setLoading(true)
  });
  // console.log("settt1",isLoading);

  useEffect(() => {
    setTeamLists(scheduleStats);
    if(scheduleStats.homeTeamPlayer && scheduleStats.homeTeamPlayer.length){
    // if(scheduleStats.homeTeamPlayer && scheduleStats.homeTeamPlayer.length){
    // setLoading(false)
    // console.log("ttt",teamlist,teamlist.length);
    // if(teamlist.length){
    //   setLoading(false)
    //   scoredata(teamlist);
    // }else{
    //   setLoading(true)
    // }
    
    setBreakdown(scheduleStats.breakdown)
    sethomeTeamPlayer(scheduleStats.homeTeamPlayer.length?scheduleStats.homeTeamPlayer.filter((item)=>item.player.first_name!==null):scheduleStats.homeTeamPlayer)
    sethomeTeamInfo(scheduleStats.homeTeamInfo[0])
    }
  }, [scheduleStats]);

  useEffect(()=>{
    if(homeTeamPlayer && homeTeamPlayer.length > 0){
    const getData = getRecordQuaterWise(value)
    // console.log("getDatagetData",getData);
    setPlayerQueterData(getData)
    }
  },[homeTeamPlayer,value])
  // console.log("myplayerstats",teamlists.breakdown);
  //  console.log("hhhttt",homeTeamPlayer);
   let trdata=homeTeamPlayer.filter((item)=>item.player.first_name!==null)
  //  console.log("hhtt22",trdata);
  const generatePointsForPlayer = (breakdown) => {
    let pointsGained = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      // console.log("stats--", statistic);
      // if (statistic.player_name == "player1 p1" && statistic.quater == 0) {
      //   // if (statistic.player_name == "Ling He") {
      //   console.log(
      //     "points gained player3",
      //     statistic.points_gained,
      //     statistic.statistic_description,
      //     statistic.quater
      //   );
      // }
      pointsGained += Number(statistic.points_gained);
      // console.log("points gained--", pointsGained);
    }
    return pointsGained;
  };

  const calculateRebounds = (breakdown) => {
    let rebounds = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];

      if (statistic.statistic_description.indexOf("REBOUND") != -1) {
        rebounds++;
      }

      return rebounds;
    }
  };

  const calculateAssists = (breakdown) => {
    let assists = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description.indexOf("ASSIST") != -1) {
        assists++;
      }
    }
    return assists;
  };

  const calculateSteals = (breakdown) => {
    let steals = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description.indexOf("STEAL") != -1) {
        steals++;
      }
    }
    return steals;
  };

  const calculateBlocks = (breakdown) => {
    let blocks = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description.indexOf("BLOCK") != -1) {
        blocks++;
      }
    }
    return blocks;
  };

  const calculateFGM = (breakdown) => {
    let fgm = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];

      if (
        statistic.statistic_description == "2_POINTER" ||
        statistic.statistic_description == "3_POINTER"
      ) {
        fgm++;
      }
    }
    return fgm;
  };

  const calculateFGA = (breakdown) => {
    let fga = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];

      if (
        statistic.statistic_description == "2_POINTER" ||
        statistic.statistic_description == "3_POINTER" ||
        statistic.statistic_description == "2_POINTS_MISSED" ||
        statistic.statistic_description == "3_POINTS_MISSED"
      ) {
        fga++;
      }
    }
    return fga;
  };

  const calculateFGPercentage = (breakdown) => {
    if (calculateFGA(breakdown) == 0) {
      return 0;
    } else {
      return (
        parseFloat(calculateFGM(breakdown) / calculateFGA(breakdown)) * 100 || 0
      );
    }
  };

  const calculate3PM = (breakdown) => {
    let threePointersMade = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description == "3_POINTER") {
        threePointersMade++;
      }
    }
    return threePointersMade;
  };

  const calculate3PA = (breakdown) => {
    let threePointersAttempted = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];

      if (
        statistic.statistic_description == "3_POINTER" ||
        statistic.statistic_description == "3_POINTS_MISSED"
      ) {
        threePointersAttempted++;
      }
    }
    return threePointersAttempted;
  };

  const calculate3PointPercentage = (breakdown) => {
    if (calculate3PA(breakdown) == 0) {
      return 0;
    } else {
      return (
        parseFloat(calculate3PM(breakdown) / calculate3PA(breakdown)) * 100 || 0
      ).toFixed(2);
    }
  };

  const calculateFTM = (breakdown) => {
    let ftm = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (
        statistic.statistic_description != undefined &&
        statistic.statistic_description.indexOf("FREETHROW") != -1
      ) {
        let freethrow = statistic.statistic_description;
        freethrow = freethrow.split("_");
        for (let k = 2; k < freethrow?.length; k++) {
          if (freethrow[k] == "1") {
            ftm++;
          }
        }
      }
    }
    return ftm;
  };

  const calculateFTA = (breakdown) => {
    let fta = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description.indexOf("FOUL_TECHNICAL") != -1) {
        let freethrow = statistic.statistic_description;
        freethrow = freethrow.split("_");
        fta += Number(freethrow[2]);
      }
    }
    return fta;
  };

  const calculateFTPercentage = (breakdown) => {
    return calculateFTA(breakdown) == 0
      ? 0
      : parseFloat(calculateFTM(breakdown) / calculateFTA(breakdown)) * 100 ||
          0;
    // 22
  };

  const calculateOffensiveRebounds = (breakdown) => {
    let or = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description.indexOf("OFFENSIVE_REBOUND") != -1) {
        or++;
      }
    }
    return or;
  };

  const calculateDefensiveRebounds = (breakdown) => {
    let dr = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description.indexOf("DEFENSIVE_REBOUND") != -1) {
        dr++;
      }
    }
    return dr;
  };

  const calculateTurnovers = (breakdown) => {
    let to = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description.indexOf("TURNOVER") != -1) {
        to++;
      }
    }
    return to;
  };

  const calculatePersonalFouls = (breakdown) => {
    let pf = 0;

    for (let i = 0; i < breakdown?.length; i++) {
      let statistic = breakdown[i];
      if (statistic.statistic_description.indexOf("FOUL_PERSONAL") != -1) {
        pf++;
      }
    }
    return pf;
  };
  
  const getRecordQuaterWise = (quater) => {
    // homeTeamInfo
    // breakdown
    // homeTeamPlayer
    const playerStats = [];

    // console.log('breakdown', breakdown);
    // debugger

    homeTeamPlayer?.length && homeTeamPlayer.forEach((player, index) => {
      // console.log("homeplayer", player, index);
      let playerInfos;
      if (quater == 4) {
        playerInfos = breakdown.filter(
          (item) => item.player_id === player.player_id && item.quater <= quater
        );
      } else {
        playerInfos = breakdown.filter(
          (item) =>
            item.player_id === player.player_id && item.quater === quater
        );
      }

      let min = 0,
        pos = 0,
        pointsGained = 0,
        rebounds = 0,
        assists = 0,
        steals = 0,
        blocks = 0,
        fgm = 0,
        fga = 0,
        fgprecentage = 0,
        threePointersMade = 0,
        threePointersAttempted = 0,
        threepercentage = 0,
        ftm = 0,
        fta = 0,
        ftpercentage = 0,
        or = 0,
        dr = 0,
        to = 0,
        pf = 0;

      // console.log(playerInfos, "playerinfo123");

      if (playerInfos.length > 0) {
        pointsGained = generatePointsForPlayer(playerInfos);
        rebounds = calculateRebounds(playerInfos);
        assists = calculateAssists(playerInfos);
        steals = calculateSteals(playerInfos);
        blocks = calculateBlocks(playerInfos);
        fgm = calculateFGM(playerInfos);
        fga = calculateFGA(playerInfos);
        fgprecentage = calculateFGPercentage(playerInfos);
        threePointersMade = calculate3PM(playerInfos);
        threePointersAttempted = calculate3PA(playerInfos);
        threepercentage = calculate3PointPercentage(playerInfos);
        ftm = calculateFTM(playerInfos);
        fta = calculateFTA(playerInfos);
        ftpercentage = calculateFTPercentage(playerInfos);
        or = calculateOffensiveRebounds(playerInfos);
        dr = calculateDefensiveRebounds(playerInfos);
        to = calculateTurnovers(playerInfos);
        pf = calculatePersonalFouls(playerInfos);
      }

      const playerStat = {
        name: `${player.player.first_name} ${player.player.last_name}`,
        pos,
        min,
        pts: pointsGained % 1 == 0 ? pointsGained : pointsGained.toFixed(1),
        reb: rebounds % 1 == 0 ? rebounds : rebounds.toFixed(1),
        ast: assists % 1 == 0 ? assists : assists.toFixed(1),
        stl: steals % 1 == 0 ? steals : steals.toFixed(1),
        blk: blocks % 1 == 0 ? blocks : blocks.toFixed(1),
        fgm,
        fga,
        "fg%": fgprecentage % 1 == 0 ? fgprecentage : fgprecentage.toFixed(1),
        "3pm":
          threePointersMade % 1 == 0
            ? threePointersMade
            : threePointersMade.toFixed(1),
        "3pa":
          threePointersAttempted % 1 == 0
            ? threePointersAttempted
            : threePointersAttempted.toFixed(1),
        "3p%":
          threepercentage % 1 == 0
            ? Number(threepercentage)
            : Number(threepercentage).toFixed(1),
        ftm,
        fta,
        "ft%": ftpercentage % 1 == 0 ? ftpercentage : ftpercentage.toFixed(1),
        off: or % 1 == 0 ? or : or.toFixed(1),
        def: dr % 1 == 0 ? dr : dr.toFixed(1),
        to: to % 1 == 0 ? to : to.toFixed(1),
        pf,
      };

      playerStats.push(playerStat);
    });
    // console.log('playerStats', playerStats);

    let sortednumbers = playerStats.sort((a, b) => b.pts - a.pts);
    return sortednumbers;
  };
  
//  console.log("gr",getRecordQuaterWise(0));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  

  const [anchorEl, setAnchorEl] = useState(null);
  const [rowId, setrowId] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event,rowId) => {
    event.rowId = rowId
    // console.log("eventtttt",event);
    setAnchorEl(event.currentTarget);
    setrowId(event.rowId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    // console.log("event, newValue",event.target, newValue);
    setValue(newValue);
  };

  const handleChangeBtn = (event, newValue) => {
    // console.log("event, newValue",event.target, newValue);
    setValue(newValue);
  };
  // console.log("statsss",teamlist && teamlist.length && teamlist[0].name,teamlist && teamlist.length && teamlist[0].town);
  // console.log("statsss",teamlist);
  // console.log("statsss",homeTeamInfo && homeTeamInfo.length && homeTeamInfo.name);
  return (
    <>

<div className="tab-btn" id="wrapper">
        <button className="tab-btn1 curr"  onClick={() => {
                history.push(
                  `/schedule/home-stats/${id}`
                  // `/admin/home-status/${item.home_team}`
                );
              }}>
          View Home Team Stats
        </button>
        <button className="tab-btn2" onClick={() => {
                history.push(
                  `/schedule/visitor-stats/${id}`
                  // `/admin/home-status/${item.home_team}`
                );
              }}>
          View Visitor Team Stats
        </button>
        
      </div>
        <div className='league-header'>Home Team Statistics</div>
      
      
        {/* <Tabs
        value={value}
        // onChange={handleChangeBtn}
        textColor="secondary"
        className="tabs-btn"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        style={{backgroundColor:"#1F1F21",color:"white", display: 'flex', justifyContent: 'space-around'}}
      >
        <Tab value="btn1" label="View Home Team Stats"   style={{color:"white",width:"100%"}} onClick={() => {
                history.push(
                  `/schedule/home-stats/${id}`
                  // `/admin/home-status/${item.home_team}`
                );
              }}/>
        <Tab value="btn2" label="View Visitor Team Stats" style={{color:"white",width:"100%"}}  onClick={() => {
                history.push(
                  `/schedule/visitor-stats/${id}`
                  // `/admin/home-status/${item.home_team}`
                );
              }}/>
      </Tabs> */}

      <div style={{backgroundColor: "#1F1F21", padding: 15}}>
        <div className="head-sec" style={{backgroundColor: '#1F1F21',color:"white",marginBottom:"0px",paddingBottom:"10px"}}>
      <h5>Team Name : {homeTeamInfo && homeTeamInfo.name}</h5>
        <h5>Organization : {homeTeamInfo  && homeTeamInfo.town}</h5>
        </div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{backgroundColor:"black",color:"white"}}> */}
  <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto"
  aria-label="scrollable auto tabs example" style={{backgroundColor:"black",color:"white"}}>
    <Tab label="Period 1" {...a11yProps(0)} />
    <Tab label="Period 2" {...a11yProps(1)} />
    <Tab label="Period 3" {...a11yProps(2)} />
    <Tab label="Period 4" {...a11yProps(3)} />
    <Tab label="Total" {...a11yProps(4)} />
  </Tabs>
</Box>
{
[0,1,2,3,4].map((item)=> {
  return(
<TabPanel value={value} index={item} style={{backgroundColor:"black",color:'white'}}>
  {/* Item One */}
   {/* <div className='container' style={{paddingBottom: 50, paddingTop: 30}}> */}
   <div style={{marginTop:0,paddingTop:0}}>
    <Paper className='league_table_body' sx={{ width: '100%', overflow: 'hidden'}}>
      <TableContainer >
   
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {/* {console.log("legust",stats)} */}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
             {/* {[stats][0].playerList?.length ? [stats][0].playerList */}
            {
            !isLoading ?
            playerQueterData.length ? playerQueterData 
            // rows?.length?rows
          //  { arr?.length ? arr
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row,i) => {
                // console.log("rrr",row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                    // let newData=getRecordQuaterWise(0)
                      // console.log("playerQueterData",playerQueterData);
                      return (
                        <>
                        {column.id !== "dropdown" ? 
                        <TableCell key={column.id} align={column.align}>
                          {/* {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value} */}
                            
                            {value}
                        </TableCell>
                        : 
                          <TableCell className="tableData">
                             <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      {/* <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="menubar"
      >
        <MenuItem onClick={handleClose} disableRipple>
          {/* <EditIcon /> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${row.id}`} style={{color: "#000", textDecoration: "none"}}> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/2`} style={{color: "white", textDecoration: "none"}}>
          View Schedules
          </Link>
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          {/* <FileCopyIcon /> */}
          {/* View Stats */}
        {/* </MenuItem> */} 
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          {/* <ArchiveIcon /> */}
          {/* View Standings */}
        {/* </MenuItem>  */}
        {/* <MenuItem onClick={handleClose} disableRipple> */}
          {/* <MoreHorizIcon /> */}
          View Teams & Players
        {/* </MenuItem> */}
      {/* </StyledMenu>  */}
    </div>
                            </TableCell>
                        }
                     
                         </>
                      );
                    })}
                  </TableRow>
                );
              }): <div style={{textAlign:"center",
                width: "100%",
                position: "absolute",
                left: "1%",
                color: "white"}}> NO STATISTICS AVAILABLE </div>
              :
              // <h2>Loading....</h2>
              <div
              // className="text-center"
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection:"row",

              }}
            >
              <div style={{position:"absolute",left:"45%"}}>
                <img
                  src={basketballgif}
                  alt="gif"
                  style={{ width: "150px", height: "130px" }}
                />
                <h2 style={{color:"white",textAlign:"center"}}>Loading...</h2>
              </div>
            </div>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        // count={schedule.length}
        // count={teamlist.length}
        // count={rows.length}
        count={playerQueterData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
</TabPanel>
)
})
}

    </div>
    </>
  );
}

export default HomeTeamStats;
