// // import React, { useEffect, useState } from "react";
// // import { Col, Row } from "react-bootstrap";
// // import "./League.scss";
// // import { useOnMount } from "../../utils/helpers";
// // import { useDispatch, useSelector } from "react-redux";
// // import TextFieldComponent from "../../Components/TextFieldComponent/TextFieldComponent";
// // import headerSection from "../../assets/images/headerSection.jpg";
// // import CardListTable from "../../Components/CardListTable/CardListTable";
// // import { Button } from "@material-ui/core";
// // import ContactUs from "../../Components/ContactUs/ContactUs";
// // import constants from "../../utils/constants";
// // import { Link } from "react-router-dom";
// // import { getLeagueListAction } from "../../Store/actions/leagueAction";
// // // const Eventcolumns = [
// // //   {
// // //     // name: "NO.",
// // //     selector: "img",
// // //     // sortable: true,
// // //     grow: "1",
// // //     cell: (row) => {
// // //       return (
// // //         <>
// // //           <img
// // //             className="w-50"
// // //             src={
// // //               row.logo
// // //                 ? constants.IMG_URL + row.logo
// // //                 : "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
// // //             }
// // //             alt="img"
// // //           />
// // //         </>
// // //       );
// // //     },
// // //   },
// // //   {
// // //     name: "LEAGUE NAME",
// // //     selector: "name",
// // //       sortable: true,
// // //     grow: "5",
// // //     cell: (row) => {
// // //       return (
// // //         <>
// // //           <h5>{row.name}</h5>
// // //         </>
// // //       );
// // //     },
// // //   },
// // //   {
// // //     name: "GENDER",
// // //     selector: "gender",
// // //     grow: "2",
// // //     cell: (row) => {
// // //       return(
// // //         <>
// // //             <h5>{row.gender == 1 ? "Male" : row.gender == 2 ? "Female" : "Mixed"}</h5>
// // //         </>
// // //       )
// // //     }
// // //   },
// // //   {
// // //     name: "LEVEL",
// // //     selector: "level",
// // //     grow: "2",
// // //     cell: (row) => {
// // //         return(
// // //           <>
// // //             <h5>{row.level}</h5>
// // //           </>
// // //         )
// // //     }
// // //   },
// // //   {
// // //     // name: "ADDRESS",
// // //     selector: "address",
// // //     //   sortable: true,
// // //     grow: "5",
// // //     cell: (row) => {
// // //       return (
// // //         <>
// // //           <div className="d-flex justify-content-between">
// // //             <Button>
// // //               <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${row.id}`} style={{color: "#000", textDecoration: "none"}}>
// // //                 View Schedule
// // //               </Link>
// // //             </Button>

// // //             <Button>
// // //               <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_STATUS}/${row.id}`} style={{color: "#000", textDecoration: "none"}}>View Stats</Link>
// // //             </Button>
// // //           </div>
// // //         </>
// // //       );
// // //     },
// // //   },
// // // ];

// // function League(props) {
// //   // const dispatch = useDispatch();
// //   // const LeagueList = useSelector((state) => state.league.list);
// //   // const [updatedList, setUpdatedList] = useState([]);

// //   // useOnMount(() => {
// //   //   dispatch(getLeagueListAction());
// //   // });
// //   // useEffect(() => {
// //   //   setUpdatedList(LeagueList);
// //   // }, [LeagueList]);
// //   // const handleSearchInput = (event) => {
// //   //   let tempUpdatedList = LeagueList.length ? LeagueList : [];
// //   //   tempUpdatedList = tempUpdatedList.filter((item) => {
// //   //     const {
// //   //       name
// //   //     } = item;
// //   //     const searchKeyWord = event.target.value.toLowerCase().trim();
// //   //     return name.toLowerCase().search(searchKeyWord) !== -1;
// //   //   });
// //   //   setUpdatedList(tempUpdatedList);
// //   // }
// //   return (
// //     <>
// //     <h2>Hello</h2>
    
// //       {/* <div className="header-league-section">
// //         <img className="img-fulid" src={headerSection} alt="header-poster" />
// //       </div>
// //       <section className="">
// //         <Row className="align-items-center justify-content-end mx-0">
// //           <Col md={12}>
// //             <div className="header-contain text-center">
// //               <div className="header pl-0">Leagues</div>
// //               <div className="sub-header">Leagues</div>
// //             </div>
// //           </Col>
// //           <Col md={4} className="mt-5 mt-md-0">
// //             <TextFieldComponent
// //               className=""
// //               name="deviceName"
// //               id="deviceName"
// //               type="text"
// //               placeholder="Search Here"
// //               onChange={handleSearchInput}
// //               // value={initialValues.deviceName}
// //               // onBlur={handleFocus}
// //               // onChange={handleChangeValue}
// //               // error
// //               // helperText={isError.deviceName}
// //             />
// //           </Col>
// //         </Row>

// //         <Row className="league-list mx-0">
// //           <Col md={12}>
// //             <CardListTable
// //               columns={Eventcolumns}
// //               data={updatedList}
// //               TheadComponent={(_) => null}
// //               // pending={loading}
// //               // pagination={false}
// //               // custompagination
// //               // paginationServer={false}
// //               noDataString={"No data found"}
// //               // totalListCount={count}
// //               // paginationTotalRows={count}
// //               // paginationPerPage={limit}
// //               // onPageChangedCalled={handlePageChange}
// //               // inputClassName="mt-3"
// //               // sortFunction={customSort}
// //             />
// //           </Col>
// //         </Row>
// //       </section> */}
// //     </>
// //   );
// // }




// // export default League;

// import {
//   CButton,
//   CCard,
//   CCardBody,
//   CCardHeader,
//   CDataTable,
//   CFooter,
//   CModal,
//   CModalFooter,
//   CModalHeader,
//   CModalBody,
//   CBadge
// } from "@coreui/react";
// import React, { useEffect, useState } from "react";

// const usersData = [
//   {id: 0, name: 'John Doe', registered: '2018/01/01', role: 'Guest', status: 'Pending'},
//   {id: 1, name: 'Samppa Nori', registered: '2018/01/01', role: 'Member', status: 'Active'},
//   {id: 2, name: 'Estavan Lykos', registered: '2018/02/01', role: 'Staff', status: 'Banned'},
//   {id: 3, name: 'Chetan Mohamed', registered: '2018/02/01', role: 'Admin', status: 'Inactive'},
//   {id: 4, name: 'Derick Maximinus', registered: '2018/03/01', role: 'Member', status: 'Pending'},
//   {id: 5, name: 'Friderik Dávid', registered: '2018/01/21', role: 'Staff', status: 'Active'},
//   {id: 6, name: 'Yiorgos Avraamu', registered: '2018/01/01', role: 'Member', status: 'Active'},
//   {id: 7, name: 'Avram Tarasios', registered: '2018/02/01', role: 'Staff', status: 'Banned'},
//   {id: 8, name: 'Quintin Ed', registered: '2018/02/01', role: 'Admin', status: 'Inactive'},
//   {id: 9, name: 'Enéas Kwadwo', registered: '2018/03/01', role: 'Member', status: 'Pending'},
//   {id: 10, name: 'Agapetus Tadeáš', registered: '2018/01/21', role: 'Staff', status: 'Active'},
//   {id: 11, name: 'Carwyn Fachtna', registered: '2018/01/01', role: 'Member', status: 'Active'},
//   {id: 12, name: 'Nehemiah Tatius', registered: '2018/02/01', role: 'Staff', status: 'Banned'},
//   {id: 13, name: 'Ebbe Gemariah', registered: '2018/02/01', role: 'Admin', status: 'Inactive'},
//   {id: 14, name: 'Eustorgios Amulius', registered: '2018/03/01', role: 'Member', status: 'Pending'},
//   {id: 15, name: 'Leopold Gáspár', registered: '2018/01/21', role: 'Staff', status: 'Active'},
//   {id: 16, name: 'Pompeius René', registered: '2018/01/01', role: 'Member', status: 'Active'},
//   {id: 17, name: 'Paĉjo Jadon', registered: '2018/02/01', role: 'Staff', status: 'Banned'},
//   {id: 18, name: 'Micheal Mercurius', registered: '2018/02/01', role: 'Admin', status: 'Inactive'},
//   {id: 19, name: 'Ganesha Dubhghall', registered: '2018/03/01', role: 'Member', status: 'Pending'},
//   {id: 20, name: 'Hiroto Šimun', registered: '2018/01/21', role: 'Staff', status: 'Active'},
//   {id: 21, name: 'Vishnu Serghei', registered: '2018/01/01', role: 'Member', status: 'Active'},
//   {id: 22, name: 'Zbyněk Phoibos', registered: '2018/02/01', role: 'Staff', status: 'Banned'},
//   {id: 23, name: 'Aulus Agmundr', registered: '2018/01/01', role: 'Member', status: 'Pending'},
//   {id: 42, name: 'Ford Prefect', registered: '2001/05/25', role: 'Alien', status: 'Don\'t panic!'}
// ]
// function League(){
// const [details, setDetails] = useState([])
// // const [items, setItems] = useState(usersData)

// const toggleDetails = (index) => {
//   const position = details.indexOf(index)
//   let newDetails = details.slice()
//   if (position !== -1) {
//     newDetails.splice(position, 1)
//   } else {
//     newDetails = [...details, index]
//   }
//   setDetails(newDetails)
// }


// const fields = [
//   { key: 'name', _style: { width: '40%'} },
//   'registered',
//   { key: 'role', _style: { width: '20%'} },
//   { key: 'status', _style: { width: '20%'} },
//   {
//     key: 'show_details',
//     label: '',
//     _style: { width: '1%' },
//     sorter: false,
//     filter: false
//   }
// ]

// const getBadge = (status)=>{
//   switch (status) {
//     case 'Active': return 'success'
//     case 'Inactive': return 'secondary'
//     case 'Pending': return 'warning'
//     case 'Banned': return 'danger'
//     default: return 'primary'
//   }
// }

// return (
//   <CDataTable
//     items={usersData}
//     fields={fields}
//     columnFilter
//     tableFilter
//     footer
//     itemsPerPageSelect
//     itemsPerPage={5}
//     hover
//     sorter
//     pagination
//     scopedSlots = {{
//       'status':
//         (item)=>(
//           <td>
//             <CBadge color={getBadge(item.status)}>
//               {item.status}
//             </CBadge>
//           </td>
//         ),
//       'show_details':
//         (item, index)=>{
//           return (
//             <td className="py-2">
//               <CButton
//                 color="primary"
//                 variant="outline"
//                 shape="square"
//                 size="sm"
//                 onClick={()=>{toggleDetails(index)}}
//               >
//                 {details.includes(index) ? 'Hide' : 'Show'}
//               </CButton>
//             </td>
//             )
//         },
//       'details':
//           (item, index)=>{
//             return (
//             // <CCollapse show={details.includes(index)}>
//               <CCardBody>
//                 <h4>
//                   {item.username}
//                 </h4>
//                 <p className="text-muted">User since: {item.registered}</p>
//                 <CButton size="sm" color="info">
//                   User Settings
//                 </CButton>
//                 <CButton size="sm" color="danger" className="ml-1">
//                   Delete
//                 </CButton>
//               </CCardBody>
//             // </CCollapse>
//           )
//         }
//     }}
    
//   />
// )
//  }
//  export default League;


import React,{useState,useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import constants from "../../utils/constants";
import { Link } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { useOnMount } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { getLeagueListAction } from "../../Store/actions/leagueAction";
import SearchBar from "material-ui-search-bar";
import basketballgif from '../../assets/images/basketballLoader.gif'
import './League.scss'


// function League(props) {
  
// }

// const data = () => {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   return(
//     <div>
//    <Button
//         id="demo-customized-button"
//         aria-controls={open ? 'demo-customized-menu' : undefined}
//         aria-haspopup="true"
//         aria-expanded={open ? 'true' : undefined}
//         variant="contained"
//         disableElevation
//         onClick={handleClick}
//       >
//         Options
//       </Button>
//       <StyledMenu
//         id="demo-customized-menu"
//         MenuListProps={{
//           'aria-labelledby': 'demo-customized-button',
//         }}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//       >
//         <MenuItem onClick={handleClose} disableRipple>
//           Edit
//         </MenuItem>
//         <MenuItem onClick={handleClose} disableRipple>
//           Duplicate
//         </MenuItem>
//         <Divider sx={{ my: 0.5 }} />
//         <MenuItem onClick={handleClose} disableRipple>
//           Archive
//         </MenuItem>
//         <MenuItem onClick={handleClose} disableRipple>
//           More
//         </MenuItem>
//       </StyledMenu>
//     </div>
//   )
// }
const columns = [
  { id: 'name', label: 'League Name', align: 'left', minWidth: 250}, 
  { id: 'gender', label: 'Gender', align: 'center', minWidth: 250},
  {
    id: 'level',
    label: 'League Level',  
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
     minWidth: 250
  },
  {
    id: 'dropdown',
    // label: 'Size\u00a0(km\u00b2)',
    label: 'League Details',
    align: 'center', minWidth: 250
    // format: "123",
    
  },
  
  // {
  //   id: 'schedule',
  //   label: 'View Schedule',
  //   minWidth: 170,
  //   align: 'right',
  //   format: (value) => value.toFixed(2),
  // },
];
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: 'black',
    color:'white',
    // color:
    //   theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


function createData(name, gender, level) {
  // const density = population / size;
  return { name, gender,level };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];

export default function StickyHeadTable() {

  const dispatch = useDispatch();
  const LeagueList = useSelector((state) => state.league.list);
  const {isLoading} = useSelector((state)=>state.loading)

  const [updatedList, setUpdatedList] = useState([]);
  const [rows, setRows] = useState([]);

  useOnMount(() => {
    dispatch(getLeagueListAction());
  });
  useEffect(() => {
    setUpdatedList(LeagueList);
    setRows(LeagueList);
  }, [LeagueList]);
  // console.log("lllll1",LeagueList);
  // let LeagueListupd=LeagueList.map((item)=>{
  //   // console.log("iii",item);
  //   if(item.gender==1){
  //     return {...item, gender: 'Male'};
  //   }else if(item.gender==2){
  //     return {...item, gender: 'Female'};
  //   }else{
  //     return {...item, gender: 'Mixed'};

  //   }
  //   return item;
  // })
  
  // const handleSearchInput = (event) => {
  //   let tempUpdatedList = LeagueList.length ? LeagueList : [];
  //   tempUpdatedList = tempUpdatedList.filter((item) => {
  //     console.log("item111",item);
  //     const {
  //       gender
  //     } = item;
  //     const searchKeyWord = event.target.value.toLowerCase().trim();
  //     console.log("seee",searchKeyWord);
  //     return gender.toLowerCase().search(searchKeyWord) !== -1 ;
  //   });
  //   setUpdatedList(tempUpdatedList);
  // }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  

  const [anchorEl, setAnchorEl] = useState(null);
  const [rowId, setrowId] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event,rowId) => {
    event.rowId = rowId
    // console.log("eventtttt",event);
    setAnchorEl(event.currentTarget);
    setrowId(event.rowId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    const filteredRows = updatedList.filter((row) => {
      const {name,gender,level}=row
      // return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      return name.toLowerCase().includes(searchedVal.toLowerCase()) || gender.toLowerCase().includes(searchedVal.toLowerCase()) || level.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
    // setUpdatedList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
    setRows(updatedList)
  };
  

  // sx={{ maxHeight: 440 }} 
  return (
    <>
    <div className='league-head'>Leagues And Tournaments</div>
    <div style={{backgroundColor: '#1F1F21'}}>
    <div className='container' style={{paddingBottom: 50, paddingTop: 50}}>
       <div 
      //  style={{display:"flex",justifyContent:"flex-end"}}  
       className='searchbar'>
        <SearchBar
          className='search'
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          // style={{width:"30%",border:"none",height:"40px",marginBottom:"14px",color:"black"}}
          // className='search'
        />
        </div>
    <Paper className='league_table_body' sx={{ width: '100%', overflow: 'hidden'}}>
    {/* <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          style={{width:"50%",border:"none",height:"30px"}}
          // className='search'
        /> */}
      <TableContainer >
   
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ?
            rows?.length?rows
            // {updatedList?.length?updatedList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                // console.log("rrr",row);
                const id=row.id
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      // console.log("vvv",value);
                    let linkUrl =  `${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${row.id}`
                      return (
                        <>
                        {/* {column.id !== "schedule" ?  */}
                        {column.id !== "dropdown" ? 
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                        : 
                          <TableCell className="tableData">
                             <div>
                              {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${row.id}`}>
                              <Button>View Schedule</Button>
                              </Link> */}
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={(e) => handleClick(e, row.id)}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
       <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="menubar"
      >
        <MenuItem onClick={handleClose} disableRipple>
          {/* {console.log("wsw",row.id)} */}
          {/* <EditIcon /> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${row.id}`} style={{color: "#000", textDecoration: "none"}}> */}
          {/* <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/2`} style={{color: "white", textDecoration: "none"}}> */}
          <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE}/${rowId}`} style={{color: "white", textDecoration: "none"}}>
          View Schedules
          </Link>
        </MenuItem>

        <MenuItem onClick={handleClose} disableRipple>
        <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_STATUS}/${rowId}`} style={{color: "white", textDecoration: "none"}}>
          {/* <FileCopyIcon /> */}
          View Stats
          </Link>
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        <MenuItem onClick={handleClose} disableRipple>
          {/* <ArchiveIcon /> */}
        <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_STANDING}/${rowId}`} style={{color: "white", textDecoration: "none"}}>

          View Standings
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          {/* <MoreHorizIcon /> */}
        <Link to={`${constants.ROUTE.LEAGUE.LEAGUE_TEAM_PLAYER}/${rowId}`} style={{color: "white", textDecoration: "none"}}>

          View Teams & Players
        </Link>
        </MenuItem>
      </StyledMenu>
       {/* } */}
    </div>
                            </TableCell>
                        }
                     
                         </>
                      );
                    })}
                  </TableRow>
                );
              }):<div className="no-data-found"> NO LEAGUES AVAILABLE </div>
              :
              <div
              // className="text-center"
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection:"row",

              }}
            >
              <div style={{position:"absolute",left:"45%"}}>
                <img
                  src={basketballgif}
                  alt="gif"
                  style={{ width: "150px", height: "130px" }}
                />
                <h2 style={{color:"white",textAlign:"center"}}>Loading...</h2>
              </div>
            </div>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        // count={updatedList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
    </div>
    </>
  );
}