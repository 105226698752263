import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import './Layout.scss'
const Layout = ({ children }) => {
  return (
    <div>
      <Container fluid className="px-0">
        <Header />
        <div className="main-content">
          <div className="page-content">{children}</div>
        </div>
        <Footer/>
      </Container>
    </div>
  );
};

export default Layout;
