import AboutUs from '../../Pages/AboutUs/AboutUs';
import FAQ from '../../Pages/FAQ/FAQ';
import HighLight from '../../Pages/HighLight/HighLight';
import ContactUsPage from '../../Pages/ContactUs/ContactUs';
import Home from '../../Pages/Home/Home';
import NewArticles from '../../Pages/Home/NewArticles/NewArticles';
import HowWorks from '../../Pages/HowWorks/HowWorks';
import League from '../../Pages/League/League';
import Story from '../../Pages/Story/Story';
import LeagueViewSchedule from '../../Pages/League/LeagueViewSchedule';
import LeagueViewStatus from '../../Pages/League/LeagueViewStatus';
import PlayerDetails from '../../Pages/Players/PlayerDetails';
import PlayersList from '../../Pages/Players/PlayersList';
import PrivacyPolicy from '../../Pages/PrivacyPolicy/PrivacyPolicy';
import Watch from '../../Pages/Watch/Watch';
import constants from '../constants';
import Features from '../../Pages/Features/Features';
import BlogDetails from '../../Pages/BlogDetails/BlogDetails';
import TermsofUse from '../../Pages/TermsofUse/TermsofUse';
import Profile from '../../Pages/Players/Profile';
import CoachList from '../../Pages/Players/CoachList';
import StoryDetails from '../../Pages/Story/StoryDetails';
import { Link } from 'react-router-dom';
import LeagueViewStanding from '../../Pages/League/LeagueViewStanding';
import LeagueTeamPlayer from '../../Pages/League/LeagueTeamPlayer';
import TeamPlayerStats from '../../Pages/Team/TeamPlayerStats'
import HomeTeamStats from '../../Pages/Team/HomeTeamStats'
import VisitorTeamStats from '../../Pages/Team/VisitorTeamStats';
import StatsPlayerDetails from '../../Pages/League/StatsPlayerDetails';
import WebDetails from '../../Pages/Story/WebDetails';
import Pricing from '../../Pages/Story/Pricing';

export const headerRoute = [
  {
    path: constants.ROUTE.HEADER.HOME,
    component: Home,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.WATCH,
    component: Watch,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.LEAGUE,
    component: League,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.NEW_ARTICLES,
    component: NewArticles,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.HOW_WORK,
    component: HowWorks,
    header: true,
  },
  // {
  //     path: constants.ROUTE.HEADER.WATCH,
  //     component: HighLight,
  //     header: true,
  // },
  {
    path: constants.ROUTE.HEADER.FAQ,
    component: FAQ,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.ABOUT_US,
    component: AboutUs,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.STORY,
    component: Story,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.PRICING,
    component: Pricing,
    header: true,
  },
  {
    path: constants.ROUTE.STORY.STORY_DETAILS,
    component: StoryDetails,
    header: true,
  },
  {
    path: constants.ROUTE.STORY.WEB_DETAILS,
    component: WebDetails,
    header: true,
  },
  {
    path: constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE_ID,
    component: LeagueViewSchedule,
    header: true,
  },
  {
    path: constants.ROUTE.LEAGUE.LEAGUE_SCHEDULE,
    component: LeagueViewSchedule,
    header: true,
  },
  {
    path: constants.ROUTE.LEAGUE.LEAGUE_STATUS_ID,
    component: LeagueViewStatus,
    header: true,
  },
  {
    path: constants.ROUTE.LEAGUE.LEAGUE_STATUS,
    component: LeagueViewStatus,
    header: true,
  },
  {
    path: constants.ROUTE.LEAGUE.LEAGUE_STANDING_ID,
    component: LeagueViewStanding,
    header: true,
  },
  {
    path: constants.ROUTE.LEAGUE.LEAGUE_TEAM_PLAYER_ID,
    component: LeagueTeamPlayer,
    header: true,
  },
  {
    path: constants.ROUTE.TEAM.PLAYERSTATS_ID,
    component: TeamPlayerStats,
    header: true,
  },
  {
    path: constants.ROUTE.TEAM.HOMESTATS_ID,
    component: HomeTeamStats,
    header: true,
  },
  {
    path: constants.ROUTE.TEAM.VISITORSTATS_ID,
    component: VisitorTeamStats,
    header: true,
  },
  {
    path: constants.ROUTE.LEAGUE.LEAGUE_STATS_PLAYER_DETAILS_ID,
    component: StatsPlayerDetails,
    header: true,
  },
  {
    path: constants.ROUTE.PLAYER.PLAYER_LIST,
    component: PlayersList,
    header: true,
  },
  {
    path: constants.ROUTE.PLAYER.PLAYER_DETAILS_ID,
    component: PlayerDetails,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.CONTACT,
    component: ContactUsPage,
    header: true,
  },
  {
    path: constants.ROUTE.HEADER.PRIVACY,
    component: PrivacyPolicy,
    header: true,
  },
  {
    path: constants.ROUTE.FEATURE.FEATURE_LIST,
    component: Features,
    header: true,
  },
  {
    path: constants.ROUTE.BLOG.BLOG_DETAILS,
    component: BlogDetails,
  },
  {
    path: constants.ROUTE.HEADER.TERMS,
    component: TermsofUse,
  },
  {
    path: constants.ROUTE.HEADER.PROFILE,
    component: Profile,
  },
  {
    path: constants.ROUTE.PLAYER.COACH_LIST,
    component: CoachList,
  },
];

export const header = [
  {
    title: 'Home',
    path: constants.ROUTE.HEADER.HOME,
    cName: '',
    header: true,
    children: [],
  },
  {
    title: 'Leagues and Tournaments',
    path: constants.ROUTE.HEADER.LEAGUE,
    cName: '',
    header: true,
    children: [],
  },
  // {
  //   title: 'Highlights',
  //   path: constants.ROUTE.HEADER.HIGHLIGHT,
  //   cName: '',
  //   header: true,
  //   children: [],
  // },
 
  //  {
  //   title: 'Teams & Players',
  //   path: constants.ROUTE.HEADER.STORY,
  //   cName: '',
  //   header: true,
  //   children: [],
  // },
  {
    title: 'Pricing',
    path: constants.ROUTE.HEADER.PRICING,
    // path: constants.ROUTE.HEADER.STORY,
    cName: '',
    header: true,
    children: [],
  },
  // {
  //   title: 'Profile',
  //   path: constants.ROUTE.HEADER.PROFILE,
  //   cName: '',
  //   header: true,
  //   children: [],
  // },
  {
    title: 'Contact Us',
    path: constants.ROUTE.HEADER.CONTACT,
    cName: '',
    header: true,
    children: [],
  },
  // {
  //   title: 'Login',
  //   path: constants.ROUTE.HEADER.LOGIN,
  //   cName: '',
  //   header: true,
  //   children: [],
  //   // cell: (row) => {
  //   //   return (

  //   //   <>

  //   //     <Link to="http://demo.hooplivestats.com/admin/login" />
  //   //     </>
  //   //   )
  //   // },
  // },
  // {
  //     title: "Privacy Policy",
  //     path: constants.ROUTE.HEADER.PRIVACY,
  //     cName: "",
  //     header: true,
  //     children: []
  // }
];
