import React ,{useState,useEffect} from "react";
import { Row, Button, Col } from "react-bootstrap";
import PlayStore from "../../assets/images/playstore.png";
import AppleStore from "../../assets/images/apple.png";

import headerSection from "../../assets/images/headerSection.jpg";
import homepagemain from "../../assets/images/hooplive-main.jpg";
import sec3img from "../../assets/images/sec3img.png";
import "./Home.scss";
import ContactUs from "../../Components/ContactUs/ContactUs";
import { Grid } from "@material-ui/core";
import ButtonComponent from "../../Components/ButtonComponent/ButtonComponent";
import { Link } from "react-router-dom";
import { useOnMount } from "../../utils/helpers";
import {
  getFeature,
  getNewsArticles,
  setBlogsDetails,
  getSectionOneAction,
  getSectionTwoAction,
  getSectionFaqAction
} from "../../Store/actions/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import collapse1 from '../../assets/images/collapse1.svg'
import collapse2 from '../../assets/images/collapse2.svg'
import collapse3 from '../../assets/images/collapse3.svg'
import collapse4 from '../../assets/images/collapse4.svg'
import constants from "../../utils/constants";
import CarouselComponent from "../../Components/Carousel/Carousel";
import Accordion from "react-bootstrap/Accordion";
import { sendEmailSubscription } from "../../Store/actions/HomeAction";
import Story from "../Story/Story";
import section3svg from '../../assets/images/section3.svg' 
import introvid from '../../assets/video/hlsdemo.mp4'
import thumbnail from '../../assets/images/thumbnail.png'


const Home = () => {
  const dispatch = useDispatch();
  const { featureList, newsArticles,sectionOne,sectionTwo,sectionFaq } = useSelector((state) => state.home);
  const featureListShortNumber = 4;
  const featureListShort = featureList.slice(0, featureListShortNumber);
  const blogListShortNumber = 4;
  const blogListShort = newsArticles.slice(0, blogListShortNumber);
  const [emailsubs, setEmailSubs] = useState({
    email: ""
  });
  const [sectionone,setSectionone]=useState([])
  const [sectiontwo,setSectiontwo]=useState([])

  useOnMount(() => {
    // dispatch(getFeature());
    // dispatch(getNewsArticles());
    dispatch(getSectionOneAction());
    dispatch(getSectionTwoAction());
    dispatch(getSectionFaqAction());
    // dispatch(sendEmailSubscription({
    //   "email":"xyzaa@yopmail.com"
    // }))
  });

  // useEffect(() => {
  //  setSectionone(sectionOne)
  //  setSectiontwo(sectionTwo)
  // }, [sectionOne,sectionTwo]);

 
  return (
    // <div style={{ marginTop: "153px" }}>
    <div>
      {/* <div className="control"> */}
      <div className="row sectcontrol sect1cnt">
        {/* <div className="col-md-6 sec1"> */}
        <div className="col-md-6 sec1">
          <div className="maintext-area">
            <span className="main-text" style={{textAlign:"initial"}}>{sectionOne[0] && sectionOne[0]?.title?sectionOne[0]?.title:"Take Control of the game"}</span>
            <p className="intro-para">
             {sectionOne[0] && sectionOne[0]?.description}
            </p>
        <Link to={`${constants.ROUTE.HEADER.CONTACT}`} style={{color: "white", textDecoration: "none"}}>

            <button className="book-btn">Book Demo</button></Link>
          </div>
        </div>
        <div className="col-md-6 sec2vid ">
          {/* <img src={homepagemain} alt="main" className="home-img" /> */}
          {/* <video src={introvid} className="vid1" width="530" height="300" controls="controls" poster={thumbnail} /> */}
          {/* <img src={constants.IMG_URL + sectionOne[0]?.media} className="vid1" width="530" height="300"  /> */}
           {/* {console.log("m",constants.IMG_URL + item.media)} */}
           {sectionOne[0]?.media && sectionOne[0]?.media?.length &&  (sectionOne[0]?.media.split('.').pop()=="jpg" || sectionOne[0]?.media.split('.').pop()=="jpeg"
            || sectionOne[0]?.media.split('.').pop()=="png" 
                   || sectionOne[0]?.media.split('.').pop()=="svg" || sectionOne[0]?.media.split('.').pop()=="bmp"
                   || sectionOne[0]?.media.split('.').pop()=="webp" || sectionOne[0]?.media.split('.').pop()=="tiff" || sectionOne[0]?.media.split('.').pop()=="gif") ?
                     <img src={constants.IMG_URL + sectionOne[0]?.media} className="vid1" width="530" height="300"   />:
                           
           <video src={constants.IMG_URL + sectionOne[0]?.media} className="vid1" width="530" height="300" controls="controls" poster={thumbnail} /> }
          {/* <video src={introvid} className="vid" width="600" height="300" controls="controls" poster={thumbnail} /> */}
          {/* <video src={introvid} className="vid" width="600" height="300" controls="controls"  /> */}

          {/* <img src={homepagemain} alt="main" className="home-img" width={"50%"} /> */}
        </div>
      </div>
      <div className="section-cards">
        <div className="container">
          <div className="sec2head">
            <h2>How can we make league game easier to manage</h2>
          </div>

          {/* <div className="d-flex justify-between card-gap mt-3"> */}
          {/* <div className="row sec2card" style={{minHeight:"50px"}}> */}
          <div className="row sec2card">
            {sectionTwo && sectionTwo.length && sectionTwo.map((item)=>{
              let media="";
            //  if (item.media.split('.').pop()=="jpg" || item.media.split('.').pop()=="jpeg" || item.media.split('.').pop()=="png" 
            //        || item.media.split('.').pop()=="svg" || item.media.split('.').pop()=="bmp"
            //        || item.media.split('.').pop()=="webp" || item.media.split('.').pop()=="tiff" || item.media.split('.').pop()=="gif"){
            //         media="imagetag"
            //        }else{
            //         media="videotag"
            //        }

               return(
                
                <div className="col-md-4 mt-3 card-parent">
                {/* <div className="col-md-4 mt-3"> */}
                <div className="card ">
                  
                  <div className="cardvid mt-0">
                   {/* {console.log("m",constants.IMG_URL + item.media)} */}
                   {item?.media && item?.media?.length &&  (item.media.split('.').pop()=="jpg" || item.media.split('.').pop()=="jpeg" || item.media.split('.').pop()=="png" 
                   || item.media.split('.').pop()=="svg" || item.media.split('.').pop()=="bmp"
                   || item.media.split('.').pop()=="webp" || item.media.split('.').pop()=="tiff" || item.media.split('.').pop()=="gif") ?
                     <img src={constants.IMG_URL + item.media} className="imgcard" width="300" height="200"   />:
                           
           <video src={constants.IMG_URL + item.media} className="vid" width="300" height="200" controls="controls" poster={thumbnail} /> }
                
        

                   {/* <img src={constants.IMG_URL + item.media} className="vid" width="300" height="200" /> */}
                  
           {/* <video src={constants.IMG_URL + item.media} className="vid" width="300" height="200" controls="controls" poster={thumbnail} />  */}
          {/* <video src={introvid} className="vid" width="300" height="200" controls="controls" poster={thumbnail} />   */}
          </div>
                  
                  <div className="card-body">
                    <h5 className="card-title">{item && item.title}</h5>
                    <p className="card-text">
                      {item && item.description}
                    </p>
                    <Button
          variant="dark"
          size="sm"
          // onClick={() => dispatch(setBlogsDetails(article))}
          className="bg-dark webbtn"
          style={{"bottom": "16px","position":"absolute"}}
        >
          <Link
            to={`/web-details/${item.id}`}
            className="text-white"
            style={{ textDecoration: 'none' }}
          >
            Read More
          </Link>
        </Button>
                  </div>
                </div>
              </div>
               )
            })}
            {/* <div className="">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Scheduling System</h5>
                  <p className="card-text">
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Referee Mobile App</h5>
                  <p className="card-text">
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Admin Mobile App</h5>
                  <p className="card-text">
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className="d-flex justify-between card-gap mt-3">
            <div className="">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Scheduling System</h5>
                  <p className="card-text">
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Referee Mobile App</h5>
                  <p className="card-text">
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Admin Mobile App</h5>
                  <p className="card-text">
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className="row section3"> */}
        {/* <div className="col-md-6 "> */}
          {/* <img src={section3svg} alt="main" className="section3svg" /> */}
          
           {/* <svg
            className="placeholder-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 525.5 525.5"
          >
            <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"></path>
            <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"></path>
            <path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"></path>
          </svg> */}
        {/* </div>  */}
        {/* { <div className="col-md-6">
          <div className="sec3-area">
            <h2>Everything you need in one device</h2>
            <div className="para3">
            <p>
              record all relevant match data through a simple app. An intuitive
              interface with plenty of shortcuts ensures you’ll spend less time
              looking at your notes and more time managing the game.
            </p>
            <p>
              From the first to the last whistle, the Hoop Live Stats app offers
              a smoother, simpler way to manage the game.
            </p>
            </div>
          </div>
        </div> } */}
      {/* </div> */}
      {/* <div className="row section3"> */}
        {/* <div className="col-md-6"> */}
          {/* <div className="maintext-area"> */}
            {/* <h2>Insights On The Go</h2>
            <p>
              Pair text with an image to focus on your chosen product,
              collection, or blog post. Add details on availability, style, or
              even provide a review.
            </p> */}
            {/* <button>Book Demo</button> */}
        {/* <Link to={`${constants.ROUTE.HEADER.CONTACT}`} style={{color: "white", textDecoration: "none"}}>

            <button className="book-btn">Book Demo</button>
            </Link>
          </div> */}
        {/* </div> */}
        {/* <div className="col-md-6 "> */}
          {/* <img src={sec3img} alt="main" className="home-img" /> */}
          {/* <svg
            className="placeholder-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 525.5 525.5"
          >
            <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"></path>
            <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"></path>
            <path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"></path>
          </svg> */}
        {/* </div> */}
      {/* </div> */}
      {/* <div className="product">
        <div className="row section4 container">
          <div className="col-md-3">
            <div className="maintext-area">
              <h3>Example Product Title</h3>
              <p>$19.99 CAD</p>
            </div>
          </div>
          <div className="col-md-3 ">
            <h3>Example Product Title</h3>
            <p>$19.99 CAD</p>
          </div>
          <div className="col-md-3 ">
            <h3>Example Product Title</h3>

            <p>$19.99 CAD</p>
          </div>
          <div className="col-md-3 ">
            <h3>Example Product Title</h3>
            <p>$19.99 CAD</p>
          </div>
        </div>
      </div> */}
      <div>

      <Story />
      </div>

      {/* <div className="collapse-content"> */}
      {/* <div className="main-acc" style={{display:"flex", flexDirection:"column",backgroundColor:"#1F1F21",padding:"15px"}}> */}
      <div className="main-acc"  >
        <div className="accord-head">
          {/* <h6 className="home-cap">ADD A CAPTION</h6> */}
         
            <h2 className="home-acc-head">FAQ's</h2>
            {/* <h2 className="home-acc-head">COLLAPSIBLE CONTENT</h2> */}
          
        </div>
        <div className="accord">
          <div className="accordion container" id="accordionExample">
            {sectionFaq && sectionFaq.length && sectionFaq.map((item,index)=>{
              return( <div className="card card-acc" key={item.id}>
               <div className="card-header" id={`heading${item.id}`}
                // id="headingOne"
               >
                 <h2 className="mb-0">
                   <button
                     className="btn btn-link btn-block text-left"
                     type="button"
                    //  data-toggle="collapse"
                    //  data-target={`#${item.id}`}
                    // //  data-target="#collapseOne"
                    //  aria-expanded="false"
                    // //  aria-controls="collapseOne"
                    //  aria-controls={item.id}
                     data-toggle="collapse"
                     data-target={`#collapse${item.id}`}
                     aria-expanded="false"
                     aria-controls={`collapse${item.id}`}
                   >
                     {/* <img src={collapse1} alt="leaf" width={"25px"}/> */}
                     {item && item.question}
                     {/* SHARE A BENEFIT OF YOUR FEATURED PRODUCT */}
                   </button>
                 </h2>
               </div>
 
               <div
                //  id="collapseOne"
                //  id={ item.id}
                 id={`collapse${item.id}`}
                //  id={`collapse${index}`}
                 className="collapse "
                //  className="collapse show"
                //  aria-labelledby="headingOne"
                 aria-labelledby={`heading${item.id}`}
                 data-parent="#accordionExample"
               >
                 <div className="card-body">
                   {/* Include key facts, specifications, and important information
                   about your featured product. Add an image in your Collapsible
                   content settings for more visual interest. */}
                     {item && item.answer}

                 </div>
               </div>
             </div>)
            })}
            {/* <div className="card card-acc">
              <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <img src={collapse1} alt="leaf" width={"25px"}/>
                    SHARE A BENEFIT OF YOUR FEATURED PRODUCT
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  Include key facts, specifications, and important information
                  about your featured product. Add an image in your Collapsible
                  content settings for more visual interest.
                </div>
              </div>
            </div>
            <div className="card card-acc">
              <div className="card-header" id="headingTwo">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <img src={collapse2} alt="leaf" width={"25px"}/>

                    SHARE ANOTHER BENEFIT
                  </button>
                </h2>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  Some placeholder content for the second accordion panel. This
                  panel is hidden by default.
                </div>
              </div>
            </div>
            <div className="card card-acc">
              <div className="card-header" id="headingThree">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-left collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <img src={collapse3} alt="leaf" width={"25px"}/>

                    SHARE ANOTHER BENEFIT
                  </button>
                </h2>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  And lastly, the placeholder content for the third and final
                  accordion panel. This panel is hidden by default.
                </div>
              </div>
            </div>
            <div className="card card-acc">
              <div className="card-header" id="headingThree">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link btn-block text-left collapsed btn:focus-none"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <img src={collapse4} alt="leaf" width={"25px"} className="img1"/>

                    SHARE ANOTHER BENEFIT
                  </button>
                </h2>
              </div>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  And lastly, the placeholder content for the third and final
                  accordion panel. This panel is hidden by default.
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* </div> */}

      {/* <CarouselComponent /> */}
      {/* <img className="header-section" src={headerSection} alt="header-poster" /> */}
      {/* <section className="section-2">
        <div className="header">Scrolling portal</div>
        <div className="contain">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularized in the 1960s with
          the release of Leeriest sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Lauds PageMaker
          including versions of Lorem Ipsum.
        </div>
      </section> */}
      {/* <section className="section-3">
        <div className="header-contain">
          <div className="header">Check Out our features</div>
          <div className="sub-header">Check Out our features</div>
        </div>
        <Row className="feature-row">
          {featureListShort.map((feature) => (
            <Col className="py-2" md={3}>
              <div className="img-background">
                <img
                  src={constants.IMG_URL + feature.image}
                  alt={feature.title}
                />
              </div>
              <div className="header">{feature.title}</div>
              <div className="contain">{feature.description}</div>
            </Col>
          ))}
        </Row>
        <Grid className="text-center py-5" item xs={12}>
          <Link to="/feature-list">
            <ButtonComponent>View All</ButtonComponent>
          </Link>
        </Grid>
      </section> */}
      {/* <section className="">
        <hr className="my-0 border-dark" />
      </section> */}
      {/* <section className="section-3"> */}
      {/* <div className="header-contain">
          <div className="header">BLOGS</div>
          <div className="sub-header">BLOGS</div>
        </div> */}
      {/* <div className="article-row">
      
      
        </div> */}
      {/* <Grid className="text-center py-5" item xs={12}>
          <Link to="/new-articles">
            <ButtonComponent>View All</ButtonComponent>
          </Link>
        </Grid> */}
      {/* </section> */}
      {/* <section className="section-4">
        <div className="header-contain">
          <div className="header">Download the app now</div>
          <div className="sub-header">Download the app now</div>
        </div>
        <div className="store-btn">
          <a
            href="https://play.google.com/store/apps/details?id=com.devfortress.hooplivestats"
            target="_blank"
            className="d-flex download-button mx-2 align-items-center"
          >
            <img src={PlayStore} alt="play-store" />
            <div className="btn-text">
              <div className="title">Available on</div>
              <div className="store-name">Google Play</div>
            </div>
          </a>
          <a
            href="https://apps.apple.com/in/app/hoop-live-stats/id1569602985"
            target="_blank"
            className="download-button d-flex mx-2 align-items-center"
          >
            <img src={AppleStore} alt="play-store" />
            <div className="btn-text">
              <div className="title">Available on</div>
              <div className="store-name">App Store</div>
            </div>
          </a>
        </div>
      </section> */}
      {/* <ContactUs /> */}
    </div>
  );
};

export default Home;
