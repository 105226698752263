import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TextFieldComponent from '../../Components/TextFieldComponent/TextFieldComponent';
import headerSection from '../../assets/images/headerSection.jpg';
import './Player.scss';
import constants from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useOnMount } from '../../utils/helpers';
import { getCoachListAction } from '../../Store/actions/HomeAction';
import coach from '../../../src/assets/images/coach.png';

function CoachList(props) {
  const dispatch = useDispatch();
  const results = useSelector((state) => state.home.coachLists);
  const [updatedList, setUpdatedList] = useState([]);

  // console.log("Coach list", useSelector((state) => state.home.coachList));
  // useOnMount(() => {
  //   dispatch(getCoachListAction());
  // });

  console.log('updatedList', updatedList, results);

  useOnMount(() => {
    dispatch(getCoachListAction());
  });

  useEffect(() => {
    setUpdatedList(results);
  }, [results]);

  const handleSearchInput = (event) => {
    let tempUpdatedList = results.length ? results : [];
    const searchKeyWord = event.target.value.toLowerCase().trim();

    tempUpdatedList = tempUpdatedList.filter((item) => {
      const { name } = item;
      return name.toLowerCase().search(searchKeyWord) !== -1;
    });
    setUpdatedList(tempUpdatedList);
  };
  return (
    <>
      <div className="">
        <div className="header-league-section">
          <img className="img-fulid" src={headerSection} alt="header-poster" />
        </div>
        <section className="px-3">
          <Row className="align-items-center justify-content-between">
            <Col md={12}>
              <div className="header-contain text-center">
                <div className="header pl-0">Coaches</div>
                <div className="sub-header">Coaches</div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col md={4}>
              <TextFieldComponent
                className=""
                name="deviceName"
                id="deviceName"
                type="text"
                placeholder="Search Here"
                onChange={handleSearchInput}
                // value={initialValues.deviceName}
                // onBlur={handleFocus}
                // onChange={handleChangeValue}
                // error
                // helperText={isError.deviceName}
              />
            </Col>
          </Row>
        </section>
        <section className="px-3 mt-5 player-list">
          <Row>
            {updatedList?.length > 0 ? (
              updatedList.map((item) => (
                <Col md={3}>
                  <Card>
                    {item.avtar === null ? (
                      <Card.Img src={coach} />
                    ) : (
                      <Card.Img src={constants.IMG_URL + item.avtar} />
                    )}

                    <Card.Body>
                      <Row className="justify-content-between">
                        <Col>{item.name}</Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <div className="no-data-found"> Data not Found </div>
            )}
          </Row>
        </section>
      </div>
    </>
  );
}

export default CoachList;
