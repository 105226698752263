/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  bool, func, number, object, string
} from 'prop-types';
import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paddingRight: {
    paddingRight: theme.spacing(2),
  },
  text: {
    fontSize: '1rem',
  },
}));

const TextInput = ({
  name,
  label,
  control,
  error,
  rules,
  multiline = false,
  rows,
  rowsMax,
  type = 'text',
  position,
  readOnly,
  isSubmitted = false,
  clearErrors,
  isMaxNumber = false,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl
      className={clsx({ [classes.paddingRight]: position === 'left' })}
      autoComplete="nope"
      {...props}
    >
      <Controller
        render={({
          onChange, value, name: inputName, ref
        }) => {
          const handleChange = (e) => {
            // Fix for input not revalidating on blur event
            console.log('enter text', e)
            if (isSubmitted) {
              clearErrors(name);
            }
            onChange(e);
          };
          return (
            <TextField
              type={type}
              name={inputName}
              label={label}
              inputRef={ref}
              fullWidth
              error={!!error}
              helperText={error}
              onChange={handleChange}
              value={value}
              multiline={multiline}
              rows={rows}
              rowsMax={rowsMax}
              disabled={readOnly}
              classes={{ root: classes.text }}
              InputLabelProps={{
                classes: { root: classes.text },
              }}
              inputProps={{ maxLength: isMaxNumber, autoComplete: 'off' }}
            />
          );
        }}
        rules={rules}
        name={name}
        control={control}
      />
    </FormControl>
  );
};

TextInput.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  control: object.isRequired,
  error: string,
  type: string,
  position: string,
  rules: object,
  multiline: bool,
  rows: number,
  rowsMax: number,
  readOnly: bool,
  isSubmitted: bool,
  isMaxNumber: bool,
  clearErrors: func.isRequired
};

export default TextInput;
