import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TeamDetailsData from '../../Components/DummyTable/DummyTable';
import './League.scss';
import { orderBy } from 'lodash';
import CardListTable from '../../Components/CardListTable/CardListTable';
import { getbreakdown, callStateDataQuater, getStatics } from '../../api/index'
import { useHistory } from 'react-router';
import axios from 'axios';
import constants from "./../../utils/constants"
import fetchClient from "./../../utils/constants"

function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

function Quater(props) {

    const [loading, setLoading] = useState(false);
    const [items, setData] = useState();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleSort = (column, sortDirection) => {
        // simulate server sort
        setLoading(true);

        // instead of setTimeout this is where you would handle your API call.
        setTimeout(() => {
            setData(orderBy(items, column.selector, sortDirection));
            setLoading(false);
        }, 100);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fields = [
        {
            key: "id",
            label: "Ranking",
        },
        {
            key: "avatar",
            label: "",
        },
        {
            key: "first_name",
            label: "First Name",
        },
        {
            key: "last_name",
            label: "Last Name"
        },
        {
            key: "team",
            label: "League leaders",
        },
        {
            key: "level",
            label: "Level",
        },
        {
            key: "g",
            label: "GP",
        },
        {
            key: "ppg",
            label: "PPG",
        },
        {
            key: "rpg",
            label: "RPG",
        },
        {
            key: 'off',
            label: 'OFF',
        },
        {
            key: "dfe",
            label: "DEF",
        },
        {
            key: "apg",
            label: "APG"
        },
        {
            key: "ft",
            label: "FT%"
        },
        {
            key: "fg",
            label: "FG%"
        },
        {
            key: "3P",
            label: "3P%"
        },
        {
            key: "2P",
            label: "2P%"
        },


    ]
    const getBadge = status => {
        switch (status) {
            case 'Active': return 'success'
            case 'Inactive': return 'secondary'
            case 'Pending': return 'warning'
            case 'Banned': return 'danger'
            default: return 'primary'
        }
    }
    const history = useHistory();
    const id = props?.teamId;
    const [teamDetails, setTeamDetails] = useState({});
    const [teamList, setTeamList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);


    useEffect(() => {
        getTeam();
    }, [props?.teamId])

    // const teamStatisticsAPI = (id) => {
    //     return (_dispatch, _getState) => {
    //         return fetchClient.get(
    //             `https://hooplivestats.com/api/scoresheet/team-statistics/${id}`,
    //         );
    //     };
    // };
    const getTeam = () => {
        setIsLoader(true)

        getStatics(id)
            .then((response) => {
                const PlayerList = response.data.playerList
                const data = PlayerList.sort((a, b) => b.score.PPG - a.score.PPG)
                setIsLoader(false)
                setTeamList(data);
                setTeamDetails(response.data.team)

            })
            .catch((error) => {
                console.log(error);
            })
        console.log("New", id);
    }


    const LeagueOverview = [
        {
            name: "Ranking",
            selector: "id",
            grow:"0.1",
        },
        {
            name: "Avtar",
            selector: "img",
            // sortable: true,
            grow: "0.3",
            cell: (row) => {
                return (
                    <>
                        <img
                            className="w-50"
                            src={
                                `${process.env.REACT_APP_API_ENDPOINT}${row.avtar}`
                            }
                            alt="img"
                        />
                    </>
                );
            },
        },
        {
            name: "Name",
            selector: "name",
            // sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {(row.first_name) + " " + (row.last_name)}
                    </>
                )
            }
        },
        {
            name:"Team",
            selector: "team",
            grow: "0.2",
            cell: (row) => {
                return(
                    <>
                        {row.team.name}
                    </>
                )
            }
        },
        {
            name:"Level",
            selector: "level",
            grow: "0.2",
        },
        {
            name: "GP",
            selector: "gp",
            grow:"0.05",
            cell: (row) => {
                return(
                    <>
                        {row.score.G}
                    </>
                )
            }
        },
        {
            name: "PPG",
            selector: "ppg",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row.score.PPG}
                    </>
                )
            }
        },
        {
            name: "RPG",
            selector: "rpg",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row.score.RPG}
                    </>
                )
            }
        },
        {
            name: "OFF",
            selector: "off",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row.score.OFF}
                    </>
                )
            }
        },
        {
            name: "DFE",
            selector: "dfe",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row.score.DFE}
                    </>
                )
            }
        },
        {
            name: "APG",
            selector: "apg",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row.score.APG}
                    </>
                )
            }
        },
        {
            name: "FT%",
            selector: "ft",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row.score.FT}
                    </>
                )
            }
        },
        {
            name: "FG%",
            selector: "fg",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row.score.FG}
                    </>
                )
            }
        },
        {
            name: "2P%",
            selector: "2p",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row?.score?.["2P"]}
                    </>
                )
            }
        },
        {
            name: "3P%",
            selector: "3p",
            //   sortable: true,
            grow: "0.5",
            cell: (row) => {
                return(
                    <>
                        {row?.score?.["3P"]}
                    </>
                )
            }
        },
        
    ];
    return (
        <>
            <div className={`quater-tab ${classes.root}`}>
                {/* <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Period 1" {...a11yProps(0)} />
                        <Tab label="Period 2" {...a11yProps(1)} />
                        <Tab label="Period 3" {...a11yProps(2)} />
                        <Tab label="Period 4" {...a11yProps(3)} />
                    </Tabs>
                </AppBar> */}
                {/* <TabPanel value={value} index={0}>
                    <CardListTable
                        columns={LeagueOverview}
                        data={getRecordQuaterWise(0)}
                        onSort={handleSort}
                        hover={false}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                <CardListTable
                        columns={LeagueOverview}
                        data={getRecordQuaterWise(1)}
                        onSort={handleSort}
                        hover={false}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                <CardListTable
                        columns={LeagueOverview}
                        data={getRecordQuaterWise(2)}
                        onSort={handleSort}
                        hover={false}
                    />
                </TabPanel>
                <TabPanel value={value} index={3}>
                <CardListTable
                        columns={LeagueOverview}
                        data={getRecordQuaterWise(3)}
                        onSort={handleSort}
                        hover={false}
                    />
                </TabPanel> */}

                <CardListTable
                    columns={LeagueOverview}
                    data={teamList}
                    // onSort={handleSort}
                    hover={false}
                />

            </div>
        </>
    );
}


export default Quater;